<template>
    <div class="projects">
        <transition name="editstate">
            <Project v-bind:project="project" v-if="project != null" @close="project = null" :translations="translations">
            </Project>
        </transition>
        <div class="row">
            <div class="col-sm-12"v-if="project === null">
                <h2>Projects</h2>
                <simple-filter ref="simpleFilter"
                               @filtersChanged="onFilter"
                               v-bind:filteritems="projectFilters"
                               toplabel="Suodattaa"
                               :selectedlabel="getDictionaryItem(translations, 'Common.SelectedFilters')"
                               :clearlabel="getDictionaryItem(translations, 'Common.ClearAll')" />
            </div>
            <div class="col-sm-6 data-table_searchdata" v-if="project === null">
                <input type="text" v-model="searchInput" v-bind:class="{ active: inputActive }" :placeholder="getDictionaryItem(translations, 'Search')" aria-label="Search criteria" aria-describedby="basic-addon2" @click="inputActive = true">
                <button tabindex="1" class="data-table_btn-clear" v-if="searchInput != ''" @click="resetSearch">
                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 1.46094L8.29422 7.5392" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path> <path d="M2.10742 7.64648L8.18568 1.35226" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path></svg>
                </button>
            </div>
        </div>
        <div class="data-table_table-container" v-if="project == null">
            <div class="data-table__mobile-table collapse-component">
                <div class="collapse-item" v-for="(row,index) in gsOutput()" v-if="loading">
                    <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true">
                        <div class="data-table__mobile-table__container container">
                            <div class="top-row row">
                                <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Common.Loading')}}</div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                            <div class="row">
                                <div class="data-table__mobile-table__left-col col"> </div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                        </div>
                    </button>
                </div>
                <transition-group name="faderow"
                                  tag="div"
                                  :css="true"
                                  @before-enter="onBeforeEnter"
                                  @enter="onEnter"
                                  @leave="onLeave">

                    <div v-for="(project, index) in gsOutput()"
                         class="collapse-item"
                         :key="project.code"
                         v-if="page*rowCount > index">
                        <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#collapse-' + index" aria-expanded="true"
                                :aria-controls="'collapse-' + index">
                            <div class="data-table__mobile-table__container container" @click="displayProject(project)">
                                <div class="top-row row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'MyPages.ProjectCode')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{project.projectCode}}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col col">Project name</div>
                                    <div class="data-table__mobile-table__right-col col">{{ project.projectName  }}</div>
                                </div>
                            </div>
                        </button>
                        <div class="collapse" :id="'collapse-' + index" style="">
                            <div class="card card-body">
                                <div class="data-table__mobile-table__container ">

                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'MyPages.ProjectStatus')}}</div>
                                        <div class="data-table__mobile-table__right-col cell align-left col">{{project.state}}</div>
                                    </div>

                                    <div class="top-row bottom-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'MyPages.Quotations')}}</div>
                                        <div class="data-table__mobile-table__right-col cell align-left col">{{ project.quotations.length }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <div class="preloader-logo" v-if="loading"></div>
            </div>

            <transition-group name="faderow"
                              tag="table"
                              class="data-table__table"
                              :css="true"
                              @before-enter="onBeforeEnter"
                              @enter="onEnter"
                              @leave="onLeave">
                <tr class="data-table__table__top-row" :key="0">
                    <th>{{getDictionaryItem(translations, 'MyPages.ProjectCode')}}</th>
                    <!--<th>Project Name</th>-->
                    <th>{{getDictionaryItem(translations, 'MyPages.ProjectStatus')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.Quotations')}}</th>
                    <!--<th>Sales person</th>-->
                    <th>{{getDictionaryItem(translations, 'MyPages.Opened')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.ProjectCity')}}</th>
                    <!--<th>Due</th>-->
                </tr>
                <tr v-for="(project, index)  in gsOutput()"
                    class="data-table__table__non-top-row"
                    :key="project.code"
                    v-if="page*rowCount > index"
                    @click="displayProject(project)">

                    <td @click="displayProject(project)">{{ project.code }}</td>
                    <td>{{project.state}}</td>
                    <!--<td>{{ project.projectName }}</td>-->
                    <td>{{ project.quotations.length }}</td>
                    <!--<td>{{ project.salesperson.firstName}} {{ project.salesperson.lastName}}</td>-->
                    <td>{{ project.createDate? project.createDate.substring(0,10) : "-" }}</td>
                    <td>{{project.customer.city}}</td>
                    <!-- <td>?</td>-->
                </tr>
                <tr class="data-table__table__non-top-row data-table__table__loading-row" v-if="loading" :key="0">
                    <td colspan="6">{{getDictionaryItem(translations, 'Common.Loading')}}</td>
                    <td><div class="loader"></div></td>
                </tr>
                <tr v-for="(project, projectIndex)  in gsOutput()"
                    v-if="loading"
                    class="data-table__table__non-top-row loading"
                    :key="projectIndex">
                    <td colspan="6">&nbsp;</td>
                </tr>
            </transition-group>
            <div class="preloader-logo" v-if="loading"></div>
            <div class="data-table__btn-wrapper" v-if="!loading && page*rowCount < gsOutput().length">
                <button class="btn btn-primary tt-orange" @click="nextPage">{{getDictionaryItem(translations, 'MyPages.LoadMore')}}</button>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import gsap from "gsap";
    import Project from "./_project.vue";
    import genericSortMixin, { EQUALS, FilterType, Sorting } from '../../mixins/genericsort.vue';
    import SimpleFilter from '../../filters/simple-filter.vue';

    export default Vue.component('mypages-nemesys-projects', {
        components: {
            Project,
            SimpleFilter
        },
        data: function () {
            return {
                loadMoreCount: 0,
                page: 1,
                rowCount: 8,
                currentIndex: 0,
                loading: true,
                headingsArray: [],
                project: null,
                searchInput: '',
                inputActive: false,
                filters: []
            }
        },
        mounted: function () {


            var offeredFilter = FilterType();
            offeredFilter.name = "Offered";
            offeredFilter.category = "Offered";
            offeredFilter.value = "Offered";
            offeredFilter.key = "state";
            offeredFilter.active = true;
            offeredFilter.operator = EQUALS;


            this.filters.push(offeredFilter);

            this.fetchProjects();
        },
        props: {
            translations: {
                type: Object
            },
        },
        computed: {
            projectFilters() {
                var filtersArr = [];
                this.gsCollection.forEach((element, index, array) => {
                    var offeredFilter = FilterType();
                    offeredFilter.name = element.state;
                    offeredFilter.category = element.state;
                    offeredFilter.value = element.state;
                    offeredFilter.key = "state";
                    offeredFilter.active = true;
                    offeredFilter.operator = EQUALS;

                    let item = filtersArr.find(e => e.value === element.state)

                    if (!item) {
                        filtersArr.push(offeredFilter);
                    }
                })
                return filtersArr;
            }
        },
        watch: {
            searchInput: function (val) {
                this.currentIndex = 0;
                this.gsPrSearch(val);
            },
        },
        methods: {
            displayProject(project) {
                this.project = project;
            },
            fetchProjects() {
                this.$http.get('/api/mypages/quotation/list').then((response) => {
                    this.loading = false;
                    this.gsCollection = response.data;
                }, (response) => {
                    console.error(response);
                });
            },
            onFilter(selectedFilters) {
                const vm = this;

                this.currentIndex = 0;
                this.gsResetFilters();

                if (selectedFilters.length) {
                    selectedFilters.forEach(function (filter, index) {
                        vm.gsToggleFilter(filter);
                    });
                }
            },
            nextPage() {
                this.currentIndex = 0;
                this.page++;
            },
            resetSearch() {
                this.searchInput = "";
                this.inputActive = false;
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    duration: 0,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    alpha: 0,
                    delay: 0
                })
            },
            onEnter(el, done) {
                this.currentIndex++;
                this.currentIndex = this.currentIndex >= (this.rowCount + 1) ? 0 : this.currentIndex;
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    x: 0,
                    onComplete: done,
                    delay: this.currentIndex * 0.05
                })
            },
        }
    });
</script>
