<template>
    <ul class="product-tree d-none d-md-block">
        <li class="tree-category">
            <a :href="url" :class="style">{{category.Name}}</a>
            <tree :category="category" v-for="(category, index) in category.Children" :activefilters="filters">
            </tree>
        </li>
    </ul>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('tree', {
        props: {
            'category': {
                required: true,
                type: Object,
            },
            'activefilters': {
                required: true,
                type: Array,
            }
        },
        watch: {
            activefilters: function (newVal) {
                this.filters = newVal;
            }
        },
        data: function () {
            return {
                filters: this.activefilters,
            }
        },
        computed: {
            
            url: function () {
                return this.category.Url;
                /*if (this.filters.length > 0) {
                    return `${this.category.Url}?f=${this.filters.join("&f=")}`
                }
                return this.category.Url;*/
                
            },
            style: function () {
                
                return {
                    'selected': this.category.SelectedLevel === true, 'open': this.category.Children !== undefined && this.category.Children.length > 0
                };

            }
        }
    });
</script>