<template>
    <div>
        <div class="mp-orderedit">
            <div class="row" v-if="!orderCreation">
                <div class="col-12 col-sm-12 d-md-flex">
                    <input class="mp-favorites_heading-input" type="text" v-model="favoriteOrder.title" v-on:keyup.enter="onEnterUpdate" />
                    <p class="mp-favorites_heading-sum">{{getDictionaryItem(translations, 'FavoriteOrders.ListSum')}}: {{currencyFormat(totalPrice,'sv-SE','SEK')}}</p>
                    <div class="btn d-sm-none btn-small btn-primary tt-orange create-btn" @click="orderCreation = true">
                        {{getDictionaryItem(translations, 'MyPages.PlaceAnOrder')}}
                    </div>
                </div>
                <div class="col-6">
                    <div class="btn d-none d-sm-block btn-small btn-primary tt-orange create-btn" @click="orderCreation = true">
                        {{getDictionaryItem(translations, 'MyPages.PlaceAnOrder')}}
                    </div>
                </div>
                <div class="col-12 col-sm-6 d-flex justify-content-end align-items-center">
                    <a class="btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action d-none d-sm-block" @click="closeOrder"></a>
                    <div class="btn btn-small btn-primary tt-blue d-sm-none btn-mob-close" @click="closeOrder">
                        Stäng
                    </div>
                </div>
            </div>
            <transition name="editstate">
                <div class="row" v-if="orderCreation">
                    <div class="col">
                        <order-form :customer="customer"
                                    :customerttcenter="ttCenterId"
                                    :translations="translations" @close="closeForm()"
                                    :favorite-order="favoriteOrder"
                                    :currentculture="currentculture">
                        </order-form>
                    </div>
                </div>
            </transition>

            <div class="mp-orderedit-list table-list" v-if="!orderCreation">
                <div class="table-list_row table-list_row_heading row d-none d-md-flex g-md-0">
                    <div class="col-2 table-list_col">
                        <p>{{getDictionaryItem(translations, 'FavoriteOrders.ArticleNumber')}}</p>
                    </div>
                    <div class="col-2 table-list_col">
                        <p>{{getDictionaryItem(translations, 'Products.RSKNumber')}}</p>
                    </div>
                    <div class="col-2 table-list_col">
                        <p>{{getDictionaryItem(translations, 'Products.ProductName')}}</p>
                    </div>
                    <div class="col-3 table-list_col d-flex justify-content-center">
                        <p>{{getDictionaryItem(translations, 'FavoriteOrders.Count')}}</p>
                    </div>
                    <div class="col-2 table-list_col">
                        <p>{{getDictionaryItem(translations, 'Products.GrossPrice')}}</p>
                    </div>
                </div>
                <transition-group name="faderow"
                                  tag="div"
                                  :css="false"
                                  @before-enter="onBeforeEnter"
                                  @enter="onEnter"
                                  @leave="onLeave">
                    <div v-for="(product, productIndex) in favoriteOrder.orderRows"
                         v-if="page*orderCount > productIndex"
                         class="table-list_row row g-md-0"
                         :key="product.key">
                        <div class="col-12 col-md-2 order-2 order-md-1 table-list_col">
                            <p><span class="d-inline-block d-md-none">Art.nr: </span>{{product.articleNr}}</p>
                        </div>
                        <div class="col-12 col-md-2 order-3 order-md-2 table-list_col">
                            <p><span class="d-inline-block d-md-none">RSK: </span>{{product.rskNr}}</p>
                        </div>
                        <div class="col-12 col-md-2 order-1 order-md-3 table-list_col">
                            <a :href="product.Url" class="table-list_title">{{product.title}}</a>
                        </div>
                        <div class="col-12 col-md-3 order-1 order-md-3 table-list_col d-flex justify-content-center">
                            <div class="btn-qty">
                                <div class="btn btn-sub" @click="reduceCount(product)"></div>
                                <input class="mp-orderedit-list_product-count"
                                       @input="validateNumber(product)"
                                       @change="saveOrder"
                                       v-model="product.count" />
                                <div class="btn btn-add" @click="increaseCount(product)"></div>
                                <a class="btn btn-secondary tt-orange btn-trash btn-square table-list_handheld-action d-md-none" @click="confirmDelete(product)"></a>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 order-4 table-list_col">
                            {{currencyFormat(product.price * product.count,'sv-SE','SEK')}}
                        </div>
                        <div class="col-1 order-5 table-list_col justify-content-end d-none d-md-flex">
                            <a class="btn btn-secondary tt-orange btn-trash btn-square table-list_handheld-action" @click="confirmDelete(product)"></a>
                        </div>
                    </div>
                </transition-group>

                <div class="row table-list_row_more row" v-if="page*orderCount < totalOrders">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="btn btn-primary tt-orange" @click="nextPage">
                            {{getDictionaryItem(translations, 'MyPages.LoadMore')}}
                        </div>
                    </div>
                </div>

                <div class="row table-list_row_more row">
                    <div class="col-12 d-flex">
                        {{getDictionaryItem(translations, 'FavoriteOrders.PriceInformation')}}
                    </div>
                </div>

            </div>

            <modal v-if="confirm"
                   @closeModal="confirmedDeleteProduct"
                   :message="getDictionaryItem(translations, 'MyPages.Modal.Heading')"
                   :decline="getDictionaryItem(translations, 'MyPages.Modal.No')"
                   :accept="getDictionaryItem(translations, 'MyPages.Modal.Yes')"></modal>

            <notification v-if="notificationMessage != ''"
                          @closeModal="closeMessage"
                          :message="notificationMessage"
                          :createclickevent="false"
                          :useokbutton="true"
                          time="4000"></notification>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { gsap } from "gsap";
    import Modal from '../utilities/modal.vue';
    import Notification from '../utilities/notification.vue';
    import OrderForm from './_orderform.vue'
    export default Vue.component('edit-order', {
        created: () => { },

        components: {
            Modal,
            Notification
        },
        data: function () {
            return {
                favoriteOrder: Object,
                page: 1,
                orderCount: 8,
                totalOrders: 0,
                confirm: false,
                messageConfirm: false,
                looseProduct: Object,
                orderCreation: false,
                notificationMessage: "",
                ttCenterId: 0
            }
        },
        props: {
            customer: {
                type: Object
            },
            favoriteorder: {
                Required: true,
                type: Object,
            },
            currentculture: {
                required: false,
                default: "sv-SE"
            },
            translations: {
                required: false,
                type: Object
            }
        },
        mounted: function () {
            this.favoriteOrder = this.favoriteorder;
            this.totalOrders = this.favoriteOrder.orderRows.length;
            window.addEventListener('keyup', this.escClose);

            if (this.customer.ThermotechCenterId) {
                this.ttCenterId = this.customer.ThermotechCenterId;
            }
            else if (this.customer.thermotechCenterId) {
                this.ttCenterId = this.customer.thermotechCenterId;
            }
        },
        methods: {
            closeForm() {
                this.orderCreation = false;
            },
            nextPage() {
                this.page++;
            },
            increaseCount(product) {
                product.count++;
            },
            reduceCount(product) {
                if (product.count <= 1) {
                    this.notificationMessage = "Antal måste vara minst 1, radera produkten om du inte vill ta med den i ordern!"
                }
                product.count > 1 ? product.count-- : product.count;
            },
            confirmDelete(product) {
                this.confirm = true;
                this.looseProduct = product;
            },
            confirmedDeleteProduct(acceptance) {
                if (acceptance) {
                    this.confirm = false;
                    this.deleteProduct(this.looseProduct);
                }
                else {
                    this.confirm = false;
                }
            },
            closeMessage() {
                this.notificationMessage = '';
            },
            deleteProduct(product) {
                this.favoriteOrder.products = this.favoriteOrder.products.filter(word => word !== product.id);
                this.favoriteOrder.orderRows = this.favoriteOrder.orderRows.filter((c) => { return c.id !== product.id })
            },
            validateNumber(product) {
                product.Count = isNaN(product.count) || product.count === '' ? 0 : product.count;
            },
            saveOrder() {
                this.$emit('saveOrder', this.favoriteOrder, this.looseProduct);
            },
            saveCloseOrder() {
                this.$emit('saveCloseOrder', this.favoriteOrder);
            },
            closeOrder() {
                this.$emit('closeOrder', this.favoriteOrder);
            },
            escClose(ev) {
                if (ev.keyCode === 27) {
                    this.closeOrder();
                }
            },
            onEnterUpdate(ev) {
                ev.target.blur();
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    x: '-5%',
                    duration: .2,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    alpha: 0,
                    x: '-5%',
                })
            },
            onEnter(el, done) {
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    x: 0,
                    onComplete: done,
                    delay: el.dataset.index * 0.05
                })
            },
            currency(price) {
                var formatter = new Intl.NumberFormat('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                });
                return formatter.format(price);
            }
        },
        watch: {
            favoriteOrder: {
                handler(val) {
                    val.orderRows.forEach(function (row) {
                        row.count = row.count === 0 ? 1 : row.count;
                    })
                    this.saveOrder();
                },
                deep: true
            }
        },
        computed: {
            totalPrice: function () {
                let sum = 0;

                if (this.favoriteOrder.orderRows) {
                    return this.favoriteOrder.orderRows.reduce((sum, item) => sum + (item.price * item.count), 0);
                }
                else {
                    return sum;
                }
            }
        }

    });
</script>
