var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter" }, [
    _c(
      "div",
      {
        staticClass: "accordion collapse-component",
        attrs: { id: "filterAccordion" }
      },
      _vm._l(_vm.filters, function(filter, index) {
        return _c("div", { staticClass: "accordion-item collapse-item" }, [
          _c(
            "h2",
            {
              staticClass: "accordion-header",
              attrs: { id: "heading" + index }
            },
            [
              _c(
                "button",
                {
                  staticClass: "collapsed btn btn-collapse",
                  attrs: {
                    type: "button",
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#collapse" + index,
                    "aria-expanded": "false",
                    "aria-controls": "'collapse' + index"
                  }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(filter.Name) +
                      "\n                    "
                  ),
                  _vm.selectFilterCounter[index] > 0
                    ? _c("span", { staticClass: "selected-counter" }, [
                        _vm._v(
                          "\n                        (" +
                            _vm._s(_vm.selectFilterCounter[index]) +
                            ")\n                    "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "accordion-collapse collapse",
              attrs: {
                id: "collapse" + index,
                "aria-labelledby": "'heading' + index",
                "data-bs-parent": "#filterAccordion"
              }
            },
            [
              _c(
                "div",
                { staticClass: "accordion-body " },
                _vm._l(_vm.filters[index].Filters, function(filterItem, index) {
                  return _c("div", { staticClass: "select-filter-component" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: filterItem.checked,
                          expression: "filterItem.checked"
                        }
                      ],
                      staticClass: "dont-close",
                      attrs: { type: "checkbox", id: filterItem.Key },
                      domProps: {
                        value: filterItem,
                        checked: Array.isArray(filterItem.checked)
                          ? _vm._i(filterItem.checked, filterItem) > -1
                          : filterItem.checked
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = filterItem.checked,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = filterItem,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    filterItem,
                                    "checked",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    filterItem,
                                    "checked",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(filterItem, "checked", $$c)
                            }
                          },
                          function($event) {
                            return _vm.filtering(filterItem.Id, $event)
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "dont-close",
                        attrs: { for: filterItem.Key }
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(filterItem.Value + " " + filter.FilterUnit) +
                            "\n                        "
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }