export default class FocusInput {

    constructor() {
    }

    Run() {

        let forgottenPwText = document.getElementById("forgottenPwText");
        if (forgottenPwText)
            forgottenPwText.addEventListener('click', event => {

                let forgottenPw = document.getElementById("forgottenPw");
                if (forgottenPw)
                    forgottenPw.focus();

                let forgottenPwCollapse = document.getElementById("forgottenPwCollapse");
                if (forgottenPwCollapse)
                    $('#forgottenPwCollapse').on('shown.bs.collapse', function () {

                        if (screen.width <= 425) {
                            let dims = forgottenPwCollapse.getBoundingClientRect();
                            window.scrollTo(window.scrollX, dims.top - 300);
                        }
                    });
            });
    }

}