var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projects" },
    [
      _c(
        "transition",
        { attrs: { name: "editstate" } },
        [
          _vm.project != null
            ? _c("Project", {
                attrs: { project: _vm.project, translations: _vm.translations },
                on: {
                  close: function($event) {
                    _vm.project = null
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.project === null
          ? _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("h2", [_vm._v("Projects")]),
                _vm._v(" "),
                _c("simple-filter", {
                  ref: "simpleFilter",
                  attrs: {
                    filteritems: _vm.projectFilters,
                    toplabel: "Suodattaa",
                    selectedlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Common.SelectedFilters"
                    ),
                    clearlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Common.ClearAll"
                    )
                  },
                  on: { filtersChanged: _vm.onFilter }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.project === null
          ? _c("div", { staticClass: "col-sm-6 data-table_searchdata" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchInput,
                    expression: "searchInput"
                  }
                ],
                class: { active: _vm.inputActive },
                attrs: {
                  type: "text",
                  placeholder: _vm.getDictionaryItem(
                    _vm.translations,
                    "Search"
                  ),
                  "aria-label": "Search criteria",
                  "aria-describedby": "basic-addon2"
                },
                domProps: { value: _vm.searchInput },
                on: {
                  click: function($event) {
                    _vm.inputActive = true
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchInput = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.searchInput != ""
                ? _c(
                    "button",
                    {
                      staticClass: "data-table_btn-clear",
                      attrs: { tabindex: "1" },
                      on: { click: _vm.resetSearch }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "10",
                            height: "9",
                            viewBox: "0 0 10 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M2 1.46094L8.29422 7.5392",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "square",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M2.10742 7.64648L8.18568 1.35226",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "square",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.project == null
        ? _c(
            "div",
            { staticClass: "data-table_table-container" },
            [
              _c(
                "div",
                { staticClass: "data-table__mobile-table collapse-component" },
                [
                  _vm._l(_vm.gsOutput(), function(row, index) {
                    return _vm.loading
                      ? _c("div", { staticClass: "collapse-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-collapse collapsed",
                              attrs: {
                                type: "button",
                                "data-bs-toggle": "collapse",
                                "aria-expanded": "true"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__container container"
                                },
                                [
                                  _c("div", { staticClass: "top-row row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__left-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDictionaryItem(
                                              _vm.translations,
                                              "Common.Loading"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__right-col col"
                                      },
                                      [_vm._v("-")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._m(0, true)
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c(
                    "transition-group",
                    {
                      attrs: { name: "faderow", tag: "div", css: true },
                      on: {
                        "before-enter": _vm.onBeforeEnter,
                        enter: _vm.onEnter,
                        leave: _vm.onLeave
                      }
                    },
                    _vm._l(_vm.gsOutput(), function(project, index) {
                      return _vm.page * _vm.rowCount > index
                        ? _c(
                            "div",
                            { key: project.code, staticClass: "collapse-item" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-collapse collapsed",
                                  attrs: {
                                    type: "button",
                                    "data-bs-toggle": "collapse",
                                    "data-bs-target": "#collapse-" + index,
                                    "aria-expanded": "true",
                                    "aria-controls": "collapse-" + index
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-table__mobile-table__container container",
                                      on: {
                                        click: function($event) {
                                          return _vm.displayProject(project)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "top-row row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__left-col col"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDictionaryItem(
                                                    _vm.translations,
                                                    "MyPages.ProjectCode"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__right-col col"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(project.projectCode)
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "data-table__mobile-table__left-col col"
                                          },
                                          [_vm._v("Project name")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "data-table__mobile-table__right-col col"
                                          },
                                          [_vm._v(_vm._s(project.projectName))]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "collapse",
                                  attrs: { id: "collapse-" + index }
                                },
                                [
                                  _c("div", { staticClass: "card card-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__container "
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "top-row row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__left-col cell col"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDictionaryItem(
                                                      _vm.translations,
                                                      "MyPages.ProjectStatus"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__right-col cell align-left col"
                                              },
                                              [_vm._v(_vm._s(project.state))]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "top-row bottom-row row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__left-col cell col"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDictionaryItem(
                                                      _vm.translations,
                                                      "MyPages.Quotations"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__right-col cell align-left col"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    project.quotations.length
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("div", { staticClass: "preloader-logo" })
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "transition-group",
                {
                  staticClass: "data-table__table",
                  attrs: { name: "faderow", tag: "table", css: true },
                  on: {
                    "before-enter": _vm.onBeforeEnter,
                    enter: _vm.onEnter,
                    leave: _vm.onLeave
                  }
                },
                [
                  _c(
                    "tr",
                    { key: 0, staticClass: "data-table__table__top-row" },
                    [
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.ProjectCode"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.ProjectStatus"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.Quotations"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.Opened"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.ProjectCity"
                            )
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.gsOutput(), function(project, index) {
                    return _vm.page * _vm.rowCount > index
                      ? _c(
                          "tr",
                          {
                            key: project.code,
                            staticClass: "data-table__table__non-top-row",
                            on: {
                              click: function($event) {
                                return _vm.displayProject(project)
                              }
                            }
                          },
                          [
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.displayProject(project)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(project.code))]
                            ),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(project.state))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(project.quotations.length))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  project.createDate
                                    ? project.createDate.substring(0, 10)
                                    : "-"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(project.customer.city))])
                          ]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.loading
                    ? _c(
                        "tr",
                        {
                          key: 0,
                          staticClass:
                            "data-table__table__non-top-row data-table__table__loading-row"
                        },
                        [
                          _c("td", { attrs: { colspan: "6" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.getDictionaryItem(
                                  _vm.translations,
                                  "Common.Loading"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_c("div", { staticClass: "loader" })])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.gsOutput(), function(project, projectIndex) {
                    return _vm.loading
                      ? _c(
                          "tr",
                          {
                            key: projectIndex,
                            staticClass:
                              "data-table__table__non-top-row loading"
                          },
                          [_c("td", { attrs: { colspan: "6" } }, [_vm._v(" ")])]
                        )
                      : _vm._e()
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.loading
                ? _c("div", { staticClass: "preloader-logo" })
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading && _vm.page * _vm.rowCount < _vm.gsOutput().length
                ? _c("div", { staticClass: "data-table__btn-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary tt-orange",
                        on: { click: _vm.nextPage }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.LoadMore"
                            )
                          )
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "data-table__mobile-table__left-col col" }),
      _vm._v(" "),
      _c("div", { staticClass: "data-table__mobile-table__right-col col" }, [
        _vm._v("-")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }