var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-fade" } }, [
    _c(
      "div",
      {
        staticClass: "notification-bar",
        on: {
          click: function($event) {
            return _vm.closeModal(false)
          }
        }
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col offset-md-2 offset-lg-1 col-md-8 col-lg-10 col-sm-12 text-center"
              },
              [
                _c("div", { staticClass: "notification-bar__notification" }, [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } })
                ])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }