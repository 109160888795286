import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
export default class MobileNavigation {
    elm;

    constructor(
        elm,
    ) {
        this.elm = elm;
    }

    Run() {
        this.toggleBtn = this.elm.querySelector('.nav-toggler');
        this.mainPanel = this.elm.querySelector('.mobnav-panel');
        this.childBtns = this.elm.querySelectorAll('.child-btn');
        this.navArea = this.elm.querySelector('.nav-area');
        this.childPanels = this.elm.querySelectorAll('.child-panel');
        this.logo = this.elm.querySelector('.logo');
        this.childCloseBtns = this.elm.querySelectorAll('.close-child-btn');

        for (let i = 0; i < this.childBtns.length; i++) {
            this.childBtns[i].addEventListener('touchend',
                event => {
                    event.preventDefault();
                    this.SlideInSidePanel(i);
                });

            this.childBtns[i].addEventListener('click',
                event => {
                    event.preventDefault();
                    this.SlideInSidePanel(i);
                });

            this.childCloseBtns[i].addEventListener('touchend',
                event => {
                    event.preventDefault();
                    this.SlideOutSidePanel();
                });
            this.childCloseBtns[i].addEventListener('click',
                event => {
                    event.preventDefault();
                    this.SlideOutSidePanel();
                });
        }

        this.toggleBtn.addEventListener('touchend',
            event => {
                event.preventDefault();
                this.ToggleMobileNav();
            });
        this.toggleBtn.addEventListener('click',
            event => {
                event.preventDefault();
                this.ToggleMobileNav();
            });

    }
    ToggleMobileNav() {
        this.elm.classList.toggle('active');
        if (this.elm.classList.contains('active')) {
            this.FadeIn(this.mainPanel);
            setTimeout(() => {
                disableBodyScroll(this.elm, { reserveScrollBarGap: true });
            }, 300);
        } else {
            enableBodyScroll(this.elm);
            this.FadeOut(this.mainPanel);
            this.navArea.classList.remove('child-active');
            this.logo.classList.remove('slide-out');
        }
    }
    SlideOutSidePanel() {
        this.navArea.classList.toggle('child-active');
        this.logo.classList.remove('slide-out');

        setTimeout(() => {
            this.CloseChildPanels();
        }, 300);

    }
    SlideInSidePanel(i) {
        this.CloseChildPanels();
        this.navArea.classList.toggle('child-active');
        this.childPanels[i].classList.add('active');
        this.logo.classList.add('slide-out');
    }
    CloseChildPanels() {
        for (let i = 0; i < this.childPanels.length; i++) {
            this.childPanels[i].classList.remove('active');
        }
    }
    FadeIn(el) {
        el.style.display = 'block';
        setTimeout(() => {
            el.classList.add('active');
        }, 1);
    }
    FadeOut(el) {
        el.classList.remove('active');
        setTimeout(() => {
            el.style.display = 'none';
        }, 300);
    }
}
