import './_polyfill';
import 'bootstrap';
import HeadRoom from 'headroom.js';

//import { Swiper } from 'swiper/bundle';
import Swiper from 'swiper/swiper-bundle';
import Vue from 'vue';

//import { Navigation } from "./_navigation";

import './components/v-demo.vue';
import './components/faq/faqlisting.vue';
import './components/case/caselisting.vue';
import './components/filters/simple-filter.vue';
import './components/coordinates/coordinates.vue';
import './components/map/map.vue';
import './components/search/searchinput.vue';
import './components/search/searchinput-mobile.vue';
import './components/search/searchpage.vue';
import './components/employees/employeelisting.vue';
import './components/utilities/text-collapse.vue';
import './components/documentation/documentationlisting.vue';
import './components/cookieoptin/cookieoptin.vue';
import './components/products/productlist.vue';
import './components/products/categorylist.vue';
import './components/products/productsearchinput.vue';
import './components/products/productfilter.vue';
import './components/products/products.vue';
import './components/products/tree/tree.vue';
import './components/products/favoritespopover.vue';
import './components/products/favoritebutton.vue';
import './components/timedtabs/timedtabs.vue';
import './components/mypages/_favorites.vue';
import './components/mypages/_orders.vue';
import './components/mypages/_favoriteorders.vue';
import './components/mypages/_invoices.vue';
import './components/mypages/_memberapplication.vue';
import './components/mypages/_orderform.vue';
import './components/mypages/nemesys/_projects.vue';


import './components/mixins/getdictionaryitem.vue';
import './components/mixins/currencyformat.vue';

import VclickOutside from './components/directives/_clickoutside.vue';
import { Videos } from "./modules/_video";

import PreventDoubleClick from './helpers/preventDoubleClick.js';
import LoadMore from './modules/loadmore.js';
import MainNavigation from './navigation/mainnavigation.js';
import MobileNavigation from './navigation/mobilenavigation.js';
import FocusInput from './helpers/focusInput.js';
import ValidateInputs from './helpers/validateInputs.js';
import 'swiper/swiper-bundle.css';
import { Loader } from '@googlemaps/js-api-loader';

import '../scss/style.scss';

class Index {
    constructor() {
        document.addEventListener("DOMContentLoaded", this.init);
        window.addEventListener('load', this.load);

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            Vue.config.devtools = true;
            console.log("[Development mode] Vue.config.devtools enabled.");
        }

        this.addScripts();

    }

    init() {
        
    }

    load() {
        new FocusInput().Run();
        new ValidateInputs().Run();

        var myElement = document.querySelector('.main-header');
        var headroom = new HeadRoom(myElement, {
            'offset': 126
        });
        headroom.init();

        var swiper = new Swiper('.swiper-navigationmodule', {
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

        });


        new PreventDoubleClick().Run("#requestPw"); //Add more ids when needed

        var productThumbnailsSwiper = new Swiper('.swiper-productthumbnails', {
            preloadImages: false,
            lazy: true,
            loop: false,
            spaceBetween: 16,
            slidesPerView: 5,
            freeMode: false,
            watchSlidesProgress: true
        });


        var productSwiper = new Swiper('.swiper-products', {
            preloadImages: false,
            lazy: true,
            loop: true,
            spaceBetween: 10,
            //effect: 'fade',
            //fadeEffect: {
            //    crossFade: true
            //},
            navigation: {
                nextEl: ".swiper-button-next-prod-image",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: productThumbnailsSwiper,
            }
        });

        productSwiper.on('reachEnd', function (s) {
            if (s.swipeDirection === undefined) {
                productSwiper.slideTo(1, 1000, true);
            }
        });


        if ($('#v-demo').length > 0) {
            const vm = new Vue({
                el: '#v-demo',
            });
        }

        if (document.getElementById('faq-listing')) {
            const vm = new Vue({
                el: '#faq-listing',
            });
        }

        if (document.getElementById('case-listing')) {
            const vm = new Vue({
                el: '#case-listing',
            });
        }

        if (document.getElementById('google-map')) {
            const vm = new Vue({
                el: '#google-map',
            });
        }

        if (document.getElementById('timedtabs')) {
            const vm = new Vue({
                el: '#timedtabs',
            });
        }

        if (document.getElementById('employee-listing')) {
            const vm = new Vue({
                el: '#employee-listing',
            });

            $(document).on('click', function (event) {
                if ($(event.target).closest('.collapse').length) {
                    return; //do nothing if event target is within the navigation
                }
                $('.collapse').collapse('hide');
            });
        }
        if (document.getElementById('coordinates-map')) {
            const vm = new Vue({
                el: '#coordinates-map'
            });
        }

        if (document.getElementById('search-input')) {
            const vm = new Vue({
                el: '#search-input'
            });
        }

        if (document.getElementById('searchinput-mobile')) {
            const vm = new Vue({
                el: '#searchinput-mobile'
            });
        }


        if (document.getElementById('search-page')) {
            const vm = new Vue({
                el: '#search-page'
            });
        }

        if (document.getElementById('documentation-listing')) {
            const vm = new Vue({
                el: '#documentation-listing'
            });
        }

        if (document.getElementById('documentation-listing-handheld')) {
            const vm = new Vue({
                el: '#documentation-listing-handheld'
            });
        }

        //if (document.getElementById('cookie-optin')) {
        //    const vm = new Vue({
        //        el: '#cookie-optin'
        //    });
        //}

        if (document.getElementById('text-collapse')) {
            const vm = new Vue({
                el: '#text-collapse'
            });
        }

        if (document.getElementById('accessories-list')) {
            const vm = new Vue({
                el: '#accessories-list'
            });
        }

        if (document.getElementById('accessories-list-handheld')) {
            const vm = new Vue({
                el: '#accessories-list-handheld'
            });
        }

        if (document.getElementById('variants-list')) {
            const vm = new Vue({
                el: '#variants-list'
            });
        }
        if (document.getElementById('variants-list-handheld')) {
            const vm = new Vue({
                el: '#variants-list-handheld'
            });
        }

        if (document.getElementById('products-list')) {
            const vm = new Vue({
                el: '#products-list'
            });
        }


        if (document.getElementById('products')) {
            const vm = new Vue({
                el: '#products'
            });
        }

        if (document.getElementById('product-searchinput')) {
            const vm = new Vue({
                el: '#product-searchinput'
            });
        }

        if (document.getElementById('favorites-popover')) {
            const vm = new Vue({
                el: '#favorites-popover'
            });
        }

        if (document.getElementById('render-tree')) {
            const vm = new Vue({
                el: '#render-tree'
            });
        }

        if (document.getElementById('mypages-favorites')) {
            const vm = new Vue({
                el: '#mypages-favorites'
            });
        }

        if (document.getElementById('mypages-favoriteorders')) {
            const vm = new Vue({
                el: '#mypages-favoriteorders'
            });
        }

        if (document.getElementById('mypages-offers')) {
            const vm = new Vue({
                el: '#mypages-offers'
            });
        }

        if (document.getElementById('mypages-invoices')) {
            const vm = new Vue({
                el: '#mypages-invoices'
            });
        }

        if (document.getElementById('member-application')) {
            const vm = new Vue({
                el: '#member-application'
            });
        }

        if (document.getElementById('favorite-button')) {
            const vm = new Vue({
                el: '#favorite-button'
            });
        }

        if (document.getElementById('mypages-nemesys-projects')) {
            const vm = new Vue({
                el: '#mypages-nemesys-projects'
            });
        }



        var jobListingModule = document.querySelector('.job-listing-module');

        if (jobListingModule) {
            new LoadMore(jobListingModule, '.job-listing-module', '.load-more', '.hidden').Run()
        }

        var navContainer = document.querySelector('.nav-container');
        if (navContainer) {
            new MainNavigation(navContainer,
                '.main-navigation',
                '.nav-container',
                '.nav-btn',
                '.panel-container',
                '.panel-item',
                '.modal-backdrop',
                '.mega-item',
                '.main-header').Run();
        }

        var mobNavContainer = document.querySelector('.mobnav-container');
        if (mobNavContainer) {
            new MobileNavigation(mobNavContainer).Run();
        }
    }

    addScripts() {
        if (localStorage.iAcceptCookies) {
            var scripts = document.querySelectorAll("script");

            scripts.forEach((script) => {
                if (script.hasAttribute("data-cookie-consent")) {
                    var s = document.createElement('script');
                    s.innerHTML = script.innerHTML;
                    script.parentNode.insertBefore(s, script.nextSibling);
                    script.remove();
                }
            });
        }
        new Videos();
    }
}


new Index();
