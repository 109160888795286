<template>
    <div class="member-application umbraco-forms-thermotech tt-form">
        <div v-if="serviceError != ''" class="reset__status-message alert alert-danger">
            {{serviceError}}
        </div>
        <form v-on:submit="submitApplication" v-if="!applicationSubmitted" style="position:relative;">
            <div v-for="(item, itemIndex) in formData">
                <div v-if="item.type != 'select' && item.type" class="reset__input-wrapper form-floating tt-form_input-group" v-bind:class="{ 'is-valid': item.isValid && item.type != 'hidden' }">
                    <input @change="validateForm($event, item)" v-on:blur="validateForm($event, item)" :id="item.type + itemIndex" class="form-control" :type="item.type" autocomplete="off" v-model="item.value">
                    <label :for="item.type + itemIndex">{{item.label}}</label>
                    <span class="field-validation-error" v-if="item.errors" v-for="error in item.errors">{{getDictionaryItem(translations, error.errorMessage)}}</span>
                </div>
                <div v-if="item.type == 'select'" class="form-group" v-bind:class="{ 'is-valid': item.isValid }">
                    <label for="warehouse-select">{{getDictionaryItem(translations, 'MemberApplicationForm.WareHouseSelectLabel')}}</label>
                    <select v-model="item.value" class="form-select form-select-lg" id="warehouse-select" @change="validateForm($event, item)">
                        <option v-for="(office, whIndex) in wareHouses" :value="office.id">
                            {{office.officeName}}
                        </option>
                    </select>
                    <span class="field-validation-error" v-if="item.errors" v-for="error in item.errors">{{getDictionaryItem(translations, error.errorMessage)}}</span>
                </div>
            </div>
            <div class="col-sm-6 offset-sm-3 form-group">
                <button id="requestApplication" class="reset__button btn btn-primary tt-orange text-uppercase" type="submit" :disabled="!formIsValid || pendingSubmit">
                    {{this.getDictionaryItem(this.translations, "Common.Submit"),}}
                </button>
            </div>
            <div class="preloader-logo" v-if="pendingSubmit"></div>
        </form>
        <div v-if="applicationSubmitted" class="reset__status-message alert alert-success">
            {{thankstxt}}
        </div>

    </div>
</template>
<script lang="ts">
    import Vue from "vue";
    export default Vue.component('member-application', {
        created: () => { },
        components: {},
        data: function () {
            return {
                formData: [],
                applicationSubmitted: false,
                pendingSubmit: false,
                formIsValid: false,
                serviceError: "",
                timeout: null,
                wareHouses: [],
            }
        },
        props: {
            btnlabel: {
                type: String,
                default: "BTNTEXT"
            },
            currentculture: {
                type: String,
                default: "sv-SE"
            },
            thankstxt: {
                type: String,
                default: "THANKS"
            },
            translations: {
                required: true,
                type: Object
            }
        },
        mounted: function () {
            this.formData.push({ key: "Email", type: "input", value: "", label: this.getDictionaryItem(this.translations, 'Common.Email'), errors: [], isValid: false });
            this.formData.push({ key: "Password", type: "password", value: "", label: this.getDictionaryItem(this.translations, "Common.Password"), errors: [], isValid: false });
            this.formData.push({ key: "FirstName", type: "input", value: "", label: this.getDictionaryItem(this.translations, "Common.Firstname"), errors: [], isValid: false });
            this.formData.push({ key: "LastName", type: "input", value: "", label: this.getDictionaryItem(this.translations, "Common.Lastname"), errors: [], isValid: false });
            this.formData.push({ key: "CompanyName", type: "input", value: "", label: this.getDictionaryItem(this.translations, "Common.Companyname"), errors: [], isValid: false });
            this.formData.push({ key: "PhoneNumber", type: "input", value: "", label: this.getDictionaryItem(this.translations, "Common.Phone"), errors: [], isValid: false });
            this.formData.push({ key: "CustomerNumber", type: "input", value: null, label: this.getDictionaryItem(this.translations, "Common.Customernumber"), errors: [], isValid: false });
            this.formData.push({ key: "Culture", type: "hidden", value: this.currentculture, errors: [], isValid: true });
            this.getWareHouses();
            this.formData.push({ key: "OfficeId", type: "select", value: null, label: "Kontor", errors: [], isValid: false });
        },
        methods: {
            validateForm: function (event, item) {
                switch (event.type) {
                    case 'focus':
                        this.resetError(item);
                        break;
                    case 'blur':
                    case 'input':
                    case 'change':
                        if (this.timeout) clearTimeout(this.timeout)
                        this.timeout = setTimeout(() => {
                            this.$http.post('/api/mypages/vaildateapplication', this.formatedData).then((response) => {
                                let modelState = response.data;

                                if (modelState.errors[item.key]) {
                                    item.isValid = false;
                                }
                                else {
                                    this.resetError(item);
                                    item.isValid = true;
                                }

                                if (Object.getOwnPropertyNames(modelState.errors).length === 0) {
                                    this.formIsValid = true;
                                }
                                else {
                                    this.formIsValid = false;
                                }
                            }, (response) => {
                                console.error(response);
                            });
                        }, 300)

                    case 'blur':
                        this.$http.post('/api/mypages/vaildateapplication', this.formatedData).then((response) => {
                            let modelState = response.data.errors;
                            if (modelState[item.key]) {
                                item.errors = modelState[item.key].errors;
                            }
                        }, (response) => {
                            console.error(response);
                        });
                        break;
                    default:
                        break;
                }
            },
            submitApplication(e) {
                e.preventDefault();
                this.pendingSubmit = true;
                this.$http.post('/api/mypages/memberapplication', this.formatedData).then((response) => {

                    if (response.data == true) {
                        this.applicationSubmitted = true;
                        this.pendingSubmit = false;
                    }
                    else {
                        this.applicationSubmitted = false;
                        this.pendingSubmit = false;
                        this.serviceError = "Något gick fel när ansökan behandlades!";
                    }
                }, (response) => {
                    console.error(response);
                });
            },
            resetErrors() {
                this.formData.forEach(item => {
                    item.errors = []
                });
            },
            resetError(item) {
                item.errors = []
            },
            getWareHouses() {
                this.$http.post('/api/mypages/office/warehouses', { "culture": this.currentculture }).then((response) => {
                    this.wareHouses = response.data;

                    if (response.data) {
                        this.wareHouses.unshift({
                            id: null,
                            officeName: this.getDictionaryItem(this.translations, 'FavoriteOrders.PickOffice')
                        });
                        this.selectedWarehouse = this.wareHouses[0].id;
                    }
                }, (response) => {
                    console.error(response);
                });
            },
        },
        watch: {
        },
        computed: {
            formatedData() {
                let applicationData = {};
                this.formData.forEach(f => applicationData[f.key] = f.value);
                return applicationData;
            }
        }
    });
</script>
