<template>
    <div class="product-filter">
        <div class="accordion collapse-component" id="filterAccordion">
            <div class="accordion-item collapse-item" v-for="(filter, index) in filters">
                <h2 class="accordion-header" :id="'heading' + index">
                    <button class="collapsed btn btn-collapse" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" aria-controls="'collapse' + index">
                        {{filter.Name}}
                        <span class="selected-counter" v-if="selectFilterCounter[index] > 0">
                            ({{selectFilterCounter[index]}})
                        </span>
                    </button>
                </h2>
                <div :id="'collapse' + index" class="accordion-collapse collapse" aria-labelledby="'heading' + index" data-bs-parent="#filterAccordion">
                    <div class="accordion-body ">
                        <div class="select-filter-component" v-for="(filterItem,index) in filters[index].Filters">
                            <input class="dont-close" v-model="filterItem.checked" :value="filterItem" type="checkbox" :id="filterItem.Key" @change="filtering(filterItem.Id,$event)" />
                            <label class="dont-close" :for="filterItem.Key">
                                {{filterItem.Value + " " + filter.FilterUnit}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import _ from 'lodash';

    export default Vue.component("product-filter", {
        props: ['filters', 'initiallyselectedfilters'],
        data: () => {
            return {
                selectFilterCounter: []
            }
        },
        components: {},

        created: function () {
            _.chain(this.filters)
                .map("Filters").flatten()
                .each(o => {
                    if (_.chain(this.initiallyselectedfilters).find(f => f == o.Id).value()) {
                        o.checked = true;
                        this.$emit('filtering', o.Id, o.checked);
                        this.countSelected();
                    }
                })
                .value();
        },        

        methods: {
            filtering: function (id, e) {
                this.$emit('filtering', id, e.target.checked);
                this.countSelected();
            },
            countSelected: function (filter) {
                var countArray = [];
                this.filters.forEach(function (element, index) {
                    countArray.push(0);     
                    element.Filters.forEach(function (child, childIndex) {                     
                        if (child.checked) {
                            countArray[index]++;
                        }
                    });
                });
                this.selectFilterCounter = countArray;
            }
        },

        computed: {

        },

        
    });
</script>