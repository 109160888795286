var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.standalone
        ? _c("div", { staticClass: "btn-container" }, [
            _vm.customer
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-primary tt-orange btn-add-list-white",
                    class: { added: _vm.favoriteproduct.AddedToFavoriteList },
                    on: { click: _vm.togglePopOver }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.favoriteproduct.AddedToFavoriteList
                            ? _vm.getDictionaryItem(
                                _vm.typedtranslations,
                                "FavoriteOrders.AddedToMyList"
                              )
                            : _vm.getDictionaryItem(
                                _vm.typedtranslations,
                                "FavoriteOrders.AddToMyList"
                              )
                        ) +
                        "\n            "
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isActive
        ? _c("div", { staticClass: "favorites-popover" }, [
            _c("div", { staticClass: "favorites-popover_backdrop" }),
            _vm._v(" "),
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "editstate", mode: "out-in" } },
                    [
                      _vm.isEditing
                        ? _c(
                            "div",
                            {
                              staticClass: "favorites-popover_listeditor edit"
                            },
                            [
                              _c("edit-order", {
                                attrs: {
                                  customer: _vm.customer,
                                  translations: _vm.typedtranslations,
                                  favoriteorder: _vm.currentOrder
                                },
                                on: {
                                  saveOrder: _vm.upDateOrders,
                                  saveCloseOrder: _vm.endEditingUpdate,
                                  closeOrder: _vm.endEditing
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isEditing
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col col-sm-12 offset-lg-1 col-lg-10 "
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "click-outside",
                                      rawName: "v-click-outside",
                                      value: _vm.deactivate,
                                      expression: "deactivate"
                                    }
                                  ],
                                  staticClass: "favorites-popover_listeditor"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "favorites-popover_header" },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.getDictionaryItem(
                                              _vm.typedtranslations,
                                              "FavoriteOrders.AddToMyList"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "btn btn-secondary tt-orange btn-close btn-square",
                                        on: { click: _vm.closePopover }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.activeFavoriteList.length
                                    ? _c(
                                        "TransitionGroup",
                                        {
                                          staticClass:
                                            "favorites-popover_lists",
                                          attrs: {
                                            name: "favorites-checkboxlist",
                                            tag: "div",
                                            css: false
                                          },
                                          on: {
                                            "before-enter": _vm.onBeforeEnter,
                                            enter: _vm.onEnter,
                                            leave: _vm.onLeave
                                          }
                                        },
                                        _vm._l(_vm.activeFavoriteList, function(
                                          list
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: list.key,
                                              staticClass:
                                                "favorites-popover_list-row"
                                            },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: list.Key } },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: list.AddedToList,
                                                        expression:
                                                          "list.AddedToList"
                                                      }
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      id: list.Key
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        list.AddedToList
                                                      )
                                                        ? _vm._i(
                                                            list.AddedToList,
                                                            null
                                                          ) > -1
                                                        : list.AddedToList
                                                    },
                                                    on: {
                                                      change: [
                                                        function($event) {
                                                          var $$a =
                                                              list.AddedToList,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  list,
                                                                  "AddedToList",
                                                                  $$a.concat([
                                                                    $$v
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  list,
                                                                  "AddedToList",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              list,
                                                              "AddedToList",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                        function($event) {
                                                          return _vm.toggleProduct(
                                                            list
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(list.title) +
                                                      "\n\n\n                                        "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass:
                                                  "btn btn-secondary tt-verylight-gray btn-edit-dark btn-square favorites-popover_edit-btn",
                                                class: {
                                                  disabled: !list.orderRows
                                                    .length
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editOrder(list)
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "favorites-popover_footer" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "favorites-popover_container"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn btn-collapse collapsed",
                                              attrs: {
                                                "data-bs-toggle": "collapse",
                                                href: "#newListCollapse",
                                                role: "button",
                                                "aria-expanded": "false",
                                                "aria-controls":
                                                  "newListCollapse"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.getDictionaryItem(
                                                      _vm.typedtranslations,
                                                      "FavoriteOrders.CreateNewList"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "collapse",
                                          attrs: { id: "newListCollapse" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "favorites-popover_container"
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(_vm.listnamelabel)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.newListName,
                                                    expression: "newListName"
                                                  }
                                                ],
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.newListName
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.newListName =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "favorites-popover_row"
                                            },
                                            [
                                              _vm.newListName != "" &&
                                              _vm.newListName.length > 2
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary tt-orange",
                                                      on: {
                                                        click: _vm.createList
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.getDictionaryItem(
                                                              _vm.typedtranslations,
                                                              "FavoriteOrders.CreateNewList"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.newListName
                                                          ) +
                                                          "\n                                            "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "notice" } },
        [
          _vm.showNotification
            ? _c("notification-bar", {
                attrs: { message: _vm.noticeMessage, time: "5000" },
                on: { closeModal: _vm.closeNotification }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }