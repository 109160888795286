<template>
    <div class="category-card" v-bind:class="{'list-view': listview}">
        <div class="img-container" v-if="category.ImageUrl">
            <img v-bind:src="category.ImageUrl" v-bind:alt="category.Alt"/>
        </div>
        <a class="btn btn-primary tt-orange" v-bind:href="url">{{category.Name}}</a>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('category-card', {
        created: () => {

        },
        components: {

        },
        watch: {
            activefilters: function (newVal) {
                this.filters = newVal;
            }
        },
        data: function () {
            return {
                filters: this.activefilters,
            }
        },
        props: {
            category: {
                type: Object
            },

            listview: {
                type: Boolean
            },

            activefilters: {
                required: true,
                type: Array,
            }
        },
        mounted: function () {

        },
        methods: {

        },
        computed: {
            url: function () {
                if (this.filters.length > 0) {
                    
                    return `${this.category.Url}?f=${ this.filters.join("&f=") }`
                }
                return this.category.Url;

            },
        }
    });
</script>
