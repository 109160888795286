var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "a",
        {
          class: _vm.classes,
          attrs: { href: "url", alt: _vm.altText },
          on: { click: _vm.download }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.notifyError
        ? _c("notification", {
            attrs: { message: _vm.errorMessage, time: 1500 },
            on: {
              closeModal: function($event) {
                _vm.notifyError = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }