<script lang="ts">
    import Vue from "vue";

    export default Vue.mixin({
        methods: {
            getDictionaryItem: function (list, ItemKey) {
                if (!list)
                    return "Missing dictionary";

                var item = list.find((item) => item.ItemKey == ItemKey);
                return item != undefined ? item.Value : `[ Missing translation: ${ItemKey} ]`;
            }
        }
    })
</script>
