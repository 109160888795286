var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-collapse" }, [
    _c("div", {
      ref: "textel",
      staticClass: "collapse-text rte-content",
      class: { expanded: _vm.isExpanded },
      domProps: { innerHTML: _vm._s(_vm.text) }
    }),
    _vm._v(" "),
    !_vm.isExpanded
      ? _c(
          "a",
          {
            staticClass: "expand-btn",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.isExpanded = !_vm.isExpanded
              }
            }
          },
          [_vm._v(_vm._s(_vm.buttonlabel))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }