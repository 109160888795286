var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faq-listing" },
    [
      _c("simple-filter", {
        attrs: {
          filteritems: _vm.unfilteredItems,
          toplabel: _vm.getDictionaryItem(
            _vm.translations,
            "FAQ.FilterContents"
          ),
          selectedlabel: _vm.getDictionaryItem(
            _vm.translations,
            "FAQ.SelectedFilters"
          ),
          clearlabel: _vm.getDictionaryItem(_vm.translations, "FAQ.ClearAll")
        },
        on: { filtersChanged: _vm.onFilter }
      }),
      _vm._v(" "),
      _c(
        "transition-group",
        { staticClass: "list row", attrs: { name: "fade", tag: "div" } },
        _vm._l(_vm.items, function(faqCat, catIndex) {
          return _c(
            "div",
            { key: faqCat.category, staticClass: "faq-list" },
            [
              faqCat.FAQs.length
                ? _c("faq-cat", {
                    attrs: {
                      faqs: faqCat.FAQs,
                      category: faqCat.category,
                      translations: _vm.translations
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }