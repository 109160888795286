<template>
    <transition name="cookiefade">
        <div class="cookie-optin" v-if="showCookieOptin">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <h4>{{heading}}</h4>
                        <p v-html="textcontent"></p>
                    </div>
                    <div class="col-sm-6 d-sm-flex justify-content-end align-items-center">
                        <button @click="acceptCookies" class="btn btn-primary tt-orange">{{acceptlabel}}</button>
                        <button @click="rejectCookies" class="btn btn-secondary tt-verylight-gray">{{denylabel}}</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>            

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('cookie-optin', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                iRejectCookies: 0,
                showCookieOptin: false
            }
        },
        props: {
            heading: {
                type: String,
                default: 'Cookies'
            },
            textcontent: {
                type: String,
                default: 'About cookies'
            },
            acceptlabel: {
                type: String,
                default: 'I accept'
            },
            denylabel: {
                type: String,
                default: 'I deny'
            }
        },
        mounted: function () {
            if (!localStorage.iRejectCookies && !localStorage.iAcceptCookies) {
                this.showCookieOptin = true;
            }
        },
        methods: {
            acceptCookies: function () {
                this.showCookieOptin = false;
                localStorage.setItem('iAcceptCookies', '1');
            },
            rejectCookies: function () {
                this.showCookieOptin = false;
                localStorage.setItem('iRejectCookies', '1');
            }
        },
        computed: {

        }
    });
</script>
