<template>
    <div class="searchinputmobile"
         v-body-scroll-lock="isActive"
         v-bind:class="{ active: isActive, disabled: !isActive }"
         v-click-outside="deactivateMobileSearch">

        <input id="mobilesearchinput"
               @blur="handleBlur"
               @focus="handleFocus"
               autocomplete="off"
               v-on:keyup.enter="toSearchResult"
               v-model="searchString"
               ref="searchinputmobile"
               v-if="isActive"
               :placeholder="searchplaceholder" tabindex="1">

        <button v-if="isActive" class="close-btn" @click="clearSearch">
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 1.46094L8.29422 7.5392" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
                <path d="M2.10742 7.64648L8.18568 1.35226" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
            </svg>
        </button>

        <button v-if="!isActive" class="search-button" @click="activateMobileSearch">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17.5 17.5L13.875 13.875" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>
        <transition name="fade">
            <div class="searchresult" 
                 v-body-scroll-lock="isActive" 
                 v-if="searchResult.length"
                 v-bind:class="{ 'input-focused': inputFocused}">
                <div class="inside-result">
                    <transition-group name="tt-list-fade" tag="ul">
                        <ul v-for="(cat, index) in searchResult" :key="cat.name">
                            <li class="cat">
                                <a class="label" v-bind:href="allbtnurl + '?query=' + searchString + '&filter=' + cat.name">{{cat.name}} ({{cat.result.length}})</a>
                                <ul class="results">
                                    <li v-for="(resItem, index) in cat.result" v-if="index < 3" :key="resItem.key" class="result-item">
                                        <a v-bind:href="resItem.url">{{resItem.heading}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </transition-group>
                    <div class="search-footer">
                        <a v-bind:href="allbtnurl + '?query=' + searchString" class="btn btn-primary tt-orange">{{allbtnlabel}}</a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import VScrollLock from 'v-body-scroll-lock';

    let SearchParams = function () {
        this.Query = "";
        this.Culture = "";
    }
    Vue.use(VScrollLock);

    export default Vue.component('searchinput-mobile', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                isActive: false,
                searchString: '',
                searchResult: [],
                keyStartIndex: 2,
                awaitingSearch: false,
                inputFocused: false,
                searchParams: new SearchParams(),
            }
        },
        props: {
            searchplaceholder: {
                type: String,
                required: false,
                default: 'Search'
            },
            allbtnlabel: {
                type: String,
                required: false,
                default: 'Visa alla träffar'
            },
            allbtnurl: {
                type: String,
                required: true
            },
            culture: {
                type: String,
                required: true
            }
        },
        mounted: function () {

        },
        methods: {
            activateMobileSearch: function () {
                this.isActive = true;
                document.body.classList.add('searchmobile-active');
                this.$nextTick(() => this.$refs.searchinputmobile.focus());
            },
            deactivateMobileSearch: function (event = undefined) {
                if (event == undefined || event.target.id != 'mobilesearchinput') {
                    this.isActive = false;
                    this.searchString = '';
                    this.searchResult = [];
                    document.body.classList.remove('searchmobile-active');
                }
            },
            clearSearch: function () {
                if (this.searchString == '') {
                    this.deactivateMobileSearch(undefined);
                }
                else{
                    this.searchString = '';
                    this.searchResult = [];
                    this.$refs.searchinputmobile.focus();
                }
            },
            toSearchResult: function () {
                window.location.href = this.allbtnurl + '?query=' + this.searchString;
            },
            performSearch: function () {
                this.searchParams.Query = this.searchString;
                this.Culture = this.culture;

                this.$http.post('/api/search/freetextcategorized', this.searchParams).then((response) => {
                    this.searchResult = response.body;
                }, (response) => {
                    console.error(response);
                });
            },
            handleBlur: function (event) {
                this.inputFocused = false;
            },
            handleFocus: function (event) {
                this.inputFocused = true;
            }
        },
        watch: {
            searchString: function (val) {
                if (val.length >= this.keyStartIndex) {
                    if (!this.awaitingSearch) {
                        setTimeout(() => {
                            this.performSearch();
                            this.awaitingSearch = false;
                        }, 500);
                    }
                    this.awaitingSearch = true;
                }
            },
        },
        computed: {

        }
    });
</script>
