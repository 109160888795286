var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeCollapse,
          expression: "closeCollapse"
        }
      ],
      staticClass: "select-filter-component"
    },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-filter",
          attrs: {
            "data-bs-parent": _vm.parentElement,
            "data-bs-target": "#" + _vm.kebabCase(_vm.caption),
            "data-bs-toggle": "collapse"
          }
        },
        [_vm._v(_vm._s(_vm.caption))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "collapse",
          attrs: {
            id: _vm.kebabCase(_vm.caption),
            "data-bs-parent": ".select-filters"
          }
        },
        [
          _vm._l(_vm.options, function(option, index) {
            return [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkedFilters.Options,
                    expression: "checkedFilters.Options"
                  }
                ],
                attrs: { type: "checkbox", id: option + "option-" + index },
                domProps: {
                  value: option,
                  checked: Array.isArray(_vm.checkedFilters.Options)
                    ? _vm._i(_vm.checkedFilters.Options, option) > -1
                    : _vm.checkedFilters.Options
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.checkedFilters.Options,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = option,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.checkedFilters,
                            "Options",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.checkedFilters,
                            "Options",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.checkedFilters, "Options", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: option + "option-" + index } }, [
                _vm._v("\n                " + _vm._s(option) + "\n            ")
              ])
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }