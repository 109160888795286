<template>
    <div class="employee-listing">
        <div class="select-filters">
            <select-filter v-for="(filter, index) in filters"
                           :key="filter.Property"
                           :caption="filter.Name"
                           :options="getOptions(employees, filter.Property)"
                           :checked-filters.sync="checkedFilters[index]"
                           @update="update">
            </select-filter>
        </div>
        
            <transition-group name="tt-list-fade" tag="div" class="employee-listing-module">
                <a v-for="employee in filteredEmployees"
                   :key="employee.Key"
                   :href="employee.Url"
                   class="employee">
                    <img :src="employee.Image" alt="" v-if="employee.Image" />

                    <div class="no-img" v-if="!employee.Image">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M184.32 243.392c23.136 42.592 51.664 51.536 71.52 51.536 19.824 0 48.352-8.928 71.68-51.424l1.552-2.816c12.512-22.72 20.784-37.728 20.784-73.072 0-55.616-11.168-112.448-94.016-112.448-93.472 0-93.472 75.952-93.472 112.448 0 35.376 8.176 50.432 20.544 73.2l1.408 2.576zm268.016 126.8L348.144 323.52a12.122 12.122 0 00-9.568-.112l-53.936 22.688h-57.152l-53.952-22.528c-3.04-1.28-6.48-1.232-9.52.112L59.68 370.24a11.985 11.985 0 00-7.104 10.96v63.632c0 6.624 5.376 12 12 12h382.848c6.624 0 12-5.376 12-12v-63.696c0-4.72-2.784-9.008-7.088-10.944z"></path>
                        </svg>
                    </div>

                    <p class="full-name">{{ employee.FullName }}</p>
                    <p class="work-title">{{ employee.WorkTitle }} {{ employee.City }}</p>
                    <p class="email">
                        <a :href="mailto(employee.Email)">{{ employee.Email }}</a>
                    </p>
                    <p class="phone">
                        <a :href="tel(employee.Phone)">{{ employee.Phone }}</a>
                    </p>
                    <p class="phone" v-if="employee.Mobile != ''">
                        <a :href="tel(employee.Mobile)">{{ employee.Mobile }}</a>
                    </p>

                </a>
            </transition-group>
        

        <div class="show-all d-flex flex-row justify-content-center" v-if="!showAll">
            <button class="btn btn-primary tt-orange" @click="showAll=true">{{showAllEmployeesText}}</button>
        </div>

    </div>
</template>
<script lang="ts">
    import _ from "lodash";
    import Vue from "vue";
    import SelectFilter from "../filters/select-filter.vue";

    export default Vue.component("employee-listing", {
        mounted: () => { },
        components: {
            SelectFilter,
        },

        created: function () {
            _.each(this.filters, (f) => {
                this.checkedFilters.push({ Property: f.Property, Options: [] });
            });
        },

        data: () => {
            return { 
                checkedFilters: [],
                showAll: false,
            };
        },

        props: ["employees", "filters", "showAllEmployeesText", "parent-element"],

        computed: {
            filteredEmployees() {
                const applyFilters = () => {

                    var filteredEmployees = [...this.employees];

                    //debugger
                    this.checkedFilters
                        .forEach((filter) => {
                            
                            filteredEmployees = filteredEmployees.filter((e) => {
                                if (filter.Options.length == 0) return true;

                            return _.indexOf(filter.Options, e[filter.Property]) >= 0;
                        });
                        
                    });
                    
                    return filteredEmployees;
                };

                const filtersApplied = () => {
                    return _.some(this.checkedFilters, (c) => {
                        return c.Options.length > 0;
                    });
                };

                let result = null;

                if (filtersApplied()) {
                    result =  applyFilters();
                }
                else {
                    result = [...this.employees];
                }

                if(!this.showAll) {
                    result = _.take(result,12);
                }

                return result;

                
            },
        },

        methods: {
            mailto(email) {
                return `mailto:${email}`;
            },
            tel(phone) {
                return `tel:${phone}`;
            },
            getOptions(items, property) {
                let unique = Array.from(new Set(items.map((e) => e[property])));
                return unique;
            },
            update: function (checked) {
                //Ett filter har blivit avmarkerat och innehåller inga val
                if (checked.checkedFilters.length < 1) {
                    _.remove(this.checkedFilters, (f) => {
                        return f.property == checked.property;
                    });
                } else {
                    //Ett nytt filter med valda värden
                    _.remove(this.checkedFilters, (f) => {
                        return f.property == checked.property;
                    });
                    this.checkedFilters.push(checked);
                }

                this.checkedFilters = [...this.checkedFilters];
            },
        },
    });
</script>
<style>
</style>
