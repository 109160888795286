<template>
    <div class="product-list">
        <div class="row">
            <div class="col-sm-12 d-flex position-relative">
                <div class="toggle-button" @click="listView = !listView">
                    <span v-if="listView">{{togglecardlabel}}</span>
                    <span v-if="!listView">{{togglelistlabel}}</span>
                    <div class="toggler-icon" v-bind:class="{'active': listView}">
                        <span class="l1"></span>
                        <span class="l2"></span>
                        <span class="l3"></span>
                    </div>
                </div>
            </div>
        </div>
        <transition-group name="fade" tag="div" class="product-list-items row" v-bind:class="{'list-view': listView}">
            <div v-bind:class="[!listView ? cardcols : 'col-12']"
                 v-for="(product) in products"
                 :key="product.Key">
                <product-card :product="product" :key="product.Key"
                              :listview="listView"
                              :customer="customer"
                              :calltoaction="calltoaction"
                              :articlnrlabel="articlnrlabel"
                              :translations="translations"
                              @favoriteOrdersClicked="opentFavoritesList"
                              :rsklabel="rsklabel">
                </product-card>
                
            </div>
        </transition-group>
        <favorites-popover 
                           :typedtranslations="translations"
                           :customer="customerObject" v-if="editFavorites"
                           :favoriteproduct="currentFavoriteProduct"
                           @closeFavorites="closeAndSaveFavoritesList">
        </favorites-popover>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import ProductCard from './productcard.vue';
    import FavoritesPopover from './favoritespopover.vue';

    export default Vue.component('product-list', {

        components: {
            ProductCard,
            FavoritesPopover
        },
        data: function () {
            return {
                listView: false,
                editFavorites: false,
                currentFavoriteProduct: {},
                customerObject: {}
            }
        },
        props: {
            products: {
                required: true,
                type: Array
            },
            calltoaction: {
                type: String,
                required: true
            },
            customer: {
                required: false,
                type: Object,
            },
            rsklabel: {
                type: String,
                required: true
            },
            articlnrlabel: {
                type: String,
                required: true
            },
            togglelistlabel: {
                type: String,
                required: true
            },
            togglecardlabel: {
                type: String,
                required: true
            },
            cardcols: {
                type: String,
                default: 'col col-sm-6 col-md-4 col-lg-3'
            },
            translations: {
                type: Object
            }
        },
        mounted: function () {
            this.customerObject = this.customer;
        },
        methods: {
            closeAndSaveFavoritesList(customer) {
                this.editFavorites = false;
                this.customerObject = customer;
            },
            opentFavoritesList(productItem) {
                this.currentFavoriteProduct = productItem;
                this.editFavorites = !this.editFavorites;
            }
        }
    });
</script>