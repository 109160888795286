import { Guid } from './_guid';
import { EventHelper } from "../helpers/_event-helper";
import device from "current-device";


export class Videos {
    constructor(node) {

        this.el = node ? node : document;
        this.vimeoScriptAdded = false;
        this.youtubeScriptAdded = false;
        this.eventsAdded = false;
        this.heroVideos = this.el.querySelectorAll('.video-hero');
        this.videos = this.el.querySelectorAll('.video-direct');
        this.videoModals = this.el.querySelectorAll('.video-modal');
        this.viemoThumbnails = this.el.querySelectorAll('.vimeo-thumbnail');
        this.viemoThumbnails.forEach(img => {
            new VimeoThumbnail(img);
        })

        if (!this.eventsAdded) {
            this.addEvents();
        } 
    }

    addEvents() {

        // this.heroVideos      
        this.heroVideos.forEach(el => {
            if (el.classList.contains('video-vimeo')) {
                if (!this.vimeoScriptAdded) {
                    this.addVimeoScript().then(() => {
                        new VideoHero(el);
                    });
                } else {
                    new VideoHero(el);
                }
            } else if (el.classList.contains('video-youtube')) {
                if (!this.youtubeScriptAdded) {
                    this.addYoutubeScript().then(() => {
                        new VideoHero(el);
                    });
                } else {
                    new VideoHero(el);
                }
            } else {
                new VideoHero(el);
            }
        });

        this.videoModals.forEach(el => {

            try {
                //z-index fix. Move last in body.
                document.body.appendChild(el);
            } catch (e) {

            }

            if (el.classList.contains('video-modal-vimeo')) {
                if (!this.vimeoScriptAdded) {
                    this.addVimeoScript().then(() => {
                        new VideoPopup(el);
                    });
                } else {
                    new VideoPopup(el);
                }
            } else if (el.classList.contains('video-modal-youtube')) {
                if (!this.youtubeScriptAdded) {
                    this.addYoutubeScript().then(() => {
                        new VideoPopup(el);
                    });
                } else {
                    new VideoPopup(el);
                }
            } else {
                new VideoPopup(el);
            }
        });

        this.videos.forEach(el => {
            if (el.classList.contains('video-vimeo')) {
                if (!this.vimeoScriptAdded) {
                    this.addVimeoScript().then(() => {
                        new Video(el);
                    });
                } else {
                    new Video(el);
                }
            } else if (el.classList.contains('video-youtube')) {
                if (!this.youtubeScriptAdded) {
                    this.addYoutubeScript().then(() => {
                        new Video(el);
                    });
                } else {
                    new Video(el);
                }
            } else {
                new Video(el);
            }
        });

        this.eventsAdded = true;
    }

    addVimeoScript() {
        return new Promise((resolve, reject) => {

            let vimeoscript = document.getElementById('vimeo-script');

            if (!vimeoscript) {
                let tag = document.createElement('script');

                tag.onload = () => {
                    this.vimeoScriptAdded = true;
                    resolve(true);
                }
                tag.onerror = () => {
                    reject(false);
                }

                tag.src = "https://player.vimeo.com/api/player.js";
                let firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            } else {
                resolve(true);
            }
        });
    }

    addYoutubeScript() {
        return new Promise((resolve, reject) => {

            let ytscript = document.getElementById('yt-script');

            if (!ytscript) {

                if (typeof window.onYouTubeIframeAPIReady === "undefined") {

                    // safe to use the function
                    window.onYouTubeIframeAPIReady = function () {

                        try {
                            var event = new CustomEvent('YouTubeIframeAPIReady', { detail: Date.now() });
                            // Dispatch the event.
                            document.dispatchEvent(event);
                        } catch (e) {
                            console.warn("onYouTubeIframeAPIReady", e);
                        }
                    }
                }

                let tag = document.createElement('script');

                tag.onload = () => {
                    this.youtubeScriptAdded = true;
                    resolve(true);
                }
                tag.onerror = () => {
                    reject(false);
                }

                tag.src = "https://www.youtube.com/iframe_api";
                tag.id = 'yt-script';
                let firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            } else {
                resolve(true);
            }
        });
    }
}

export class VideoHero {
    constructor(el) {    
        this.eventHelper = new EventHelper('videopopup');
        this.loaded = false;

        this.el = el;
        this.hero = el.parentNode;

        this.vid = this.el.dataset.vid;
        this.id = `v-${Guid()}`;//this.el.dataset.pvid;
        this.el.querySelector('.video-placeholder').id = this.id;
        this.url = this.el.dataset.href;
        this.player = null;
        this.type = this.el.dataset.type;

        if (this.el.dataset.start && this.el.dataset.end) {
            this.start = parseInt(this.el.dataset.start);
            this.end = parseInt(this.el.dataset.end);
        } else {
            this.start = 0
            this.end = 0;
        }

        if (this.type === 'vimeo') {
            this.addVimeoPlayer();
        }
        else if (this.type === 'mp4') {
            this.addNativePlayer();
        }
        else {
            document.addEventListener('YouTubeIframeAPIReady', (e) => {
                this.addYTPlayer();
            }, false);
        }

        this.eventHelper.on('open', ev => {
            //Kolla om det är samma vid (video ID) som öppnas i en popup
            if (ev.detail.vid === this.vid) {
                if (this.type === 'vimeo') {
                    this.player.pause();
                }
                else if (this.type === 'mp4') {
                    this.player.pause();
                }
                else {
                    this.player.pauseVideo();
                }
            }            
        });

        this.eventHelper.on('close', ev => {

             //Kolla om det är samma vid (video ID) som stängs en popup
            if (ev.detail.vid === this.vid) {
                if (this.type === 'vimeo') {
                    this.player.getPaused().then((paused) => {
                        if (paused)
                            this.player.play();
                    }).catch(function (error) {
                        console.warn(error);
                    });
                }
                else if (this.type === 'mp4') {
                    this.player.play();
                }
                else {
                    if (this.player.getPlayerState() === 2) {
                        this.player.playVideo();
                    }
                }
            }            
        });

    }

    addNativePlayer() {
        this.player = document.createElement('video');
        this.player.src = this.url;
        this.player.muted = true;
        this.player.loop = true;

        this.el.querySelector('.video-placeholder').innerHTML = '';

        this.el.querySelector('.video-placeholder').appendChild(this.player);

        this.loaded = true;

        if (this.end != 0) {

            let end = this.end;
            let start = this.start;

            this.player.currentTime = this.start;
            this.player.play();


            let interval = setInterval(() => {
                    if (this.player.currentTime >= end || this.player.currentTime < start) {
                        this.player.currentTime = this.start;
                    }
                
            }, 200);

        }
        else {
            this.player.autoplay = true;
        }
    }

    addVimeoPlayer() {
        this.player = new Vimeo.Player(this.id, {
            autoplay: true,
            url: this.vid,
            background: false,
            dnt: true,
            muted: true,
            loop: true,
        });

        if (this.end != 0) {

            let end = this.end;
            let start = this.start;

            this.player.on('loaded', () => {
                try {
                    this.hero.dataset.loaded = this.loaded = true;
                    this.player.setCurrentTime(start);
                    this.player.play();
                }
                catch (error) {
                    console.warn('Vimeo play', error);
                }
            });

            this.player.getDuration().then((duration) => {
                if (duration >= end && start < end) {
                    this.player.setCurrentTime(start).then((seconds) => {
                        // `seconds` indicates the actual time that the player seeks to
                    }).catch((error) => {
                        console.error('player.setCurrentTime(' + start + ')', error);
                    });

                    let interval = setInterval(() => {
                        this.player.getCurrentTime().then((seconds) => {
                            if (seconds >= end || seconds < start) {
                                this.player.setCurrentTime(start);
                            }
                        });
                    }, 200);
                }
            });

        } else {

            this.player.on('loaded', () => {
                try {
                    this.hero.dataset.loaded = this.loaded = true;
                    this.player.play();
                }
                catch (error) {
                    console.warn('Vimeo play', error);
                }
            });
        }


        return false;
    }

    addYTPlayer(vid) {

        let _this = this;

        let vars = {
            rel: 0, modestbranding: 1, controls: 0, autoplay: 1, playsinline: 0,
            iv_load_policy: 3, cc_load_policy: 1, fs: 1, muted: 1,
            disablekb: 1
        };

        let start = 0;
        let end = this.end;
        if (this.start != 0) {
            start = vars.start = this.start;
        }

        this.player = new YT.Player(this.id, {
            videoId: this.vid,
            width: '560',
            height: '315',
            host: 'https://www.youtube-nocookie.com',
            playerVars: vars
        });

        this.player.addEventListener('onStateChange', (e) => {
            e.target.mute();

            if (e.data === 1) {
                this.el.classList.add('yt-ready');
            }
        });

        this.player.addEventListener('onReady', (e) => {
            
            e.target.mute();
            e.target.playVideoAt(start);
            e.target.playVideo();
            this.hero.dataset.loaded = this.loaded = true;
        });


        let interval = setInterval(() => {
            //this.player.mute();
            let time = this.player.playerInfo.currentTime;
            if (time >= end) {
                this.player.seekTo(start, true);
            }
        }, 200);       

        return false;
    }
}

export class VimeoThumbnail {
    constructor(el) {
        this.el = el;

        if (el.dataset.vid) {
            fetch(`https://vimeo.com/api/v2/video/${el.dataset.vid}.json`)
                .then(response => {
                    return response.text();
                })
                .then(data => {
                    try {
                        const { thumbnail_large } = JSON.parse(data)[0];

                        if (this.el.classList.contains('lazyload')) {
                            this.el.dataset.src = thumbnail_large;
                        }

                        this.el.src = thumbnail_large;
                    } catch (e) {

                    }
                })
                .catch(error => {
                   // console.warn(error);
                });
        }
    }    
}

export class VideoPopup {
    constructor(el) {
        this.eventHelper = new EventHelper('videopopup');
        this.loaded = false;

        this.el = el;
        this.vid = this.el.dataset.vid;
        this.url = this.el.dataset.href;
        this.id = `v-${Guid()}`;//this.el.dataset.pvid;
        this.el.querySelector('.video-placeholder').id = this.id;
        this.player = null;
        this.type = this.el.dataset.type;


       // console.log('constructor this.type ', this.type);

        el.addEventListener('show.bs.modal', (ev) => {
            if (!this.loaded) {

               // console.log('this.type ', this.type);

                if (this.type === 'vimeo') {
                    this.addVimeoPlayer();
                }
                else if (this.type === 'youtube') {
                    this.addYTPlayer();
                }
                else if (this.type === 'mp4') {
                    this.addNativePlayer();
                }
            } else {
                if (this.type === 'vimeo') {
                    this.player.play();
                }
                else if (this.type === 'youtube') {
                    this.player.playVideo();
                }
                else if (this.type === 'mp4') {
                    this.player.play();
                }
            }
            this.eventHelper.throw('open', { vid: this.vid, url: this.url })
        });

        el.addEventListener('hide.bs.modal', (ev) => {
            if (this.loaded) {
                if (this.type === 'vimeo') {
                    this.player.pause();
                }
                else if (this.type === 'youtube') {
                    this.player.pauseVideo();
                }
                else if (this.type === 'mp4') {
                    this.player.pause();
                }
            }

            this.eventHelper.throw('close', { vid: this.vid, url: this.url })
        });
    }

    addNativePlayer() {


        ///console.log('addNativePlayer')

        this.player = document.createElement('video');
        this.player.src = this.url;
        this.player.autoplay = true;
        this.el.querySelector('.video-placeholder').innerHTML = '';
        this.el.querySelector('.video-placeholder').appendChild(this.player);
        this.loaded = true;

    }

    addVimeoPlayer() {

        this.player = new Vimeo.Player(this.id, {
            autoplay: true,
            url: this.vid,
            byline: false,
            controls: false,
            transparent: true,
            title: false
        });

        this.player.on('loaded', () => {
            try {
                this.loaded = true;
                this.player.play();
            }
            catch (error) {
                console.warn('Vimeo play', error);
            }
        });

        return false;
    }

    addYTPlayer(vid) {

        let _this = this;

        this.player = new YT.Player(this.id, {
            videoId: this.vid,
            width: '560',
            height: '315',
            host: 'https://www.youtube-nocookie.com',
            playerVars: { rel: 0, modestbranding: 1, autoplay: 1, playsinline: 0, iv_load_policy: 3, cc_load_policy: 1, fs: 1 }
        });

        this.player.addEventListener('onReady', (e) => {
            e.target.playVideo();
            this.loaded = true;
        });


        return false;
    }
}

export class Video {
    constructor(el) {

        if (!el)
            return;


        this.el = el;
        this.link = this.el.firstElementChild;
        this.type = this.link.dataset.type;

        if (!this.link || this.link.tagName.toLowerCase() !== 'a') {
            return;
        }

        this.url = this.link.href;
        this.vid = this.link.dataset.vid;
        this.id = this.link.dataset.type === 'vimeo' ? this.el.id : this.link.id;

        if (this.type === 'vimeo') {
            this.addVimeoEvent();
        }
        else if (this.type === 'youtube') {
            this.addYoutubeEvent();
        }
        else if(this.type === 'mp4'){
            this.addNativeEvent();
        }
        else {
            console.warn('Unknown video type');
        }
    }

    addNativeEvent() {
        ///console.log('addNativePlayer')
        this.link.addEventListener('click', this.addNativePlayer.bind(this));
    }

    addYoutubeEvent() {
        this.link.addEventListener('click', this.addYTPlayer.bind(this));
    }

    addVimeoEvent() {
        this.link.addEventListener('click', this.addVimeoPlayer.bind(this));
    }

    addVimeoPlayer(e) {

        e.preventDefault();
        this.el.id = this.id = `v-${Guid()}`;

        var player = new Vimeo.Player(this.id, {
            autoplay: true,
            url: this.vid,
            byline: false,
            controls: true,
            transparent: true,
            title: false
        });

        player.on('loaded', () => {
            try {
                player.play();
            }
            catch (error) {
                console.warn('Vimeo play', error);
            }
        });

        setTimeout(() => {
            this.el.querySelector('.video-placeholder').remove();
            this.link.remove();

        }, 10);

        return false;
    }

    addYTPlayer(e) {

        e.preventDefault();

        this.id = `v-${Guid()}`;
        this.link.id = this.id;

        let player = new YT.Player(this.id, {
            videoId: this.vid,
            width: '560',
            height: '315',
            host: 'https://www.youtube-nocookie.com',
            playerVars: { rel: 0, modestbranding: 1, autoplay: 1, playsinline: 0, iv_load_policy: 3, cc_load_policy: 1, fs: 1 }
        });

        player.addEventListener('onReady', (e) => {
            e.target.playVideo();
        });

        setTimeout(() => {
            this.link.remove();
            this.el.querySelector('.video-placeholder').remove();
        }, 10);

        return false;
    }

    addNativePlayer(e) {

        e.preventDefault();
        this.player = document.createElement('video');
        this.player.src = this.url;
        this.player.autoplay = true;
        this.el.querySelector('.video-placeholder').innerHTML = '';
        this.el.querySelector('.video-placeholder').appendChild(this.player);
        this.loaded = true;
        this.player.controls = true;

        setTimeout(() => {
            this.link.remove();
        }, 10);
    }
}
