<template>
    <div class="modal-container">
        <div class="modal-container_backdrop" @click="closeModal(false)"></div>
        <div class="container">
            <div class="row">
                <div class="col offset-md-2 offset-lg-1 col-md-8 col-lg-10 col-sm-12">
                    <div class="modal-container_content">
                        <div class="modal-container_header">
                            {{message}}
                            <div class="modal-container_close-btn btn btn-close-dark" @click="closeModal(false)"></div>
                        </div>
                        <div class="modal-container_footer">
                            <div class="modal-container_main-content">
                                <slot></slot>
                                <div role="group" aria-label="toggle" class="btn-group" v-if="showMobalButtons">
                                    <div class="btn btn-secondary" @click="closeModal(true)">
                                        {{accept}}
                                    </div>
                                    <div class="btn btn-secondary active" @click="closeModal(false)">
                                        {{decline}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { gsap } from "gsap";


    export default Vue.component('modal', {
        data: function () {
            return {
                closing: false
            }
        },
        mounted: function () { },
        props: {
            message: {
                default: 'Sure?',
                type: String,
            },
            accept: {
                default: 'Yes',
                type: String,
            },
            decline: {
                default: 'No',
                type: String,
            },
            showMobalButtons: {
                default: true,
                type: Boolean
            }
        },
        methods: {
            closeModal(acceptance) {
                if (!this.closing) {
                    this.$emit('closeModal', acceptance, this.reference);
                    this.closing = true;
                }   
            },
            onBeforeEnter(el) {
                this.closing = false;

                el.style.opacity = 0;
                gsap.to(el, {
                    scale: 0.9,
                    duration: 0,
                    alpha: 0,
                    translateY: 500
                })
            },
            onEnter(el, done) {
                gsap.to(el, {
                    scale: 1,
                    alpha: 1,
                    duration: .5,
                    x: 0,
                    translateY: 0,
                    onComplete: done,
                    delay: el.dataset.index * 0.05
                })
            },
        }
    });
</script>