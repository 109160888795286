<template>
    <div class="select-filter-component" v-click-outside="closeCollapse">
        <button :data-bs-parent="parentElement" class="btn btn-primary btn-filter" :data-bs-target="'#'+kebabCase(caption)" data-bs-toggle="collapse">{{caption}}</button>
        <div class="collapse" :id="kebabCase(caption)" data-bs-parent=".select-filters">
            <template v-for="(option,index) in options">
                <input :value="option" v-model="checkedFilters.Options" type="checkbox" :id="option + 'option-' + index" />
                <label :for="option + 'option-' + index">
                    {{option}}
                </label>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import _ from 'lodash';

    export default Vue.component('select-filter', {
        props: ['options', 'property', 'parentElement', 'checkedFilters', 'caption'],

        methods: {
            kebabCase : function(str) {    
                let returnVal =  _.kebabCase(str);
                return returnVal;
            },
            closeCollapse: function () {
                document.querySelectorAll('.collapse').forEach(function (collapse) {
                    collapse.classList.remove('show');
                });
            }

        },
    });

</script>