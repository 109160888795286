var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "simple-filter" },
    [
      _vm.filteritems.length
        ? _c("h4", [_vm._v(_vm._s(_vm.toplabel))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filters" },
        _vm._l(_vm.filteritems, function(filter, index) {
          return _c(
            "button",
            {
              key: filter.category,
              staticClass: "btn btn-primary btn-filter",
              class: { active: _vm.filterStatus(filter) },
              on: {
                click: function($event) {
                  return _vm.pushFilters(filter)
                }
              }
            },
            [_vm._v("\n            " + _vm._s(filter.category) + "\n        ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.selectedFilters.length
          ? _c(
              "div",
              { staticClass: "active-filters" },
              [
                _c("h4", [_vm._v(_vm._s(_vm.selectedlabel))]),
                _vm._v(" "),
                _vm._l(_vm.selectedFilters, function(selectedFilter, index) {
                  return _c(
                    "button",
                    {
                      key: selectedFilter.category,
                      staticClass: "btn btn-secondary btn-selected active",
                      on: {
                        click: function($event) {
                          return _vm.pushFilters(selectedFilter)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(selectedFilter.category) +
                          "\n            "
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-selected",
                    on: { click: _vm.clearFilters }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.clearlabel) +
                        "\n            "
                    )
                  ]
                )
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }