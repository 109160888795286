var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.deactivate,
          expression: "deactivate"
        }
      ],
      ref: "main",
      staticClass: "search-input",
      class: { active: _vm.isActive, disabled: !_vm.isActive },
      attrs: { tabindex: "0" }
    },
    [
      _c(
        "svg",
        {
          staticClass: "search-icon",
          attrs: {
            width: "20",
            height: "20",
            viewBox: "0 0 20 20",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z",
              stroke: "#303030",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M17.5 17.5L13.875 13.875",
              stroke: "#303030",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }
          })
        ]
      ),
      _vm._v(" "),
      _vm.isActive && _vm.searchString.length > 0
        ? _c(
            "button",
            {
              staticClass: "btn-clear",
              attrs: { tabindex: _vm.searchResult.length * 5 + 1 },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.clearSearch.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "10",
                    height: "9",
                    viewBox: "0 0 10 9",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M2 1.46094L8.29422 7.5392",
                      stroke: "black",
                      "stroke-width": "1.5",
                      "stroke-linecap": "square",
                      "stroke-linejoin": "round"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M2.10742 7.64648L8.18568 1.35226",
                      stroke: "black",
                      "stroke-width": "1.5",
                      "stroke-linecap": "square",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchString,
            expression: "searchString"
          }
        ],
        ref: "ip",
        attrs: {
          id: "mainsearchinput",
          autocomplete: "off",
          placeholder: _vm.searchplaceholder,
          tabindex: "1"
        },
        domProps: { value: _vm.searchString },
        on: {
          click: _vm.activate,
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.toSearchResult.apply(null, arguments)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchString = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.searchResult.length
          ? _c("div", { staticClass: "searchresult" }, [
              _c(
                "div",
                { staticClass: "inside-result" },
                [
                  _c(
                    "transition-group",
                    { attrs: { name: "tt-list-fade", tag: "ul" } },
                    _vm._l(_vm.searchResult, function(cat, index) {
                      return _c("ul", { key: cat.name }, [
                        _c("li", { staticClass: "cat" }, [
                          _c(
                            "a",
                            {
                              staticClass: "label",
                              attrs: {
                                tabindex: index + 4,
                                href:
                                  _vm.allbtnurl +
                                  "?query=" +
                                  _vm.searchString +
                                  "&filter=" +
                                  cat.name
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(cat.name) +
                                  " (" +
                                  _vm._s(cat.result.length) +
                                  ")"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "results" },
                            _vm._l(cat.result, function(resItem, childIndex) {
                              return childIndex < 3
                                ? _c(
                                    "li",
                                    {
                                      key: resItem.key,
                                      staticClass: "result-item"
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: resItem.url,
                                            tabindex: index + 5
                                          }
                                        },
                                        [_vm._v(_vm._s(resItem.heading))]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "search-footer" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary tt-orange",
                    attrs: {
                      href: _vm.allbtnurl + "?query=" + _vm.searchString,
                      tabindex: _vm.searchResult.length * 2
                    }
                  },
                  [_vm._v(_vm._s(_vm.allbtnlabel))]
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }