<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 category-top">
                <h1>{{categoryname}}</h1>
            </div>
            <div class="col-sm-12">
                <div id="product-searchinput">
                    <product-searchinput :productsearchpage="productsearchpage"
                                         :searchbuttonlabel="getDictionaryItem(translations, 'Products.Search')"
                                         :searchplaceholder="getDictionaryItem(translations, 'Products.SearchPlaceHolder')">
                    </product-searchinput>
                </div>
            </div>
            <div class="tree col-md-4 col-lg-3 col-12">

                <div id="product-filter">
                    <product-filter :filters.sync="filterdata" @filtering="filtering" :initiallyselectedfilters="initiallyselectedfilters"></product-filter>
                </div>

                <tree :category="tree" :activefilters="activeFilters"></tree>
            </div>
            <div class="categories col-md-8 col-lg-9 col-sm-12">

                <div v-if="haveProducts" id="products-list">
                    <product-list :products="productdata"
                                  :calltoaction="calltoaction"
                                  :customer="customer"
                                  :articlnrlabel="getDictionaryItem(translations, 'Products.ArticleNumber')"
                                  :rsklabel="getDictionaryItem(translations, 'Products.RSKNumber')"
                                  :togglelistlabel="getDictionaryItem(translations, 'Products.ShowAsList')"
                                  :togglecardlabel="getDictionaryItem(translations, 'Products.ShowAsCards')"
                                  :translations="translations"
                                  cardcols="col col-sm-6 col-lg-4">
                    </product-list>
                </div>
                <div v-else>
                    <category-list :categories="categoriesdata"
                                   :activefilters="activeFilters"
                                   :togglelistlabel="getDictionaryItem(translations, 'Products.ShowAsList')"
                                   :togglecardlabel="getDictionaryItem(translations, 'Products.ShowAsCards')"
                                   cardcols="col col-12 col-sm-6 col-lg-4">
                    </category-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import ProductSearchInput from './productsearchinput.vue';
    import ProductList from './productlist.vue';
    import CategoryList from './categorylist.vue';
    import ProductFilter from './productfilter.vue';
    import Tree from './tree/tree.vue';
    
    import _ from 'lodash';
    export default Vue.component('products', {
        created: function () {
            this.filterdata = this.filters;
        },
        methods: {
            filtering: function (id, checked) {
                if (checked) {
                    if (_.find(this.activeFilters, (f) => f == id) === undefined) {
                        this.activeFilters.push(id);
                    }
                }
                else {
                    _.remove(this.activeFilters, (f) => f == id);
                    this.activeFilters = [...this.activeFilters]
                }

                this.replaceHistory();
            },
            replaceHistory: function () {
                if (this.activeFilters && this.activeFilters.length > 0) {
                    window.history.replaceState({}, '', `?f=${this.activeFilters.join("&f=")}`)
                }
                else {
                    window.history.pushState({}, '', '')
                }
            }
        },

        computed: {
            productdata: function () {
                if (this.activeFilters !== undefined && this.activeFilters.length > 0) {
                    var result = _.filter(this.products, (p) => {
                        let result = _.intersection(p.Filters, this.activeFilters);
                        return result.length > 0
                    });

                    return result;
                }
                else {
                    return this.products;
                }

            },
            haveProducts: function () {
                return this.products.length > 0;
            },
            categoriesdata: function () {
                if (this.activeFilters !== undefined && this.activeFilters.length > 0) {
                    var result = _.filter(this.categories, (c) => {
                        let result = _.intersection(c.FilterIds, this.activeFilters);
                        return result.length > 0
                    });

                    return result;
                }
                else {
                    return this.categories;
                }

            },
        },
        components: {
            ProductSearchInput, ProductList, CategoryList, ProductFilter, Tree
        },
        data: function () {
            return {
                activeFilters: [],
                filterData: [],
            };
        },
        props: {
            'products': {
                required: true,
                type: Array,
            },
            'categories': {
                required: true,
                type: Array,
            },
            'customer': {
                required: false,
                type: Object,
            },
            'tree': {
                required: true,
                type: Object,
            },
            'filters': {
                required: false,
                type: Array,
            },
            'categoryname': {
                required: true,
                type: String,
            },
            'itemscount': {
                required: true,
                type: String,
            },
            'calltoaction': {
                required: false,
            },
            'productsearchpage': {
                required: false
            },
            'initiallyselectedfilters': {
                required: false,
                type: Array,
                default: []
            },
            translations: {
                type: Object
            }

        },

    });
</script>
