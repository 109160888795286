<template>
    <div v-click-outside="deactivate" 
         class="search-input" 
         ref="main" 
         v-bind:class="{ active: isActive, disabled: !isActive }"
         tabindex="0">
        <svg class="search-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.5 17.5L13.875 13.875" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <button v-if="isActive && searchString.length > 0" 
                class="btn-clear" 
                @click.stop="clearSearch" 
                v-bind:tabindex="searchResult.length * 5 + 1">
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 1.46094L8.29422 7.5392" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
                <path d="M2.10742 7.64648L8.18568 1.35226" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
            </svg>
        </button>
        <input id="mainsearchinput" 
               ref="ip"
               autocomplete="off"
               @click="activate" 
               v-on:keyup.enter="toSearchResult"
               v-model="searchString" 
               :placeholder="searchplaceholder" tabindex="1">

        <transition name="fade">
            <div class="searchresult" v-if="searchResult.length">
                <div class="inside-result">
                    <transition-group name="tt-list-fade" tag="ul">
                        <ul v-for="(cat, index) in searchResult" :key="cat.name">
                            <li class="cat">
                                <a class="label" v-bind:tabindex="index + 4" v-bind:href="allbtnurl + '?query=' + searchString + '&filter=' + cat.name">{{cat.name}} ({{cat.result.length}})</a>
                                <ul class="results">
                                    <li v-for="(resItem, childIndex) in cat.result" v-if="childIndex < 3" :key="resItem.key" class="result-item">
                                        <a v-bind:href="resItem.url" v-bind:tabindex="index + 5">{{resItem.heading}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </transition-group>
                </div>
                <div class="search-footer">
                    <a v-bind:href="allbtnurl + '?query=' + searchString" class="btn btn-primary tt-orange" v-bind:tabindex="searchResult.length * 2">{{allbtnlabel}}</a>  
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import VueResource from 'vue-resource';
    import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

    let SearchParams = function () {
        this.Query = "";
        this.Culture = "";
    }
    Vue.use(VueResource);

    export default Vue.component('searchinput', {

        created: () => {

        },
        components: {

        },
        data: () => {
            return {
                isActive: false,
                keyStartIndex: 2,
                searchString: '',
                awaitingSearch: false,
                searchResult: [],
                searchParams: new SearchParams(),
            }
        },
        props: {
            searchplaceholder: {
                type: String,
                required: false,
                default: 'Search'
            },
            allbtnlabel: {
                type: String,
                required: false,
                default: 'Visa alla träffar'
            },
            allbtnurl: {
                type: String,
                required: true
            },
            culture: {
                type: String,
                required: true
            }
        },
        mounted: () => {

        },
        methods: {
            activate: function () {
                this.isActive = true;
                document.body.classList.add('search-active');
                disableBodyScroll(this.$el, { reserveScrollBarGap: false });
            },
            deactivate: function (event) {
                if (event.target.id != 'mainsearchinput') {
                    this.isActive = false;
                    this.searchString = '';
                    this.searchResult = [];
                    document.body.classList.remove('search-active');
                    enableBodyScroll(this.$el);
                }
            },
            clearSearch: function () {
                this.searchString = '';
                this.searchResult = [];

                this.$nextTick(() => this.$refs.ip.focus())
            },
            performSearch: function () {
                this.searchParams.Query = this.searchString;
                this.searchParams.Culture = this.culture;

                this.$http.post('/api/search/freetextcategorized', this.searchParams).then((response) => {
                    this.searchResult = response.body;
                }, (response) => {
                    console.error(response);
                });
            },
            toSearchResult: function () {
                window.location.href = this.allbtnurl + '?query=' + this.searchString;
            },
        },
        watch: {
            searchString: function (val) {
                if (val.length >= this.keyStartIndex) {
                    if (!this.awaitingSearch) {
                        setTimeout(() => {
                            this.performSearch();
                            this.awaitingSearch = false;
                        }, 500);
                    }
                    this.awaitingSearch = true;
                }
            },
        },
        computed: {

        }
    });
</script>
