var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "cookiefade" } }, [
    _vm.showCookieOptin
      ? _c("div", { staticClass: "cookie-optin" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h4", [_vm._v(_vm._s(_vm.heading))]),
                _vm._v(" "),
                _c("p", { domProps: { innerHTML: _vm._s(_vm.textcontent) } })
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-sm-6 d-sm-flex justify-content-end align-items-center"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary tt-orange",
                      on: { click: _vm.acceptCookies }
                    },
                    [_vm._v(_vm._s(_vm.acceptlabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary tt-verylight-gray",
                      on: { click: _vm.rejectCookies }
                    },
                    [_vm._v(_vm._s(_vm.denylabel))]
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }