<template>
    <div>
        <div class="btn-container" v-if="standalone">
            <div v-if="customer"
                 @click="togglePopOver"
                 class="btn btn-primary tt-orange btn-add-list-white"
                 v-bind:class="{'added': favoriteproduct.AddedToFavoriteList}">
                {{favoriteproduct.AddedToFavoriteList ? getDictionaryItem(typedtranslations, 'FavoriteOrders.AddedToMyList') : getDictionaryItem(typedtranslations, 'FavoriteOrders.AddToMyList')}}
            </div>
        </div>
        <div class="favorites-popover" v-if="isActive">
            <div class="favorites-popover_backdrop"></div>
            <div class="container">
                <div class="row">
                    <transition name="editstate" mode="out-in">
                        <div class="favorites-popover_listeditor edit" v-if="isEditing">
                            <edit-order @saveOrder="upDateOrders"
                                        @saveCloseOrder="endEditingUpdate"
                                        @closeOrder="endEditing"
                                        :customer="customer"
                                        :translations="typedtranslations"
                                        :favoriteorder="currentOrder" />
                        </div>
                        <div class="col col-sm-12 offset-lg-1 col-lg-10 " v-if="!isEditing">
                            <div class="favorites-popover_listeditor" v-click-outside="deactivate">
                                <div class="favorites-popover_header">
                                    {{getDictionaryItem(typedtranslations, 'FavoriteOrders.AddToMyList')}}
                                    <!--<div class="favorites-popover_header_close-btn" @click="closePopover"></div>-->
                                    <div class="btn btn-secondary tt-orange btn-close btn-square" @click="closePopover"></div>
                                </div>
                                <TransitionGroup class="favorites-popover_lists"
                                                 name="favorites-checkboxlist"
                                                 tag="div"
                                                 v-if="activeFavoriteList.length"
                                                 :css="false"
                                                 @before-enter="onBeforeEnter"
                                                 @enter="onEnter"
                                                 @leave="onLeave">
                                    <div v-for="(list) in activeFavoriteList" :key="list.key" class="favorites-popover_list-row">
                                        <label :for="list.Key">
                                            <input type="checkbox" :id="list.Key" v-model="list.AddedToList" @change="toggleProduct(list)">
                                            {{list.title}}


                                        </label>
                                        <div class="btn btn-secondary tt-verylight-gray btn-edit-dark btn-square favorites-popover_edit-btn"
                                             @click="editOrder(list)"
                                             v-bind:class="{'disabled': !list.orderRows.length}"></div>
                                    </div>
                                </TransitionGroup>
                                <div class="favorites-popover_footer">
                                    <div class="favorites-popover_container">
                                        <div class="btn btn-collapse collapsed" data-bs-toggle="collapse" href="#newListCollapse" role="button" aria-expanded="false" aria-controls="newListCollapse">
                                            {{getDictionaryItem(typedtranslations, 'FavoriteOrders.CreateNewList')}}
                                        </div>
                                    </div>

                                    <div class="collapse" id="newListCollapse">
                                        <div class="favorites-popover_container">
                                            <p>{{listnamelabel}}</p>
                                            <input type="text" v-model="newListName" />
                                        </div>
                                        <div class="favorites-popover_row">
                                            <div v-if="newListName != '' && newListName.length > 2" class="btn btn-primary tt-orange" @click="createList">
                                                {{getDictionaryItem(typedtranslations, 'FavoriteOrders.CreateNewList')}} {{newListName}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <transition name="notice">
            <notification-bar v-if="showNotification"
                              @closeModal="closeNotification"
                              :message="noticeMessage"
                               time="5000"/>
            </transition>
</div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { gsap } from "gsap";
    import EditOrder from '../mypages/_editorder.vue';
    import NotificationBar from '../utilities/notification-bar.vue';

    let FavoriteList = function () {
        this.title = "Ny lista";
        this.key = "56sdf45sdf6kjl";
        this.createDate = "2022-05-17";
        this.products = [];
        this.orderRows = [];
    }

    export default Vue.component('favorites-popover', {

        components: {
            EditOrder,
            NotificationBar
        },
        data: function () {
            return {
                listView: false,
                newListName: '',
                isActive: true,
                currentOrder: Object,
                isEditing: false,
                customer: {},
                showNotification: false,
                noticeMessage: ''
            }
        },
        mounted: function () {
            this.favoriteproduct.Count = 1;
            this.isActive = this.active;
            window.addEventListener('keyup', this.escClose);
            this.getCustomerObject();
        },
        destroyed: function () {
            window.removeEventListener('keyup', this.escClose);
        },
        props: {
            customer: {
                required: false,
                type: Object,
            },
            favoriteproduct: {
                type: Object
            },
            active: {
                default: true,
                type: Boolean
            },
            standalone: {
                default: false,
                type: Boolean
            },
            typedtranslations: {
                type: Object
            },
            listnamelabel: {
                type: String,
                default: 'Name on list'
            },
        },
        methods: {
            togglePopOver() {
                this.isActive = !this.isActive;
                this.getCustomerObject();
            },
            displayNotification(added, listName) {
                const listText = '<a href="' + this.customer.favoritOrdersUrl + '">' + listName + '</a>';
                if (added) {
                    this.noticeMessage = this.favoriteproduct.Title + ' ' + this.getDictionaryItem(this.typedtranslations, 'FavoriteOrders.AddedToMyListNotice') + listText + '<i class="notification-bar__icon added"></i>'; 
                }
                else {
                    this.noticeMessage = this.favoriteproduct.Title + ' ' + this.getDictionaryItem(this.typedtranslations, 'FavoriteOrders.RemovedFromMyListNotice') + listText + '<i class="notification-bar__icon removed"></i>';
                }
                this.showNotification = true;
            },
            closeNotification() {
                this.showNotification = false;
            },
            closePopover() {
                if (this.standalone) {
                    this.togglePopOver()
                }
                else {
                    this.$emit('closeFavorites', this.customer);
                }
            },
            toggleProduct(list) {
                if (!list.AddedToList) {
                    this.displayNotification(false, list.title);
                    let id = this.favoriteproduct.Id
                    list.orderRows = list.orderRows.filter(function (el) { return el.id != id; });
                    //list.orderRows = list.orderRows.filter(function (el) { return el.Id != id; });
                    list.products = list.products.filter(function (el) { return el != id; });
                    this.favoriteproduct.AddedToFavoriteList = false;
                }
                else {
                    this.displayNotification(true, list.title);
                    let simpleProduct = {
                        count: this.favoriteproduct.Count,
                        description: this.favoriteproduct.Description,
                        id: this.favoriteproduct.Id,
                        key: this.favoriteproduct.Key,
                        price: this.favoriteproduct.Price,
                        rskNr: this.favoriteproduct.RSKNr,
                        title: this.favoriteproduct.Title,
                        url: this.favoriteproduct.Url
                    }

                    list.orderRows.push(this.favoriteproduct);
                    list.products.push(this.favoriteproduct.Id)
                    this.favoriteproduct.AddedToFavoriteList = true;
                }
                this.upDateFavoriteOrders();
            },
            getCustomerObject() {
                this.$http.post('/api/mypages/getcustomer').then((response) => {
                    this.customer = response.data;
                    this.favoriteproduct.AddedToFavoriteList = this.isFavoriteProduct;

                }, (response) => {
                    console.error(response);
                });
            },
            upDateFavoriteOrders() {
                this.$http.post('/api/mypages/updatefavoriteorders', this.customer).then((response) => {
                    this.customer = response.data;
                }, (response) => {
                    console.error(response);
                });
            },
            createList() {
                let emptyList = new FavoriteList();
                this.$http.post('/api/mypages/newfavoriteorderlist').then((response) => {
                    emptyList.title = this.newListName;
                    emptyList.key = response.data.key;
                    emptyList.createDate = response.data.createDate;
                    this.customer.favoriteOrders.unshift(emptyList);
                    this.upDateFavoriteOrders();
                    this.newListName = '';
                }, (response) => {
                    console.error(response);
                });
            },
            editOrder(order) {
                this.getCustomerObject();
                if (order.orderRows.length) {
                    this.currentOrder = order;
                    this.isEditing = true;
                }
            },
            upDateOrders(order, deletedProduct) {
                if (deletedProduct.id && this.favoriteproduct.Id === deletedProduct.id) {
                    this.favoriteproduct.AddedToFavoriteList = false;
                }

                const objectToReplace = this.customer.favoriteOrders.find(arrayItem => arrayItem.key === this.currentOrder.key);
                if (this.currentOrder.orderRows.length === 0) {
                    this.currentOrder.AddedToList = false;
                }
                Object.assign(objectToReplace, this.currentOrder);
                this.upDateFavoriteOrders();
            },
            endEditing(order) {
                this.isEditing = false;
            },
            endEditingUpdate(order) {
                this.saveFavoriteOrders();
                this.isEditing = false;
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    scale: 0.9,
                    duration: 0,
                    alpha: 0,
                    translateY: 500
                })
            },
            onEnter(el, done) {
                gsap.to(el, {
                    scale: 1,
                    alpha: 1,
                    duration: .5,
                    x: 0,
                    translateY: 0,
                    onComplete: done,
                    delay: el.dataset.index * 0.05
                })
            },
            escClose(ev) {
                if (ev.keyCode === 27) {
                    if (this.isActive && !this.isEditing) {
                        this.closePopover();
                    }
                }
            },
            deactivate(event) {
                if (!event.target.classList.contains('btn') && this.isActive && !this.isEditing) {
                    this.closePopover();
                }
            }
        },
        computed: {
            activeFavoriteList() {
                if (this.customer.favoriteOrders) {
                    this.customer.favoriteOrders.forEach(o => o.AddedToList = false);
                    let favoritList = this.customer.favoriteOrders;
                    favoritList.map((order, index) => {
                        if (order.products.includes(this.favoriteproduct.Id)) {
                            order.AddedToList = true;
                        }
                        else {
                            order.AddedToList = false;
                        }
                    });
                    return favoritList;
                }
                else {
                    return [];
                }
            },
            isFavoriteProduct() {
                var isFavorite = false;
                this.customer.favoriteOrders.forEach(async (order) => {
                    order.orderRows.forEach(async (row) => {
                        isFavorite = row.id === this.favoriteproduct.Id ? true : isFavorite;
                    });
                });

                return isFavorite;
            }
        }
    });
</script>