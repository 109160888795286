var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.listBlueprintsOrderId != null && _vm.viewState === "BLUEPRINTS"
        ? _c(
            "div",
            {
              key: "1",
              staticClass:
                "collapse-item data-table_table-container data-table_table-container--margin-top"
            },
            [
              _c("list-blueprints", {
                attrs: {
                  translations: _vm.translations,
                  orderId: _vm.listBlueprintsOrderId
                },
                on: { closeBlueprints: _vm.hideBlueprints }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.viewState === "BASELIST"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("simple-filter", {
                  ref: "simpleFilter",
                  attrs: {
                    filteritems: _vm.filters,
                    toplabel: "Filter",
                    selectedlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Common.SelectedFilters"
                    ),
                    clearlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "MyPages.ClearAll"
                    )
                  },
                  on: { filtersChanged: _vm.onFilter }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6 data-table_searchdata" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchInput,
                    expression: "searchInput"
                  }
                ],
                class: { active: _vm.inputActive },
                attrs: {
                  type: "text",
                  placeholder: "Sök",
                  "aria-label": "Search criteria",
                  "aria-describedby": "basic-addon2"
                },
                domProps: { value: _vm.searchInput },
                on: {
                  click: function($event) {
                    _vm.inputActive = true
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchInput = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.searchInput != ""
                ? _c(
                    "button",
                    {
                      staticClass: "data-table_btn-clear",
                      attrs: { tabindex: "1" },
                      on: { click: _vm.resetSearch }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "10",
                            height: "9",
                            viewBox: "0 0 10 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M2 1.46094L8.29422 7.5392",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "square",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M2.10742 7.64648L8.18568 1.35226",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "square",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.viewState === "BASELIST"
        ? _c(
            "div",
            { staticClass: "data-table_table-container" },
            [
              _c(
                "div",
                { staticClass: "data-table__mobile-table collapse-component" },
                [
                  _vm._l(_vm.orderCount, function(row, index) {
                    return _vm.loading
                      ? _c("div", { staticClass: "collapse-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-collapse collapsed",
                              attrs: {
                                type: "button",
                                "data-bs-toggle": "collapse",
                                "aria-expanded": "true"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__container container"
                                },
                                [
                                  _c("div", { staticClass: "top-row row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__left-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDictionaryItem(
                                              _vm.translations,
                                              "Common.Loading"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__right-col col"
                                      },
                                      [_vm._v("-")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._m(0, true)
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _c(
                    "transition-group",
                    {
                      attrs: { name: "faderow", tag: "div", css: true },
                      on: {
                        "before-enter": _vm.onBeforeEnter,
                        enter: _vm.onEnter,
                        leave: _vm.onLeave
                      }
                    },
                    _vm._l(_vm.gsOutput(), function(order, index) {
                      return _vm.page * _vm.orderCount > index
                        ? _c(
                            "div",
                            {
                              key: order.orderNumber,
                              staticClass: "collapse-item"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-collapse collapsed",
                                  attrs: {
                                    type: "button",
                                    "data-bs-toggle": "collapse",
                                    "data-bs-target": "#collapse-" + index,
                                    "aria-expanded": "true",
                                    "aria-controls": "collapse-" + index
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-table__mobile-table__container container"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "top-row row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__left-col col"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDictionaryItem(
                                                    _vm.translations,
                                                    "Offers.OrderDate"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__right-col col"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  order.orderDate
                                                    ? order.orderDate.substring(
                                                        0,
                                                        10
                                                      )
                                                    : "-"
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "data-table__mobile-table__left-col col"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDictionaryItem(
                                                  _vm.translations,
                                                  "Offer.ProjectReference"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "data-table__mobile-table__right-col col"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                order.yourReference
                                                  ? order.yourReference
                                                  : "-"
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "data-table__mobile-table__left-col cell col"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDictionaryItem(
                                                  _vm.translations,
                                                  "Offers.OrderNumber"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "data-table__mobile-table__right-col cell col"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                order.orderNumber
                                                  ? order.orderNumber
                                                  : "-"
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "collapse",
                                  attrs: { id: "collapse-" + index }
                                },
                                [
                                  _c("div", { staticClass: "card card-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__container "
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "top-row row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__left-col cell col"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDictionaryItem(
                                                      _vm.translations,
                                                      "Offers.ProjectNumber"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__right-col cell col"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    order.projectNumber
                                                      ? order.projectNumber
                                                      : "-"
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "top-row row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__left-col cell col"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDictionaryItem(
                                                      _vm.translations,
                                                      "Offers.Status"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__right-col cell col"
                                              },
                                              [
                                                _vm.ordersMode
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            order.deliverStateTranslationKey
                                                              ? _vm.getDictionaryItem(
                                                                  _vm.translations,
                                                                  order.deliverStateTranslationKey
                                                                )
                                                              : "-"
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.ordersMode
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            order.orderConfirmationStateKey
                                                              ? _vm.getDictionaryItem(
                                                                  _vm.translations,
                                                                  order.orderConfirmationStateKey
                                                                )
                                                              : "-"
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "top-row row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__left-col cell col"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDictionaryItem(
                                                      _vm.translations,
                                                      "Offers.Track"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__right-col cell col"
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "data-table__table__button--no-style",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.listShipping(
                                                          order
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("svg", {
                                                      staticClass:
                                                        "icon data-table__table__tracking-icon"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "top-row row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__left-col cell col"
                                              },
                                              [
                                                _vm.ordersMode
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.getDictionaryItem(
                                                              _vm.translations,
                                                              "Offers.Order"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.ordersMode
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.getDictionaryItem(
                                                              _vm.translations,
                                                              "Offers.Offer"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-table__mobile-table__right-col cell col"
                                              },
                                              [
                                                _c(
                                                  "DownloadLink",
                                                  {
                                                    attrs: {
                                                      url: order.pdfUrl,
                                                      errorMessage: _vm.getDictionaryItem(
                                                        _vm.translations,
                                                        "Offer.MissingOffer"
                                                      ),
                                                      classes: [
                                                        "data-table_document",
                                                        " data-table__table__button--no-style"
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _c("svg", {
                                                      staticClass:
                                                        "icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width"
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__left-col cell col"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDictionaryItem(
                                                    _vm.translations,
                                                    "Offers.Mark"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__right-col cell col"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(order.mark) +
                                                    "\n                                        "
                                                )
                                              ])
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__left-col cell col"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getDictionaryItem(
                                                    _vm.translations,
                                                    "Offers.ExtraInfo"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "data-table__mobile-table__right-col cell col"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(order.extraInfo) +
                                                    "\n                                        "
                                                )
                                              ])
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm.ordersMode
                                          ? _c(
                                              "div",
                                              { staticClass: "bottom-row row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "data-table__mobile-table__left-col cell col"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getDictionaryItem(
                                                          _vm.translations,
                                                          "Offers.Blueprints"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "data-table__mobile-table__right-col cell col"
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "data-table__table__button--no-style",
                                                        attrs: {
                                                          type: "button",
                                                          title: _vm.getDictionaryItem(
                                                            _vm.translations,
                                                            "Offers.Blueprints"
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.listBlueprints(
                                                              order
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticClass:
                                                            "icon data-table__table__blueprint-icon data-table__table__blueprint-icon--short-width"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("div", { staticClass: "preloader-logo" })
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "transition-group",
                {
                  staticClass: "data-table__table",
                  attrs: { name: "faderow", tag: "table", css: true },
                  on: {
                    "before-enter": _vm.onBeforeEnter,
                    enter: _vm.onEnter,
                    leave: _vm.onLeave
                  }
                },
                [
                  _c(
                    "tr",
                    { key: 0, staticClass: "data-table__table__top-row" },
                    [
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offers.OrderDate"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offers.OrderNumber"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offers.Mark"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offers.ExtraInfo"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offer.ProjectReference"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offer.Type"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offers.Status"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm.ordersMode
                        ? _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.getDictionaryItem(
                                  _vm.translations,
                                  "Offers.Track"
                                )
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", [
                        _vm.ordersMode
                          ? _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.getDictionaryItem(
                                      _vm.translations,
                                      "Offer.Document"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.ordersMode
                          ? _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.getDictionaryItem(
                                      _vm.translations,
                                      "Offers.Offer"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.ordersMode
                        ? _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.getDictionaryItem(
                                  _vm.translations,
                                  "Offers.Blueprints"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.gsOutput(), function(order, orderIndex) {
                    return _vm.page * _vm.orderCount > orderIndex
                      ? _c(
                          "tr",
                          {
                            key: order.orderNumber,
                            staticClass: "data-table__table__non-top-row"
                          },
                          [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  order.orderDate
                                    ? order.orderDate.substring(0, 10)
                                    : "-"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  order.orderNumber ? order.orderNumber : "-"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(order.mark))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(order.extraInfo))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  order.yourReference
                                    ? order.yourReference
                                    : "-"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.getDictionaryItem(
                                    _vm.translations,
                                    order.orderTypeTranslationKey
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm.ordersMode
                                ? _c("span", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          order.deliverStateTranslationKey
                                            ? _vm.getDictionaryItem(
                                                _vm.translations,
                                                order.deliverStateTranslationKey
                                              )
                                            : "-"
                                        ) +
                                        "\n                        "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.ordersMode
                                ? _c("span", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          order.orderConfirmationStateKey
                                            ? _vm.getDictionaryItem(
                                                _vm.translations,
                                                order.orderConfirmationStateKey
                                              )
                                            : "-"
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.ordersMode
                              ? _c("td", [
                                  order.orderType === "1"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "data-table__table__button--no-style",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              return _vm.listShipping(order)
                                            }
                                          }
                                        },
                                        [
                                          _c("svg", {
                                            staticClass:
                                              "icon data-table__table__tracking-icon"
                                          })
                                        ]
                                      )
                                    : order.orderType === "0"
                                    ? _c("div", [
                                        _c("svg", {
                                          staticClass:
                                            "icon data-table__table__tracking-icon data-table__table__tracking-icon_disabled"
                                        })
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "DownloadLink",
                                  {
                                    attrs: {
                                      url: order.pdfUrl,
                                      errorMessage: _vm.getDictionaryItem(
                                        _vm.translations,
                                        "Offer.MissingOffer"
                                      ),
                                      classes: [
                                        "data-table_document",
                                        " data-table__table__button--no-style"
                                      ]
                                    }
                                  },
                                  [
                                    _c("svg", {
                                      staticClass:
                                        "icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.ordersMode
                              ? _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "data-table__table__button--no-style",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.listBlueprints(order)
                                        }
                                      }
                                    },
                                    [
                                      _c("svg", {
                                        staticClass:
                                          "icon data-table__table__blueprint-icon data-table__table__blueprint-icon--short-width"
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.loading
                    ? _c(
                        "tr",
                        {
                          key: 0,
                          staticClass:
                            "data-table__table__non-top-row data-table__table__loading-row"
                        },
                        [
                          _c("td", { attrs: { colspan: "6" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.getDictionaryItem(
                                  _vm.translations,
                                  "Common.Loading"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_c("div", { staticClass: "loader" })])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.orderCount, function(order, orderIndex) {
                    return _vm.loading
                      ? _c(
                          "tr",
                          {
                            key: orderIndex,
                            staticClass:
                              "data-table__table__non-top-row loading"
                          },
                          [_c("td", { attrs: { colspan: "6" } }, [_vm._v(" ")])]
                        )
                      : _vm._e()
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.loading
                ? _c("div", { staticClass: "preloader-logo" })
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading && _vm.page * _vm.orderCount < _vm.orderList.length
                ? _c("div", { staticClass: "data-table__btn-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary tt-orange",
                        on: { click: _vm.nextPage }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.LoadMore"
                            )
                          )
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ordersMode &&
      _vm.deliveryNoteNo != "0" &&
      _vm.viewState === "SHIPPING"
        ? _c("Shipping", {
            attrs: {
              orderid: _vm.deliveryNoteNo,
              translations: _vm.translations
            },
            on: {
              closeShipping: function($event) {
                return _vm.setState("BASELIST")
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.notifyError
        ? _c("notification", {
            attrs: { message: _vm.errorMessage },
            on: {
              closeModal: function($event) {
                _vm.notifyError = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "data-table__mobile-table__left-col col" }),
      _vm._v(" "),
      _c("div", { staticClass: "data-table__mobile-table__right-col col" }, [
        _vm._v("-")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }