var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mp-favorites" },
    [
      !_vm.orderCreation
        ? _c("div", { staticClass: "product-list mypages-favorites" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "transition",
                  { attrs: { name: "editstate" } },
                  [
                    _vm.isEditing
                      ? _c("edit-order", {
                          attrs: {
                            translations: _vm.translations,
                            customer: _vm.customer,
                            currentculture: _vm.currentCulture,
                            favoriteorder: _vm.currentOrder
                          },
                          on: {
                            saveOrder: _vm.upDateOrders,
                            saveCloseOrder: _vm.endEditingUpdate,
                            closeOrder: _vm.endEditing
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "editstate" } }, [
                  !_vm.isEditing && _vm.favoriteOrders
                    ? _c(
                        "div",
                        { staticClass: "mp-order-list table-list" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "table-list_row row table-list_row_heading d-none d-md-flex g-md-0"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-2 table-list_col" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "Common.Created"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-2 table-list_col" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "Common.Name"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "col-8 table-list_col d-flex justify-content-end"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "table-list_row row g-md-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-8 col-md-2 table-list_col"
                                },
                                [
                                  _c("p", { staticClass: "table-list_date" }, [
                                    _vm._v(_vm._s(_vm.newList.createDate))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-2 d-flex table-list_col"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newList.title,
                                        expression: "newList.title"
                                      }
                                    ],
                                    staticClass: "input",
                                    class: {
                                      active: _vm.focusedRow === _vm.order
                                    },
                                    domProps: { value: _vm.newList.title },
                                    on: {
                                      click: function($event) {
                                        return _vm.setFocusRow(_vm.newList)
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.newList,
                                          "title",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "table-list_action-container d-flex d-md-none"
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "d-md-none btn btn-secondary tt-verylight-gray btn-add-dark table-list_handheld-action btn-square",
                                        on: { click: _vm.createList }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-8 table-list_col d-flex justify-content-md-end"
                                },
                                [
                                  _c("a", {
                                    staticClass:
                                      "btn btn-secondary tt-verylight-gray btn-add-dark btn-square table-list_handheld-action d-none d-md-block",
                                    on: { click: _vm.createList }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                name: "faderow",
                                tag: "div",
                                css: false
                              },
                              on: {
                                "before-enter": _vm.onBeforeEnter,
                                enter: _vm.onEnter,
                                leave: _vm.onLeave
                              }
                            },
                            _vm._l(_vm.favoriteOrders, function(
                              order,
                              orderIndex
                            ) {
                              return _vm.page * _vm.orderCount > orderIndex
                                ? _c(
                                    "div",
                                    {
                                      key: order.key,
                                      staticClass: "table-list_row row g-md-0",
                                      attrs: { css: false },
                                      on: {
                                        click: function($event) {
                                          if (
                                            $event.target !==
                                            $event.currentTarget
                                          ) {
                                            return null
                                          }
                                          return _vm.editOrder(order)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-8 col-md-2 table-list_col"
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "table-list_date" },
                                            [_vm._v(_vm._s(order.createDate))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex col-12 col-md-10 table-list_col"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: order.title,
                                                expression: "order.title"
                                              }
                                            ],
                                            staticClass: "input",
                                            class: {
                                              active: _vm.focusedRow === order
                                            },
                                            domProps: { value: order.title },
                                            on: {
                                              blur: _vm.upDateOrders,
                                              click: function($event) {
                                                return _vm.setFocusRow(order)
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.onEnterUpdate.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  order,
                                                  "title",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table-list_action-container d-flex"
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "btn btn-secondary tt-orange btn-trash btn-square table-list_handheld-action",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.confirmDelete(
                                                      order
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("a", {
                                                staticClass:
                                                  "btn btn-secondary tt-verylight-gray btn-order-create btn-square table-list_handheld-action",
                                                class: {
                                                  disabled: !order.orderRows
                                                    .length
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openOrderForm(
                                                      order
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("a", {
                                                staticClass:
                                                  "btn btn-secondary tt-verylight-gray btn-edit-dark btn-square table-list_handheld-action",
                                                class: {
                                                  disabled: !order.orderRows
                                                    .length
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editOrder(order)
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm.page * _vm.orderCount < _vm.favoriteOrders.length
                            ? _c(
                                "div",
                                { staticClass: "row table-list_row_more" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 d-flex justify-content-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "btn btn-primary tt-orange",
                                          on: { click: _vm.nextPage }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.getDictionaryItem(
                                                  _vm.translations,
                                                  "Search.LoadMore"
                                                )
                                              ) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.confirm
        ? _c("modal", {
            attrs: {
              message: _vm.getDictionaryItem(
                _vm.translations,
                "MyPages.Modal.Heading"
              ),
              decline: _vm.getDictionaryItem(
                _vm.translations,
                "MyPages.Modal.No"
              ),
              accept: _vm.getDictionaryItem(
                _vm.translations,
                "MyPages.Modal.Yes"
              )
            },
            on: { closeModal: _vm.confirmedDeleteList }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.disable
        ? _c("div", { staticClass: "mp-favorites_disable" }, [
            _c("div", { staticClass: "preloader-logo" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "editstate" } }, [
        _vm.orderCreation
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("order-form", {
                    attrs: {
                      customerttcenter: _vm.customer.ThermotechCenterId,
                      customer: _vm.customer,
                      currentculture: _vm.currentCulture,
                      translations: _vm.translations,
                      "favorite-order": _vm.favoriteOrder
                    },
                    on: {
                      close: function($event) {
                        return _vm.closeForm()
                      }
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }