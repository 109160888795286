<template>
    <div class="category-list"">
        <div class="row">
            
            <div class="col-sm-12 d-flex position-relative" >
                <div class="toggle-button" @click="listView = !listView">
                    <span v-if="listView">{{togglecardlabel}}</span>
                    <span v-if="!listView">{{togglelistlabel}}</span>
                    <div class="toggler-icon" v-bind:class="{'active': listView}">
                        <span class="l1"></span>
                        <span class="l2"></span>
                        <span class="l3"></span>
                    </div>
                </div>
            </div>
        </div>
        <transition-group name="fade" tag="div" class="category-list-items row" v-bind:class="{'list-view': listView}">
            <div v-bind:class="[!listView ? cardcols : 'col-12']"
                 v-for="(category, catIndex) in categories"
                 :key="category.Key">
                <category-card :category="category" :key="category.Key"
                               :listview="listView" :activefilters="activefilters">
                </category-card>
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import ProductCard from './categorycard.vue';
    export default Vue.component('category-list', {
        created: () => {

        },
        components: {
            ProductCard
        },
        data: function () {
            return {
                listView: false
            }
        },
        props: {
            categories: {
                required: true
            },            
            togglelistlabel: {
                type: String,
                required: true
            },
            togglecardlabel: {
                type: String,
                required: true
            },
            cardcols: {
                type: String,
                default: 'col col-sm-6 col-md-4 col-lg-3'
            },
            activefilters: {
                type: Array,
                required: true
            },
        },
        mounted: function () {

        },
        methods: {

        },
        computed: {

        }
    });
</script>