export default class MainNavigation {
    elm;
    mainNavQuerySelector;
    containerQuerySelector;
    btnQuerySelector;
    panelContainerQuerySelector;
    panelQuerySelector;
    rightPanelContainerQuerySelector;
    rightPanelSelector;
    backDrop;
    megaItemSelector;
    mainHeader;

    constructor(
        elm,
        mainNavQuerySelector,
        containerQuerySelector,
        btnQuerySelector,
        panelContainerQuerySelector,
        panelQuerySelector,
        backDrop,
        megaItemSelector,
        mainHeader
    ) {
        this.elm = elm;
        this.mainNavQuerySelector = mainNavQuerySelector;
        this.containerQuerySelector = containerQuerySelector;
        this.btnQuerySelector = btnQuerySelector;
        this.panelContainerQuerySelector = panelContainerQuerySelector;
        this.panelQuerySelector = panelQuerySelector;
        this.backDrop = backDrop;
        this.megaItemSelector = megaItemSelector;
        this.mainHeader = mainHeader;
    }

    Run() {
        this.btns = document.querySelectorAll(`${this.mainNavQuerySelector} ${this.btnQuerySelector}`);
        this.panels = document.querySelectorAll(`${this.containerQuerySelector} ${this.panelQuerySelector}`);
        this.panel = document.querySelector(`${this.containerQuerySelector} ${this.panelContainerQuerySelector}`);
        this.backDropEl = document.querySelector(`${this.backDrop}`);
        this.mainHeaderEl = document.querySelector(`${this.mainHeader}`);
        this.megaItems = document.querySelectorAll(`${this.megaItemSelector}`);
        this.childBtns = document.querySelectorAll(`${this.mainNavQuerySelector} ${'.sub-nav-btn'}`);
        this.abortClose = false;

        this.panel.addEventListener('mouseover', event => {
            this.abortClose = true;
        });

        for (let i = 0; i < this.btns.length; i++) {
            this.btns[i].addEventListener('mouseover', event => {
                this.abortClose = true;
                this.ToggleMainPanel(i);
            });

            this.btns[i].addEventListener('focus', event => {
                this.abortClose = true;
                this.ToggleMainPanel(i);
            });

            this.btns[i].addEventListener('click', event => {
                this.Navigated();
            });
        }

        var searchComponent = document.querySelector('#search-input');

        //Close nav when search vue component is clicked
        if (searchComponent) {
            searchComponent.addEventListener('click', event => {
                this.HidePanelContainer();
            });
        }

        this.mainHeaderEl.addEventListener('mouseleave', event => {
            this.abortClose = false;
            setTimeout(() => {
                if (this.abortClose === false) {
                    this.HidePanelContainer(this.panel);
                    this.HidePanels(this.megaItems, false);
                }
            }, 500);
        });

        for (let i = 0; i < this.childBtns.length; i++) {
            this.childBtns[i].addEventListener('click', event => {
                this.Navigated();
            });
        }
    }
    ToggleMainPanel(i) {

        this.ShowPanelContainer(this.panel);

        if (this.panels[i].style.display !== 'block') {
            this.HidePanels(this.megaItems);

            this.btns[i].classList.add('active');

            setTimeout(() => {
                this.FadeIn(this.megaItems[i]);
            }, 150);
        }
    }
    HidePanelContainer() {
        this.panel.style.display = 'none';
        this.panel.classList.remove('active');
        this.elm.classList.remove('active');
        this.backDropEl.classList.remove('show');
        this.backDropEl.classList.add('hide');
        setTimeout(() => {
            this.backDropEl.style.display = 'none';
        }, 150);
    }
    ShowPanelContainer() {
        this.FadeIn(this.panel);
        this.FadeIn(this.elm);
        //this.elm.classList.add('active');
        this.backDropEl.style.display = 'block';
        this.backDropEl.classList.remove('hide');
        this.backDropEl.classList.add('show');
    }
    HidePanels(megaItems, animate = true) {
        for (let i = 0; i < megaItems.length; i++) {
            if (animate == true) {
                this.FadeOut(megaItems[i]);
            }
            else {
                megaItems[i].style.display = 'none';

            }
            this.btns[i].classList.remove('active');
        }
    }
    FadeIn(el) {
        el.style.display = 'block';
        setTimeout(() => {
            el.classList.add('active');
        }, 15);
    }
    FadeOut(el) {
        el.classList.remove('active');
        setTimeout(() => {
            el.style.display = 'none';
        }, 150);
    }
    Navigated() {
        document.body.classList.remove('animate-show');
        document.body.classList.add('navigate-leave');
        this.elm.classList.add('fade-out-slow');
    }
}
