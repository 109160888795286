<template>
    <div>
        <div class="row">
            <div class="col-10 d-flex">
                <h3>{{getDictionaryItem(translations, 'Offers.Blueprints')}}</h3>
            </div>
            <div class="col-2 d-flex justify-content-end align-items-center">
                <a class="btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action" @click="closeBlueprints"></a>
            </div>
        </div>
        <div v-if="blueprints.length > 0">
            <transition-group name="faderow"
                              tag="table"
                              class="data-table__table"
                              :css="true"
                              @before-enter="onBeforeEnter"
                              @enter="onEnter"
                              @leave="onLeave">

                <tr class="data-table__table__top-row" key="-1">
                    <th>
                        {{getDictionaryItem(translations, 'Offers.BlueprintsForOrder')}} {{ orderId }}
                    </th>
                </tr>

                <tr class="data-table__table__non-top-row" v-for="(blueprint, index) in blueprints" :key="blueprint" v-if="blueprints.length > 0">
                    <td>
                        <a v-bind:href="blueprint.url" target="_blank">
                            <svg class="icon data-table__table__doc-icon data-table__table__doc-icon--short-width" style="text-decoration: none"></svg>
                            <span>{{blueprint.name}}</span>
                        </a>
                    </td>
                </tr>
            </transition-group>


        </div>
        
        <div v-if="(!blueprints || blueprints.length == 0) && downloaded">
            <b>{{getDictionaryItem(translations, 'Offers.NoBlueprints')}} {{ orderId }}</b>
        </div>
        <div v-if="error" class="error-text">
            {{getDictionaryItem(translations, 'Offers.BlueprintsDownloadError')}}
        </div>
    </div>   
</template>

<script lang="ts">
    import Vue from "vue";
    import gsap from "gsap";
    
    export default Vue.component('list-blueprints', {
        created: () => { },

        data: function () {
            return {
                blueprints: [],
                error: false,
            }
        },
        props: {
            orderId: {
                Required: true,
                type: Number,
            },
            translations: {
                required: false,
                type: Object
            }
        },
        mounted: function () {
            this.fetchBlueprints();
        },

        methods: {
            fetchBlueprints() {
                this.$http.get(`/api/mypages/download/listfiles/${this.orderId}` ).then(
                    (response) => {
                        this.blueprints = response.data;
                        this.downloaded = true;
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    },
                    (error) => {
                        this.error = true;
                    }
                )
            },
            closeBlueprints() {
                this.$emit('closeBlueprints');
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    duration: 0,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    alpha: 0,
                    delay: 0
                })
            },
            onEnter(el, done) {
                this.currentIndex++;
                this.currentIndex = this.currentIndex >= (this.invoiceCount + 1) ? 0 : this.currentIndex;
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    x: 0,
                    onComplete: done,
                    delay: this.currentIndex * 0.05
                })
            },
        },
        watch: {
        },
        computed: {}
    });
</script>
