<template>
    <div class="faq-cat">
        <h2>{{category}}</h2>
        <div class="collapse-component" v-for="(faqItem, index) in faqs" v-if="index < itemCount">
            <div class="collapse-item">
                <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-' + faqItem.Key" aria-expanded="false" :aria-controls="'#collapse-' + faqItem.Key">
                    {{faqItem.Question}}
                </button>
                <div class="collapse" :id="'collapse-' + faqItem.Key">
                    <div class="card card-body">
                        <p v-html="faqItem.Answer"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-row" v-if="itemCount < faqs.length">
            <button @click="nextPage" class="btn btn-primary">{{getDictionaryItem(translations, 'FAQ.AllQuestions')}}</button>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('faq-cat', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                nrItems: 3,
                itemCount: 3,
            }
        },
        props: {
            faqs: {
                type: Array,
                required: true
            },
            category: {
                type: String,
                required: true
            },
            translations: {
                required: true
            }
        },
        mounted: function () {
            console.log(this.translations);
        },
        methods: {
            nextPage: function () {
                this.itemCount = this.itemCount + this.nrItems;
            },
        },
        computed: {}
    });
</script>
