<template>
    <div>
        <div class="filters" v-if="filters">
                        
            <div class="select-filters">
                <div class="container">
                <select-filter v-for="(filter, index) in filters"
                               :parent-element="parentElement"
                               :key="filter.Property" :property="filter.Property"
                               :caption="filter.Name"
                               :options="getOptions(documenations)"
                               :checked-filters.sync="checkedFilters[index]"></select-filter>
                </div>

            </div>
            <div class="selected-filters">
                <div class="container">
                    <p class="tag">{{tagText}}</p>
                    <div>
                        <transition-group name="tt-list-fade" tag="p">
                            <template v-for="checkedFilter in checkedFilters">
                                <button v-for="option in checkedFilter.Options"
                                        class="btn btn-primary tt-white btn-filter"
                                        :key="option"
                                        @click="unselect(checkedFilter.Options,option)">
                                    {{option}}
                                </button>
                            </template>

                        </transition-group>
                        <p>
                            <button v-if="filtering" class="btn btn-primary tt-verylight-gray btn-filter f-flex d-lg-none "
                                    @click="clearFilters">
                                {{clearAllText}}
                            </button>
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="files container">
            <transition-group name="tt-list-fade" tag="div" class="documenation-listing-module">
                <p v-for="documentation in filteredDocumenation"
                   :key="documentation.Key"
                   class="documentation file">
                    <a v-bind:href="documentation.Url" target="_blank">
                        {{documentation.Name}}
                        
                    </a>
                    <span class="tags" >
                        <span class="tag" v-if="documentation.Tags && documentation.Tags.length" v-for="tagItem in documentation.Tags">{{tagItem}}</span>
                    </span>
                    <a class="download-link" v-bind:href="documentation.Url" :download="documentation.FileName">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 8H15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 1L15 8L8 15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                </p>
            </transition-group>
        </div>

        <div class="show-all d-flex flex-row justify-content-center" v-if="showButton">
            <button class="btn btn-primary tt-orange" @click="showAll=true">{{showAllDocumentstionsText}}</button>
        </div>

    </div>
</template>
<script lang="ts">
    import _ from "lodash";
    import Vue from "vue";
    import SelectFilter from "../filters/select-filter.vue";

    const take = 12;

    export default Vue.component("documentation-listing", {
        created: function () {
            this.checkedFilters.push({ Property: "Tags", Options: [] });
        },
        components: {
            SelectFilter,
        },

        data: () => {
            return { 
                checkedFilters: [],
                showAll: false,
            };
        },

        props: ["documenations", "filters", "showAllDocumentstionsText", "parentElement", "tagText", "clearAllText"],

        computed: {
            showButton: function () {
                return !this.showAll && this.allFilteredDocumenation.length > take;
            },
            filtering: function () {
                return _.some(this.checkedFilters, (cf) => { return cf.Options.length >= 1; });
            },
            filteredDocumenation() {
                let result = this.allFilteredDocumenation;

                if (!this.showAll) {
                    result = _.take(result, take);
                }
                return result;
            },
            allFilteredDocumenation() {
                const applyFilters = () => {
                    var filteredDocumenation = [...this.documenations];

                    this.checkedFilters.forEach((filter) => {
                        filteredDocumenation = filteredDocumenation.filter((f) => {
                            return _.intersection(filter.Options, f[filter.Property]).length >= 1;
                        });
                    });

                    return filteredDocumenation;
                };
                const filtersApplied = () => {
                    return _.some(this.checkedFilters, (c) => {
                        return c.Options.length > 0;
                    });
                };

                let result = null;

                if (filtersApplied()) {
                    result =  applyFilters();
                }
                else {
                    if(!this.documenations) return [];

                    result = [...this.documenations];
                }
              
                return result;
            },
        },
        methods: {
            clearFilters() {
                _.each(this.checkedFilters, (cf) => {
                    cf.Options = [];
                });
                this.checkedFilters = [...this.checkedFilters];
            },
            unselect(options, option) {
                _.remove(options, (o) => { return o === option; });
                this.checkedFilters = [...this.checkedFilters];
            },
            getOptions(items) {
                let all = _.map(items, i => i.Tags);
                let unique = Array.from(new Set(_.flatten(all)));
                return unique;
            },
        },
    });
</script>
<style>
</style>
