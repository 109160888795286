var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-searchinput" }, [
    _c(
      "form",
      {
        staticClass: "search-wrapper",
        attrs: { method: "get", action: _vm.productsearchpage }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchString,
              expression: "searchString"
            }
          ],
          attrs: {
            id: "mainsearchinput",
            autocomplete: "off",
            placeholder: _vm.searchplaceholder,
            tabindex: "1",
            name: "query"
          },
          domProps: { value: _vm.searchString },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchString = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-primary tt-orange" }, [
          _vm._v(_vm._s(_vm.searchbuttonlabel))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }