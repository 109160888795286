var render, staticRenderFns
import script from "./currencyformat.vue?vue&type=script&lang=ts&"
export * from "./currencyformat.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Thermotech\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ad751f0c')) {
      api.createRecord('ad751f0c', component.options)
    } else {
      api.reload('ad751f0c', component.options)
    }
    
  }
}
component.options.__file = "Dev/js/components/mixins/currencyformat.vue"
export default component.exports