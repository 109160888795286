<template>
    <div>
        <div key="1"
             class="collapse-item data-table_table-container data-table_table-container--margin-top" v-if="listBlueprintsOrderId != null && viewState === 'BLUEPRINTS'">
            <list-blueprints :translations="translations" @closeBlueprints="hideBlueprints" :orderId="listBlueprintsOrderId"></list-blueprints>
        </div>
        <div class="row" v-if="viewState === 'BASELIST'">
            <div class="col-sm-12">
                <simple-filter ref="simpleFilter" 
                               @filtersChanged="onFilter" 
                               v-bind:filteritems="filters" 
                               toplabel="Filter" 
                               :selectedlabel="getDictionaryItem(translations, 'Common.SelectedFilters')" 
                               :clearlabel="getDictionaryItem(translations, 'MyPages.ClearAll')" />
            </div>
            <div class="col-sm-6 data-table_searchdata">
                <input type="text" v-model="searchInput" v-bind:class="{ active: inputActive }" placeholder="Sök" aria-label="Search criteria" aria-describedby="basic-addon2" @click="inputActive = true">
                <button tabindex="1" class="data-table_btn-clear" v-if="searchInput != ''" @click="resetSearch">
                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 1.46094L8.29422 7.5392" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path> <path d="M2.10742 7.64648L8.18568 1.35226" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path></svg>
                </button>
            </div>
        </div>
        <div class="data-table_table-container" v-if="viewState === 'BASELIST'">
            <div class="data-table__mobile-table collapse-component">
                <div class="collapse-item" v-for="(row,index) in orderCount" v-if="loading">
                    <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true">
                        <div class="data-table__mobile-table__container container">
                            <div class="top-row row">
                                <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Common.Loading')}}</div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                            <div class="row">
                                <div class="data-table__mobile-table__left-col col"> </div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                        </div>
                    </button>
                </div>
                <transition-group name="faderow"
                                  tag="div"
                                  :css="true"
                                  @before-enter="onBeforeEnter"
                                  @enter="onEnter"
                                  @leave="onLeave">
                    <div v-for="(order, index) in gsOutput()"
                         class="collapse-item"
                         :key="order.orderNumber"
                         v-if="page*orderCount > index">
                        <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#collapse-' + index" aria-expanded="true"
                                :aria-controls="'collapse-' + index">
                            <div class="data-table__mobile-table__container container">
                                <div class="top-row row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Offers.OrderDate')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{order.orderDate? order.orderDate.substring(0,10) : "-"}}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Offer.ProjectReference')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{ order.yourReference? order.yourReference : "-"  }}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.OrderNumber')}}</div>
                                    <div class="data-table__mobile-table__right-col cell col">{{ order.orderNumber? order.orderNumber : "-"  }}</div>
                                </div>
                            </div>
                        </button>

                        <div class="collapse" :id="'collapse-' + index" style="">
                            <div class="card card-body">
                                <div class="data-table__mobile-table__container ">
                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.ProjectNumber')}}</div>
                                        <div class="data-table__mobile-table__right-col cell col">{{ order.projectNumber? order.projectNumber : "-"  }}</div>
                                    </div>
                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.Status')}}</div>
                                        <div class="data-table__mobile-table__right-col cell col">
                                            <span v-if="ordersMode">
                                                {{ order.deliverStateTranslationKey? getDictionaryItem(translations, order.deliverStateTranslationKey) : "-"  }}
                                            </span>
                                            <span v-if="!ordersMode">
                                                {{ order.orderConfirmationStateKey? getDictionaryItem(translations, order.orderConfirmationStateKey) : "-"  }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.Track')}}</div>
                                        <div class="data-table__mobile-table__right-col cell col">
                                            <button type="button" @click="listShipping(order)" class="data-table__table__button--no-style">
                                                <svg class="icon data-table__table__tracking-icon" />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">
                                            <span v-if="ordersMode">
                                                {{getDictionaryItem(translations, 'Offers.Order')}}
                                            </span>
                                            <span v-if="!ordersMode">
                                                {{getDictionaryItem(translations, 'Offers.Offer')}}
                                            </span>
                                        </div>

                                        <div class="data-table__mobile-table__right-col cell col">
                                            <DownloadLink :url="order.pdfUrl" :errorMessage="getDictionaryItem(translations, 'Offer.MissingOffer')" :classes="['data-table_document', ' data-table__table__button--no-style']">
                                                <svg class="icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width" />
                                            </DownloadLink>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.Mark')}}</div>
                                        <div class="data-table__mobile-table__right-col cell col">
                                            <span>
                                                {{ order.mark}}
                                            </span>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.ExtraInfo')}}</div>
                                        <div class="data-table__mobile-table__right-col cell col">
                                            <span>
                                                {{ order.extraInfo}}
                                            </span>

                                        </div>
                                    </div>
                                    <div v-if="ordersMode" class="bottom-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.Blueprints')}}</div>

                                        <div class="data-table__mobile-table__right-col cell col">
                                            <button type="button" @click="listBlueprints(order)" class="data-table__table__button--no-style" :title="getDictionaryItem(translations, 'Offers.Blueprints')">
                                                <svg class="icon data-table__table__blueprint-icon data-table__table__blueprint-icon--short-width" />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <div class="preloader-logo" v-if="loading"></div>
            </div>

            <transition-group name="faderow"
                              tag="table"
                              class="data-table__table"
                              :css="true"
                              @before-enter="onBeforeEnter"
                              @enter="onEnter"
                              @leave="onLeave">
                <tr class="data-table__table__top-row" :key="0">
                    <th>{{getDictionaryItem(translations, 'Offers.OrderDate')}}</th>
                    <th>{{getDictionaryItem(translations, 'Offers.OrderNumber')}}</th>
                    <th>{{getDictionaryItem(translations, 'Offers.Mark')}}</th>
                    <th>{{getDictionaryItem(translations, 'Offers.ExtraInfo')}}</th>
                    <th>{{getDictionaryItem(translations, 'Offer.ProjectReference')}}</th>
                    <th>{{getDictionaryItem(translations, 'Offer.Type')}}</th>
                    <th>{{getDictionaryItem(translations, 'Offers.Status')}}</th>
                    <th v-if="ordersMode">{{getDictionaryItem(translations, 'Offers.Track')}}</th>
                    <th>
                        <span v-if="ordersMode">
                            {{getDictionaryItem(translations, 'Offer.Document')}}
                        </span>
                        <span v-if="!ordersMode">
                            {{getDictionaryItem(translations, 'Offers.Offer')}}
                        </span>
                    </th>
                    <th v-if="ordersMode">{{getDictionaryItem(translations, 'Offers.Blueprints')}}</th>
                    <!--<th class="data-table__table__rightmost-cell">{{translations.projectName}}</th>-->
                </tr>
                <tr v-for="(order, orderIndex)  in gsOutput()"
                    class="data-table__table__non-top-row"
                    :key="order.orderNumber"
                    v-if="page*orderCount > orderIndex">
                    <td>{{ order.orderDate? order.orderDate.substring(0,10) : "-" }}</td>
                    <td>{{ order.orderNumber? order.orderNumber : "-"  }}</td>
                    <td>{{order.mark}}</td>
                    <td>{{order.extraInfo}}</td>
                    <td>{{ order.yourReference? order.yourReference : "-"  }}</td>
                    <td>{{getDictionaryItem(translations, order.orderTypeTranslationKey)}}</td>
                    <td>
                        <span v-if="ordersMode">
                            {{ order.deliverStateTranslationKey? getDictionaryItem(translations, order.deliverStateTranslationKey) : "-"  }}
                            <!--{{ order.orderConfirmationStateKey? getDictionaryItem(translations, order.orderConfirmationStateKey) : "-"  }}-->
                        </span>
                        <span v-if="!ordersMode">
                            {{ order.orderConfirmationStateKey? getDictionaryItem(translations, order.orderConfirmationStateKey) : "-"  }}
                        </span>
                    </td>
                    <td v-if="ordersMode">
                        <button v-if="order.orderType === '1'" type="button" @click="listShipping(order)" class="data-table__table__button--no-style">
                            <svg class="icon data-table__table__tracking-icon" />
                        </button>
                        <div v-else-if="order.orderType === '0'">
                            <svg class="icon data-table__table__tracking-icon data-table__table__tracking-icon_disabled" />
                        </div>
                    </td>
                    <td>
                        <DownloadLink :url="order.pdfUrl" :errorMessage="getDictionaryItem(translations, 'Offer.MissingOffer')" :classes="['data-table_document', ' data-table__table__button--no-style']">
                            <svg class="icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width" />
                        </DownloadLink>
                    </td>
                    <td v-if="ordersMode">

                        <button type="button" @click="listBlueprints(order)" class="data-table__table__button--no-style">
                            <svg class="icon data-table__table__blueprint-icon data-table__table__blueprint-icon--short-width" />
                        </button>
                    </td>
                    <!--<td class="data-table__table__rightmost-cell">{{ order.projectName? order.projectName : "-"  }}</td>-->
                </tr>
                <tr class="data-table__table__non-top-row data-table__table__loading-row" v-if="loading" :key="0">
                    <td colspan="6">{{getDictionaryItem(translations, 'Common.Loading')}}</td>
                    <td><div class="loader"></div></td>
                </tr>
                <tr v-for="(order, orderIndex)  in orderCount"
                    v-if="loading"
                    class="data-table__table__non-top-row loading"
                    :key="orderIndex">
                    <td colspan="6">&nbsp;</td>
                </tr>
            </transition-group>
            <div class="preloader-logo" v-if="loading"></div>
            <div class="data-table__btn-wrapper" v-if="!loading && page*orderCount < orderList.length">
                <button class="btn btn-primary tt-orange" @click="nextPage">{{getDictionaryItem(translations, 'MyPages.LoadMore')}}</button>
            </div>
        </div>

        <Shipping @closeShipping="setState('BASELIST')"
                  :orderid="deliveryNoteNo"
                  v-if="ordersMode && deliveryNoteNo != '0' && viewState === 'SHIPPING'"
                  :translations="translations"></Shipping>
        
        <notification v-if="notifyError"
                      @closeModal="notifyError = false"
                      :message="errorMessage">
        </notification>

    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import gsap from "gsap";
    import ListBlueprints from './_listblueprints.vue';
    import Shipping from './_shipping.vue';
    import DownloadLink from '../utilities/download-link.vue';
    import genericSortMixin, { EQUALS, FilterType, Sorting } from '../mixins/genericsort.vue';
    import SimpleFilter from '../filters/simple-filter.vue';
    import Notification from '../utilities/notification.vue';

    export default Vue.component('mypages-orders', {
        mixins: [genericSortMixin],
        components: {
            ListBlueprints,
            Shipping,
            DownloadLink,
            SimpleFilter,
            Notification
        },
        data: function () {
            return {
                loadMoreCount: 0,
                orderList: [],
                page: 1,
                orderCount: 8,
                currentIndex: 0,
                loading: true,
                headingsArray: [],
                deliveryNoteNo: '0',
                dataOb: [],
                viewState: 'BASELIST',
                listBlueprintsOrderId: null,
                filters: [],
                searchInput: '',
                inputActive: false,
                notifyError: false,
                errorMessage: ''
            }
        },
        mounted: function () {

            var offerFilter = FilterType();
            offerFilter.name = "Offerter";
            offerFilter.category = "Offerter";
            offerFilter.value = 0;
            offerFilter.key = "orderType";
            offerFilter.active = false;
            offerFilter.operator = EQUALS;

            var orderFilter = FilterType();
            orderFilter.name = "Ordrar";
            orderFilter.category = "Ordrar";
            orderFilter.value = 1;
            orderFilter.key = "orderType";
            orderFilter.active = false;
            orderFilter.operator = EQUALS;

            var deliveredFilter = FilterType();
            deliveredFilter.name = "Slutlevererad";
            deliveredFilter.category = "Slutlevererad";
            deliveredFilter.value = 5;
            deliveredFilter.key = "deliverState";
            deliveredFilter.active = false;
            deliveredFilter.operator = EQUALS;

            var partiallyDeliveredFilter = FilterType();
            partiallyDeliveredFilter.name = "Dellevererad";
            partiallyDeliveredFilter.category = "Dellevererad";
            partiallyDeliveredFilter.value = 4;
            partiallyDeliveredFilter.key = "deliverState";
            partiallyDeliveredFilter.active = false;
            partiallyDeliveredFilter.operator = EQUALS;

            this.filters.push(offerFilter, orderFilter, deliveredFilter, partiallyDeliveredFilter);

            this.fetchOrders();
        },
        props: {
            translations: {
                type: Object
            },
            endpoint: {
                type: String,
                default: 'getallorders'
            },
            mode: {
                type: String
            }
        },
        computed: {
            ordersMode() {
                return this.mode == "orders";
            }
        },
        watch: {
            searchInput: function (val) {
                this.currentIndex = 0;
                this.gsSearch(val);
            },
        },
        methods: {
            onFilter(selectedFilters) {
                const vm = this;

                this.currentIndex = 0;
                this.gsResetFilters();

                if (selectedFilters.length) {
                    selectedFilters.forEach(function (filter, index) {
                        vm.gsToggleFilter(filter);
                    });
                }
            },
            resetSearch() {
                this.searchInput = "";
                this.inputActive = false;
            },
            fetchOrders() {
                //offers/list
                this.$http.get('/api/mypages/' + this.endpoint).then((response) => {
                    this.orderList = response.data;
                    this.loading = false;

                    var filter = FilterType();
                    filter.name = "Text som användaren kan se";
                    filter.value = 0;
                    filter.key = "orderType";
                    filter.operator = EQUALS;

                    this.gsCollection = response.data;

                }, (response) => {
                    this.errorMessage = this.getDictionaryItem(this.translations, 'MyPages.HttpFailure');
                    this.notifyError = true;
                    this.loading = false;
                    this.orderList = [];
                    console.error(response);
                });
            },
            hideBlueprints() {
                this.setState('BASELIST');
                this.listBlueprintsOrderId = null;
            },
            setState(state) {
                this.viewState = state;
            },
            nextPage() {
                this.currentIndex = 0;
                this.page++;
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    duration: 0.2,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    x: -10,
                    alpha: 0,
                    delay: 0
                })
            },
            onEnter(el, done) {

                this.currentIndex++;
                this.currentIndex = this.currentIndex >= (this.invoiceCount + 1) ? 0 : this.currentIndex;
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    x: 0,
                    onComplete: done,
                    delay: this.currentIndex * 0.05
                })
            },
            listBlueprints(offer) {
                this.viewState = 'BLUEPRINTS'
                this.listBlueprintsOrderId = offer.orderNumber;
                this.scrollTop();
            },
            listShipping(order) {
                this.viewState = 'SHIPPING'
                this.deliveryNoteNo = order.orderNumber;
                this.scrollTop();
            },
            scrollTop() {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
    });
</script>
