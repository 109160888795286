export class EventHelper {
    constructor(namespace, node = document) {
        this.el = node ? node : document;
        this.namespace = namespace;
    }

    throw(type, detail = {}) {
        const event = (detail) ? new CustomEvent(`${this.namespace}-${type}`, { detail: detail }) : new Event(`${this.namespace}-${type}`);
        this.el.dispatchEvent(event);
    }

    on(type, ev) {
        this.el.addEventListener(`${this.namespace}-${type}`, ev);
    }

    off(type, ev) {
        this.el.removeEventListener(`${this.namespace}-${type}`, ev);
    }
}