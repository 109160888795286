var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "product-tree d-none d-md-block" }, [
    _c(
      "li",
      { staticClass: "tree-category" },
      [
        _c("a", { class: _vm.style, attrs: { href: _vm.url } }, [
          _vm._v(_vm._s(_vm.category.Name))
        ]),
        _vm._v(" "),
        _vm._l(_vm.category.Children, function(category, index) {
          return _c("tree", {
            attrs: { category: category, activefilters: _vm.filters }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }