var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mp-favorites" }, [
    _c(
      "div",
      { staticClass: "product-list mypages-favorites" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12 d-flex position-relative" }, [
            _c(
              "div",
              {
                staticClass: "toggle-button",
                on: {
                  click: function($event) {
                    _vm.listView = !_vm.listView
                  }
                }
              },
              [
                _vm.listView
                  ? _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Products.ShowAsCards"
                            )
                          ) +
                          "\n                    "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.listView
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getDictionaryItem(
                            _vm.translations,
                            "Products.ShowAsList"
                          )
                        )
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "toggler-icon",
                    class: { active: _vm.listView }
                  },
                  [
                    _c("span", { staticClass: "l1" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "l2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "l3" })
                  ]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "transition-group",
          {
            staticClass: "product-list-items row",
            class: { "list-view": _vm.listView },
            attrs: { name: "fade", tag: "div" }
          },
          _vm._l(_vm.favoriteItems, function(product, productIndex) {
            return _c(
              "div",
              {
                key: product.Key,
                class: [!_vm.listView ? _vm.cardcols : "col-12"]
              },
              [
                product.IsFavorite && _vm.page * _vm.productCount > productIndex
                  ? _c("product-card", {
                      key: product.Key,
                      attrs: {
                        product: product,
                        customer: _vm.customer,
                        listview: _vm.listView,
                        translations: _vm.translations,
                        articlnrlabel: _vm.getDictionaryItem(
                          _vm.translations,
                          "Products.ArticleNumber"
                        ),
                        rsklabel: _vm.getDictionaryItem(
                          _vm.translations,
                          "Products.RSKNumber"
                        ),
                        calltoaction: _vm.getDictionaryItem(
                          _vm.translations,
                          "Products.ToProduct"
                        )
                      },
                      on: {
                        favoriteChanged: _vm.upDateFavorites,
                        favoriteOrdersClicked: _vm.opentFavoritesList
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.editFavorites
          ? _c("favorites-popover", {
              attrs: {
                customer: _vm.customerItem,
                typedtranslations: _vm.translations,
                favoriteproduct: _vm.currentFavoriteProduct
              },
              on: { closeFavorites: _vm.closeAndSaveFavoritesList }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.page * _vm.productCount < _vm.favoriteItems.length
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-primary tt-orange",
                      on: { click: _vm.nextPage }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Search.LoadMore"
                            )
                          ) +
                          "\n                "
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }