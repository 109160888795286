import { render, staticRenderFns } from "./_shipping.vue?vue&type=template&id=4895eaeb&"
import script from "./_shipping.vue?vue&type=script&lang=ts&"
export * from "./_shipping.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Thermotech\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4895eaeb')) {
      api.createRecord('4895eaeb', component.options)
    } else {
      api.reload('4895eaeb', component.options)
    }
    module.hot.accept("./_shipping.vue?vue&type=template&id=4895eaeb&", function () {
      api.rerender('4895eaeb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Dev/js/components/mypages/_shipping.vue"
export default component.exports