var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "search-item",
      class: _vm.searchitem.type,
      style: { transitionDelay: _vm.index * 0.03 + "s" },
      attrs: { href: _vm.searchitem.url, target: "_self" }
    },
    [
      _c(
        "div",
        { staticClass: "image ratio ratio-1x1" },
        [
          !_vm.searchitem.image
            ? [
                _vm.searchitem.type == "umbracoMediaArticle"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "26",
                            height: "32",
                            viewBox: "0 0 26 32",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M15.5889 1H3.58887C2.79322 1 2.03016 1.31607 1.46755 1.87868C0.904937 2.44129 0.588867 3.20435 0.588867 4V28C0.588867 28.7956 0.904937 29.5587 1.46755 30.1213C2.03016 30.6839 2.79322 31 3.58887 31H21.5889C22.3845 31 23.1476 30.6839 23.7102 30.1213C24.2728 29.5587 24.5889 28.7956 24.5889 28V10L15.5889 1Z",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M15.5879 1V10H24.5879",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M18.5889 17.502H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M18.5889 23.5H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M9.58887 11.498H8.08887H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "documentation"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "416",
                            height: "512",
                            viewBox: "0 0 416 512",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M15.5889 1H3.58887C2.79322 1 2.03016 1.31607 1.46755 1.87868C0.904937 2.44129 0.588867 3.20435 0.588867 4V28C0.588867 28.7956 0.904937 29.5587 1.46755 30.1213C2.03016 30.6839 2.79322 31 3.58887 31H21.5889C22.3845 31 23.1476 30.6839 23.7102 30.1213C24.2728 29.5587 24.5889 28.7956 24.5889 28V10L15.5889 1Z",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M15.5879 1V10H24.5879",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M18.5889 17.502H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M18.5889 23.5H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M9.58887 11.498H8.08887H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "startPage" ||
                _vm.searchitem.type == "page" ||
                _vm.searchitem.type == "modulePage"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M87.887 74.772v361.521h336.958V74.772H87.887zm306.318 330.879H118.544V105.399h275.661v300.252zM262.693 137.497H149.04v130.742h113.653V137.497zm104.942.521h-79.221v24.951h79.221v-24.951zm0 107.328h-79.221v24.92h79.221v-24.92zm0-53.661h-79.221v24.948h79.221v-24.948zm-1.816 107.327H147.57v24.917h218.249v-24.917zm0 53.662H147.57v24.887h218.249v-24.887z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "employee" ||
                _vm.searchitem.type == "employeeItem"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M455.184 87.44H56.816c-11.04 0-20 8.96-20 20v297.12c0 11.04 8.96 20 20 20h398.368c11.04 0 20-8.96 20-20V107.44c0-11.056-8.944-20-20-20zm-20 297.12H76.816V127.44h54.128v38.992h64V127.44h122.128v38.992h64V127.44H435.2v257.12zm-282.88-117.408c7.584 13.952 17.2 16.864 23.92 16.864 6.704 0 16.32-2.912 23.968-16.816l.464-.848c3.792-6.88 6.528-11.856 6.528-23.104 0-9.952 0-36.384-30.944-36.384-30.784 0-30.784 25.504-30.784 36.384 0 11.28 2.704 16.256 6.464 23.152l.384.752zm82.592 34.464l-30.768-13.776c-1.312-.592-2.784-.624-4.096-.048l-15.632 6.576h-16.224l-15.632-6.528a5.059 5.059 0 00-4.064.048l-30.8 13.744a5.14 5.14 0 00-3.04 4.688v18.768a5.142 5.142 0 005.136 5.136h113.008a5.142 5.142 0 005.136-5.136v-18.8a5.086 5.086 0 00-3.024-4.672zm48.848-85.648h113.632v31.456H283.744zm0 72.512h113.632v31.456H283.744z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "product"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M161.08 106.29l-70.073 40.452 165.498 95.545 70.076-40.453L161.08 106.29zm259.851 41.077L255.435 51.815l-63.578 36.709 165.499 95.542 63.575-36.699zm-150.11 122.19V459.43l164.966-95.238V174.32l-164.966 95.237zM75.082 364.191l164.959 95.234V268.32L75.082 173.09v191.101z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "faqItem" || _vm.searchitem.type == "faq"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M230.384 224.6c4.293-11.035-1.231-16.553-6.143-16.553-22.688 0-52.154 53.374-63.173 53.374-4.311 0-7.99-4.307-7.99-7.985 0-11.043 26.991-36.805 34.982-44.783 24.528-23.312 56.44-41.104 92.027-41.104 26.368 0 54.601 15.945 32.515 75.471L268.42 362.638c-3.665 9.205-10.415 24.557-10.415 34.367 0 4.29 2.438 8.595 7.348 8.595 18.396 0 52.155-52.158 60.748-52.158 3.061 0 7.351 3.675 7.351 9.196 0 17.793-71.771 93.876-133.744 93.876-22.088 0-37.423-10.421-37.423-33.738 0-29.441 20.854-79.757 25.169-90.194l42.93-107.982zm33.125-120.861c0-26.992 23.309-49.073 50.308-49.073 24.556 0 42.336 16.554 42.336 41.716 0 28.233-23.303 49.094-50.914 49.094-25.151 0-41.73-16.576-41.73-41.737z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "helpCenter"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M255.998 49.497c-117.223.014-212.225 93.63-212.236 209.151v73.13h33.453v-73.13c.012-48.708 19.986-92.648 52.367-124.58 32.404-31.911 76.992-51.598 126.416-51.604 49.428.005 94.018 19.692 126.42 51.604 32.379 31.931 52.355 75.872 52.365 124.58v73.13h33.453v-73.13c-.009-115.521-95.013-209.137-212.238-209.151zM388.91 225.988h-43.994c-6.758 0-12.234 5.399-12.234 12.054v132.64c0 6.661 5.477 12.06 12.234 12.06h43.994c6.756 0 12.232-5.399 12.232-12.06v-132.64c.001-6.655-5.476-12.054-12.232-12.054zM167.088 382.746c6.756 0 12.232-5.399 12.232-12.059v-132.64c0-6.655-5.477-12.054-12.232-12.054h-43.994c-6.758 0-12.234 5.398-12.234 12.054v132.639c0 6.66 5.477 12.059 12.234 12.059h43.994zm105.91 10.994h-39.012c-8.496 0-15.383 6.789-15.383 15.161v20.636H77.217v-39.025H43.762v71.992h229.236c8.494 0 15.381-6.788 15.381-15.16v-38.442c0-8.373-6.887-15.162-15.381-15.162z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "job" ||
                _vm.searchitem.type == "jobListing"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M134.506 84.553v342.895h242.988V84.553H134.506zm214.572 314.48H162.922V112.967h186.156v286.066zm-52.244-274.226H174.76v262.369l122.074-70.186V124.807zm-21.449 83.646l-19.459 11.191V192.33l19.459-11.189v27.312z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "casePage"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M339.22 135.024c0-24.836-20.111-44.945-44.962-44.962h-84.142c-24.824.017-44.965 20.126-44.965 44.962v15.907H129.57v271.493h256.937V150.932H339.22v-15.908zm-30.641 15.908H195.824v-15.907c0-7.9 6.377-14.304 14.292-14.304h84.142c7.912 0 14.321 6.403 14.321 14.304v15.907zm-271 24.172v223.154c0 13.341 10.824 24.166 24.168 24.166h42.872V150.932H61.747c-13.344 0-24.168 10.811-24.168 24.172zm411.944-24.172h-38.098v271.493h38.098c13.343 0 24.167-10.825 24.167-24.166V175.104c0-13.361-10.824-24.172-24.167-24.172z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "officePage"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 512 512"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M255.221 60.807c-64.034 0-115.941 51.908-115.941 115.945 0 64.029 115.941 273.752 115.941 273.752s115.943-209.723 115.943-273.752c0-64.037-51.914-115.945-115.943-115.945zm0 151.172c-19.455 0-35.229-15.773-35.229-35.227 0-19.457 15.773-35.23 35.229-35.23s35.228 15.773 35.228 35.23c0 19.453-15.772 35.227-35.228 35.227z"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchitem.type == "pdfMediaType"
                  ? _c("div", { staticClass: "icon" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "26",
                            height: "32",
                            viewBox: "0 0 26 32",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M15.5889 1H3.58887C2.79322 1 2.03016 1.31607 1.46755 1.87868C0.904937 2.44129 0.588867 3.20435 0.588867 4V28C0.588867 28.7956 0.904937 29.5587 1.46755 30.1213C2.03016 30.6839 2.79322 31 3.58887 31H21.5889C22.3845 31 23.1476 30.6839 23.7102 30.1213C24.2728 29.5587 24.5889 28.7956 24.5889 28V10L15.5889 1Z",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M15.5879 1V10H24.5879",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M18.5889 17.502H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M18.5889 23.5H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M9.58887 11.498H8.08887H6.58887",
                              stroke: "white",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.searchitem.type != "documentation" && _vm.searchitem.image != ""
            ? _c("img", { attrs: { src: _vm.searchitem.image, alt: "" } })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("h3", [_vm._v(_vm._s(_vm.searchitem.heading))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.searchitem.text))]),
        _vm._v(" "),
        _c("span", { staticClass: "content-type" }, [
          _vm._v(_vm._s(_vm.searchitem.category))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }