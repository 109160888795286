var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-card", class: { "list-view": _vm.listview } },
    [
      _vm.product.ImageUrl
        ? _c(
            "div",
            { staticClass: "img-container" },
            [
              _c("img", {
                attrs: { src: _vm.product.ImageUrl, alt: _vm.product.Alt }
              }),
              _vm._v(" "),
              _vm.customer
                ? _c("favorite-button", {
                    attrs: { customer: _vm.customer, product: _vm.product }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "product-content" }, [
        _c("h3", [_vm._v(_vm._s(_vm.product.Title))]),
        _vm._v(" "),
        _c("div", { staticClass: "texts" }, [
          _c("p", {
            staticClass: "intro",
            domProps: { innerHTML: _vm._s(_vm.product.Description) }
          }),
          _vm._v(" "),
          _vm.product.ArticleNr != ""
            ? _c("p", { staticClass: "pr-numbers" }, [
                _vm._v(
                  _vm._s(_vm.articlnrlabel) +
                    " " +
                    _vm._s(_vm.product.ArticleNr)
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.RSKNr != ""
            ? _c("p", { staticClass: "pr-numbers" }, [
                _vm._v(_vm._s(_vm.rsklabel) + " " + _vm._s(_vm.product.RSKNr))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.Price != ""
            ? _c("p", { staticClass: "pr-price" }, [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(_vm.translations, "Products.Price")
                  ) +
                    ": " +
                    _vm._s(
                      _vm.currencyFormat(_vm.product.Price, "sv-SE", "SEK")
                    )
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "btn-container" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary tt-orange",
            attrs: { href: _vm.product.Url }
          },
          [
            _vm._v(
              _vm._s(
                _vm.getDictionaryItem(_vm.translations, "Products.ReadMore")
              )
            )
          ]
        ),
        _vm._v(" "),
        _vm.customer
          ? _c(
              "div",
              {
                staticClass: "btn btn-primary tt-verylight-gray btn-add-list",
                class: { added: _vm.productItem.AddedToFavoriteList },
                on: { click: _vm.favoriteOrders }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "Products.SaveToList"
                      )
                    ) +
                    " "
                ),
                _c("span", { staticClass: "icon" })
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }