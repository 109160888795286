var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "body-scroll-lock",
          rawName: "v-body-scroll-lock",
          value: _vm.isActive,
          expression: "isActive"
        },
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.deactivateMobileSearch,
          expression: "deactivateMobileSearch"
        }
      ],
      staticClass: "searchinputmobile",
      class: { active: _vm.isActive, disabled: !_vm.isActive }
    },
    [
      _vm.isActive
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchString,
                expression: "searchString"
              }
            ],
            ref: "searchinputmobile",
            attrs: {
              id: "mobilesearchinput",
              autocomplete: "off",
              placeholder: _vm.searchplaceholder,
              tabindex: "1"
            },
            domProps: { value: _vm.searchString },
            on: {
              blur: _vm.handleBlur,
              focus: _vm.handleFocus,
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.toSearchResult.apply(null, arguments)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchString = $event.target.value
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isActive
        ? _c(
            "button",
            { staticClass: "close-btn", on: { click: _vm.clearSearch } },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "10",
                    height: "9",
                    viewBox: "0 0 10 9",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M2 1.46094L8.29422 7.5392",
                      stroke: "black",
                      "stroke-width": "1.5",
                      "stroke-linecap": "square",
                      "stroke-linejoin": "round"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M2.10742 7.64648L8.18568 1.35226",
                      stroke: "black",
                      "stroke-width": "1.5",
                      "stroke-linecap": "square",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isActive
        ? _c(
            "button",
            {
              staticClass: "search-button",
              on: { click: _vm.activateMobileSearch }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "20",
                    viewBox: "0 0 20 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z",
                      stroke: "#303030",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M17.5 17.5L13.875 13.875",
                      stroke: "#303030",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.searchResult.length
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "body-scroll-lock",
                    rawName: "v-body-scroll-lock",
                    value: _vm.isActive,
                    expression: "isActive"
                  }
                ],
                staticClass: "searchresult",
                class: { "input-focused": _vm.inputFocused }
              },
              [
                _c(
                  "div",
                  { staticClass: "inside-result" },
                  [
                    _c(
                      "transition-group",
                      { attrs: { name: "tt-list-fade", tag: "ul" } },
                      _vm._l(_vm.searchResult, function(cat, index) {
                        return _c("ul", { key: cat.name }, [
                          _c("li", { staticClass: "cat" }, [
                            _c(
                              "a",
                              {
                                staticClass: "label",
                                attrs: {
                                  href:
                                    _vm.allbtnurl +
                                    "?query=" +
                                    _vm.searchString +
                                    "&filter=" +
                                    cat.name
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(cat.name) +
                                    " (" +
                                    _vm._s(cat.result.length) +
                                    ")"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "results" },
                              _vm._l(cat.result, function(resItem, index) {
                                return index < 3
                                  ? _c(
                                      "li",
                                      {
                                        key: resItem.key,
                                        staticClass: "result-item"
                                      },
                                      [
                                        _c(
                                          "a",
                                          { attrs: { href: resItem.url } },
                                          [_vm._v(_vm._s(resItem.heading))]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              }),
                              0
                            )
                          ])
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "search-footer" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary tt-orange",
                          attrs: {
                            href: _vm.allbtnurl + "?query=" + _vm.searchString
                          }
                        },
                        [_vm._v(_vm._s(_vm.allbtnlabel))]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }