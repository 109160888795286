

export default class LoadMore {
    elm;
    containerQuerySelector;
    btnQuerySelector;
    hiddenQuerySelector;

    constructor(elm, containerQuerySelector, btnQuerySelector, hiddenQuerySelector = 'hidden') {
        this.elm = elm;
        this.containerQuerySelector = containerQuerySelector;
        this.btnQuerySelector = btnQuerySelector;
        this.hiddenQuerySelector = hiddenQuerySelector;
    }

    Run() {

        var btn = document.querySelector(`${this.containerQuerySelector} ${this.btnQuerySelector}`);
        if (btn) {
            btn.addEventListener('click', () => {
                var hiddenElms = document.querySelectorAll(`${this.containerQuerySelector} ${this.hiddenQuerySelector}`);
                hiddenElms.forEach((elm) => { elm.classList.remove(`${this.hiddenQuerySelector}`.replace('.', '')); });
                btn.classList.add('d-none');
            });
        }

    }

}


