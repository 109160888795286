var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 category-top" }, [
        _c("h1", [_vm._v(_vm._s(_vm.categoryname))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "div",
          { attrs: { id: "product-searchinput" } },
          [
            _c("product-searchinput", {
              attrs: {
                productsearchpage: _vm.productsearchpage,
                searchbuttonlabel: _vm.getDictionaryItem(
                  _vm.translations,
                  "Products.Search"
                ),
                searchplaceholder: _vm.getDictionaryItem(
                  _vm.translations,
                  "Products.SearchPlaceHolder"
                )
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tree col-md-4 col-lg-3 col-12" },
        [
          _c(
            "div",
            { attrs: { id: "product-filter" } },
            [
              _c("product-filter", {
                attrs: {
                  filters: _vm.filterdata,
                  initiallyselectedfilters: _vm.initiallyselectedfilters
                },
                on: {
                  "update:filters": function($event) {
                    _vm.filterdata = $event
                  },
                  filtering: _vm.filtering
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("tree", {
            attrs: { category: _vm.tree, activefilters: _vm.activeFilters }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "categories col-md-8 col-lg-9 col-sm-12" }, [
        _vm.haveProducts
          ? _c(
              "div",
              { attrs: { id: "products-list" } },
              [
                _c("product-list", {
                  attrs: {
                    products: _vm.productdata,
                    calltoaction: _vm.calltoaction,
                    customer: _vm.customer,
                    articlnrlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Products.ArticleNumber"
                    ),
                    rsklabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Products.RSKNumber"
                    ),
                    togglelistlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Products.ShowAsList"
                    ),
                    togglecardlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Products.ShowAsCards"
                    ),
                    translations: _vm.translations,
                    cardcols: "col col-sm-6 col-lg-4"
                  }
                })
              ],
              1
            )
          : _c(
              "div",
              [
                _c("category-list", {
                  attrs: {
                    categories: _vm.categoriesdata,
                    activefilters: _vm.activeFilters,
                    togglelistlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Products.ShowAsList"
                    ),
                    togglecardlabel: _vm.getDictionaryItem(
                      _vm.translations,
                      "Products.ShowAsCards"
                    ),
                    cardcols: "col col-12 col-sm-6 col-lg-4"
                  }
                })
              ],
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }