var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case-listing" }, [
    _c(
      "div",
      { staticClass: "filter-row" },
      [
        _c("simple-filter", {
          attrs: {
            filteritems: _vm.allFilters,
            toplabel: _vm.getDictionaryItem(
              _vm.translations,
              "Case.FilterContents"
            ),
            selectedlabel: _vm.getDictionaryItem(
              _vm.translations,
              "Case.SelectedFilters"
            ),
            clearlabel: _vm.getDictionaryItem(_vm.translations, "Case.ClearAll")
          },
          on: { filtersChanged: _vm.onFilter }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "sorting" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sort",
              on: {
                click: function($event) {
                  return _vm.sort(false)
                }
              }
            },
            [
              _vm._v(
                _vm._s(_vm.getDictionaryItem(_vm.translations, "Case.Latest"))
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sort",
              on: {
                click: function($event) {
                  return _vm.sort(true)
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.getDictionaryItem(_vm.translations, "Case.Alphabetical")
                )
              )
            ]
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _vm.selectedFilters.length
        ? _c("h4", { staticClass: "list-header" }, [
            _vm._v(
              _vm._s(
                _vm.getDictionaryItem(
                  _vm.translations,
                  "Case.FilteredSelection"
                )
              ) +
                " (" +
                _vm._s(_vm.items.length) +
                " " +
                _vm._s(_vm.getDictionaryItem(_vm.translations, "Case.Hits")) +
                ")"
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "case-list" },
        [
          _c(
            "transition-group",
            { staticClass: "row", attrs: { name: "tt-list-fade", tag: "div" } },
            _vm._l(_vm.items, function(caseItem, caseIndex) {
              return _c(
                "a",
                {
                  key: caseItem.Key,
                  staticClass: "case-item col-md-6 col-xl-4",
                  attrs: { href: caseItem.Url, target: "_self" }
                },
                [
                  _c("img", { attrs: { src: caseItem.Image, alt: "" } }),
                  _vm._v(" "),
                  _c("h2", [
                    _vm._v(_vm._s(caseItem.Name)),
                    caseItem.City != ""
                      ? _c("span", [_vm._v(", " + _vm._s(caseItem.City))])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm._f("truncate")(caseItem.HeaderText, 35, "..."))
                    )
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }