<template>
    <div class="map">
        <div class="google-map" :id="mapid"></div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { Loader } from '@googlemaps/js-api-loader';
    import Theme from './theme.json';

    export default Vue.component('google-map', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                Theme
            }
        },
        props: {
            mapid: {
                type: String,
                required: true,
                default: 'map'
            },
            lat: {
                type: Number,
                required: true,
                default: 63.839161458597935
            },
            lng: {
                type: Number,
                required: true,
                default: 20.239688226034872
            },
            zoom: {
                type: Number,
                default: 16
            },
            markercontent: {
                type: String,
                default: ''
            },
            markertitle: {
                type: String,
                default: 'Thermotech'
            },
            customstyle: {
                type: Boolean,
                default: false
            }
        },
        mounted: function () {
            let map;
            const loader = new Loader({
                apiKey: "AIzaSyDDrK02BaAAUBvceadcKj9D8qV3ldcheU4",
                version: "weekly"
            });

            loader.load().then(() => {

                map = new google.maps.Map(document.getElementById(this.mapid), {
                    center: { lat: parseFloat(this.lat), lng: parseFloat(this.lng) },
                    zoom: this.zoom,
                });

                const image =
                    "/Assets/logo-tt-colors-icon.svg";

                const marker = new google.maps.Marker({
                    position: { lat: parseFloat(this.lat), lng: parseFloat(this.lng) },
                    title: this.markertitle,
                    map: map,
                    icon: image,
                    animation: google.maps.Animation.DROP
                });

                if (this.markercontent != '') {
                    const infowindow = new google.maps.InfoWindow({
                        content: "<p>" + this.markercontent + "</p>",
                    });

                    google.maps.event.addListener(marker, "click", () => {
                        infowindow.open(map, marker);
                    });
                }
                if (this.customstyle) {
                    this.styleMap(map);
                }
            });
        },
        methods: {
            styleMap: function (map) {
                const styledMapType = new google.maps.StyledMapType(
                    Theme,{ name: "Styled Map" }
                );
                map.mapTypes.set("styled_map", styledMapType);
                map.setMapTypeId("styled_map");
            },
        },
        computed: {

        }
    });
</script>
