<template>
    <div>
        <div class="row" v-if="posting && !orderPosted">
            <div class="col">
                <div class="preloader-logo"></div>
            </div>
        </div>
        <div class="row" v-if="orderPosted">
            <div class="col-12">
                <h2>{{getDictionaryItem(translations, 'FavoriteOrders.OrderConfirmationHeader')}}</h2>
                <p>{{notificationMessage}}</p><br />
                <p><strong>{{getDictionaryItem(translations, 'MyPages.OrderSum')}}</strong></p>
                <p v-if="offerRequest.deliveryDate">Önskat leveransdatum: {{offerRequest.deliveryDate}}</p>
                <p v-if="totalPrice">{{getDictionaryItem(translations, 'MyPages.TotalPrice')}}: {{totalPrice}}</p>
                <p v-if="totalArticles">{{getDictionaryItem(translations, 'FavoriteOrders.Count')}}: {{totalArticles}}</p>
                <p v-if="offerRequest.reference">{{getDictionaryItem(translations, 'MyPages.Reference')}}: {{offerRequest.reference}}</p><br />
                <div v-if="offerRequest.recipient">
                    <p><strong>{{getDictionaryItem(translations, 'MyPages.DeliveryAddress')}}</strong></p>
                    <p>{{offerRequest.recipient}}</p>
                    <p>{{offerRequest.address1}}</p>
                    <p>{{offerRequest.zip}}</p>
                    <p>{{offerRequest.city}}</p><br />
                </div>
                <button class="btn btn-secondary mp-orderform--btn mp-orderform--btn__last tt-blue" @click="decline($event)">{{getDictionaryItem(translations, 'MyPages.Return')}}</button>
            </div>
        </div>
        <form class="mp-orderform" v-if="!posting">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <h2>{{getDictionaryItem(translations, 'FavoriteOrders.CreateOrderHeader')}}</h2>
                        <p class="form-text mp-orderform--instruction-text">&nbsp;</p>
                    </div>
                    <div class="form-group">
                        <label for="warehouse-select">{{getDictionaryItem(translations, 'MyPages.TTCenter')}}</label>
                        <select v-model="offerRequest.officeId" class="form-select form-select-lg mp-orderform--select" id="warehouse-select">
                            <option v-for="(wareHouse, whIndex) in wareHouses" :value="wareHouse.id">
                                {{wareHouse.officeName}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="reference">{{getDictionaryItem(translations, 'MyPages.Reference')}}</label>
                        <input class="form-control modal-container_input" v-model="offerRequest.reference" type="text" value="" placeholder="" id="reference" />
                    </div>
                    <div class="form-group">
                        <label for="delivery-date">{{getDictionaryItem(translations, 'MyPages.DeliveryDate')}}</label>
                        <input class="form-control modal-container_input" id="delivery-date" v-model="offerRequest.deliveryDate" type="date" placeholder="" :min="minDate" />
                    </div>
                    <div class="form-group">
                        <label for="notes">{{getDictionaryItem(translations, 'MyPages.Notes')}}</label>
                        <input class="form-control modal-container_input" id="notes" v-model="offerRequest.notes" type="text" placeholder="Noteringar" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <h2>{{getDictionaryItem(translations, 'MyPages.DeliveryAddress')}}</h2>
                        <p class="form-text mp-orderform--instruction-text">{{getDictionaryItem(translations, 'MyPages.InfoAboutDeliveryAddress')}}</p>
                    </div>
                    <div class="form-group">
                        <label for="recipient">{{getDictionaryItem(translations, 'MyPages.Recipient')}}</label>
                        <input class="form-control modal-container_input" id="recipient" v-model="offerRequest.recipient" type="text" placeholder="" />
                    </div>
                    <div class="form-group">
                        <label for="address1">{{getDictionaryItem(translations, 'MyPages.Address1')}}</label>
                        <input class="form-control modal-container_input" id="address1" v-model="offerRequest.address1" type="text" placeholder="" />
                    </div>
                    <div class="form-group">
                        <label for="address2">{{getDictionaryItem(translations, 'MyPages.Address2')}}</label>
                        <input class="form-control modal-container_input" id="address2" v-model="offerRequest.address2" type="text" placeholder="" />
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-4">
                            <label for="zip">{{getDictionaryItem(translations, 'MyPages.Zip')}}</label>
                            <input class="form-control modal-container_input" id="zip" v-model="offerRequest.zip" type="text" placeholder="" />
                        </div>
                        <div class="form-group col-lg-8">
                            <label for="city">{{getDictionaryItem(translations, 'MyPages.City')}}</label>
                            <input class="form-control modal-container_input" id="city" v-model="offerRequest.city" type="text" placeholder="" />
                        </div>
                    </div>

                </div>
            </div>
            <div class="row align-center">
                <div class="col-12">
                    <p class="form-text mp-orderform--instruction-text">{{getDictionaryItem(translations, 'FavoriteOrders.PriceInformation')}}</p>
                </div>
                <div class="col-12 d-flex justify-content-right">
                    <button class="btn btn-primary mp-orderform--btn tt-orange" @click="createOrder($event)" :disabled="!canPost">{{getDictionaryItem(translations, 'MyPages.Send')}}</button>
                    <button class="btn btn-secondary mp-orderform--btn mp-orderform--btn__last tt-blue" @click="decline($event)">{{getDictionaryItem(translations, 'MyPages.Cancel')}}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">

    import Vue from "vue";
    import { gsap } from "gsap";
    import Modal from '../utilities/modal.vue';
    import Notification from '../utilities/notification.vue';
    export default Vue.component('order-form', {
        created: () => { },

        components: {
            Modal,
            Notification
        },
        data: function () {
            return {

                orderCreation: false,
                notificationMessage: "No request!",
                posting: false,
                orderPosted: false,

                wareHouses: [],

                offerRequest: {
                    officeId: 0,
                    reference: null,
                    recipient: null,
                    address1: null,
                    address2: null,
                    zip: null,
                    city: null,
                    notes: null,
                    deliveryDate: null,
                    note: null,
                    products: null
                }
            }
        },
        props: {
            customer: {
                type: Object
            },
            customerttcenter: {
                Required: false,
                type: Number,
                default: 0
            },
            favoriteOrder: {
                Required: true,
                type: Object,
            },
            currentculture: {
                required: false,
                default: "sv-SE"
            },
            translations: {
                required: true,
                type: Object
            }
        },
        mounted: function () {
            this.getWareHouses();
            this.offerRequest.products = this.garpProducts;
        },
        methods: {
            decline($event) {
                $event.preventDefault();
                this.$emit('close');
            },
            declineFromModal() {
                this.$emit('close');
            },
            createOrder($event) {
                $event.preventDefault();

                if (this.posting)
                    return;

                this.posting = true;

                this.$http.post('/api/mypages/order/create', this.offerRequest).then((response) => {
                    this.orderPosted = true;
                    this.notificationMessage = this.getDictionaryItem(this.translations, 'FavoriteOrders.OrderCreated');
                }, (response) => {
                    console.error(response);
                    this.notificationMessage = this.getDictionaryItem(this.translations, 'FavoriteOrders.NotCreated')
                });

            },
            getWareHouses() {
                this.$http.post('/api/mypages/office/warehouses', { "culture": this.currentculture }).then((response) => {
                    this.wareHouses = response.data;
                    if (response.data) {
                        this.wareHouses.unshift({
                            id: 0,
                            officeName: this.getDictionaryItem(this.translations, 'FavoriteOrders.PickOffice')
                        });
                        this.selectedWarehouse = this.wareHouses[0].id;

                        this.offerRequest.officeId = this.customerttcenter !== 0 ? this.customerttcenter : 0;
                    }
                }, (response) => {
                    console.error(response);
                });
            },

            validateNumber(product) {
                product.Count = isNaN(product.Count) || product.Count === '' ? 0 : product.Count;
            },
        },
        computed: {
            garpProducts() {
                let products = []
                this.favoriteOrder.orderRows.forEach((row, index) => {
                    products.push(
                        {
                            ArticleNumber: row.articleNr != "" ? row.articleNr : "0",
                            Quantity: row.count
                        }
                    );
                })
                return products;
            },
            minDate() {
                var tomorrow = new Date();
                tomorrow.setDate(new Date().getDate() + 1);
                return tomorrow.toISOString().split('T')[0];
            },
            canPost() {
                return this.offerRequest.officeId && this.offerRequest.reference;
            },
            totalPrice() {
                return this.favoriteOrder.orderRows.map(i => i.price * i.count).reduce((a, b) => a + b);
            },
            totalArticles() {
                return this.favoriteOrder.orderRows.map(i => i.count).reduce((a, b) => a + b);
            }
        }
    });

</script>

