var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-10 d-flex" }, [
        _c("h3", [
          _vm._v(
            _vm._s(_vm.getDictionaryItem(_vm.translations, "Offers.Blueprints"))
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-2 d-flex justify-content-end align-items-center" },
        [
          _c("a", {
            staticClass:
              "btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action",
            on: { click: _vm.closeBlueprints }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _vm.blueprints.length > 0
      ? _c(
          "div",
          [
            _c(
              "transition-group",
              {
                staticClass: "data-table__table",
                attrs: { name: "faderow", tag: "table", css: true },
                on: {
                  "before-enter": _vm.onBeforeEnter,
                  enter: _vm.onEnter,
                  leave: _vm.onLeave
                }
              },
              [
                _c(
                  "tr",
                  { key: "-1", staticClass: "data-table__table__top-row" },
                  [
                    _c("th", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Offers.BlueprintsForOrder"
                            )
                          ) +
                          " " +
                          _vm._s(_vm.orderId) +
                          "\n                "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.blueprints, function(blueprint, index) {
                  return _vm.blueprints.length > 0
                    ? _c(
                        "tr",
                        {
                          key: blueprint,
                          staticClass: "data-table__table__non-top-row"
                        },
                        [
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: blueprint.url, target: "_blank" }
                              },
                              [
                                _c("svg", {
                                  staticClass:
                                    "icon data-table__table__doc-icon data-table__table__doc-icon--short-width",
                                  staticStyle: { "text-decoration": "none" }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(blueprint.name))])
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                })
              ],
              2
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    (!_vm.blueprints || _vm.blueprints.length == 0) && _vm.downloaded
      ? _c("div", [
          _c("b", [
            _vm._v(
              _vm._s(
                _vm.getDictionaryItem(_vm.translations, "Offers.NoBlueprints")
              ) +
                " " +
                _vm._s(_vm.orderId)
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.error
      ? _c("div", { staticClass: "error-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.getDictionaryItem(
                  _vm.translations,
                  "Offers.BlueprintsDownloadError"
                )
              ) +
              "\n    "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }