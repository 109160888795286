<template>
    <div class="notification-container" @click="closeModal(false)">
        <div class="container">
            <div class="row">
                <div class="col offset-md-2 offset-lg-1 col-md-8 col-lg-10 col-sm-12">
                    <div class="notification-container_content">
                        <div class="notification-container_message">
                            {{message}}
                        </div>
                        <div v-if="useokbutton" class="notification-container_btncontainer">
                            <div class="btn btn-small btn-primary tt-orange" @click="btnCloseModal">Ok</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { gsap } from "gsap";


    export default Vue.component('notification', {
        data: function () {
            return {}
        },
        mounted: function () {
            if (this.time && this.time > 0) {
                setTimeout(
                    () => { this.closeModal() }, this.time);
            }

        },
        created() {
            if (this.createclickevent === true) {
                window.addEventListener('click', (e) => {
                    this.closeModal();
                })
            }
        },
        props: {
            message: {
                default: '',
                type: String,
            },
            time: {
                default: 0,
                type: Number,
                required: true,
            },
            useokbutton: {
                default: false,
                type: Boolean
            },
            createclickevent: {
                default: true,
                type: Boolean,
                required: false
            }

        },
        methods: {
            closeModal() {
                this.$emit('closeModal');
            },
            btnCloseModal() {
                this.$emit('btnCloseModal');
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    scale: 0.9,
                    duration: 0,
                    alpha: 0,
                    translateY: 500
                })
            },
            onEnter(el, done) {
                gsap.to(el, {
                    scale: 1,
                    alpha: 1,
                    duration: .5,
                    x: 0,
                    translateY: 0,
                    onComplete: done,
                    delay: el.dataset.index * 0.05
                })
            },
        }
    });
</script>