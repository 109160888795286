var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project" }, [
    _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "flex-grow-1" }, [
        _c("h2", [
          _vm._v(
            "\n                " +
              _vm._s(_vm.project.code) +
              " - " +
              _vm._s(_vm.project.state) +
              "\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex " }, [
        _c("a", {
          staticClass:
            "btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action",
          on: {
            click: function($event) {
              return _vm.close()
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 data-table_searchdata" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchInput,
              expression: "searchInput"
            }
          ],
          class: { active: _vm.inputActive },
          attrs: {
            type: "text",
            placeholder: _vm.getDictionaryItem(_vm.translations, "Search"),
            "aria-label": "Search criteria",
            "aria-describedby": "basic-addon2"
          },
          domProps: { value: _vm.searchInput },
          on: {
            click: function($event) {
              _vm.inputActive = true
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchInput = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.searchInput != ""
          ? _c(
              "button",
              {
                staticClass: "data-table_btn-clear",
                attrs: { tabindex: "1" },
                on: { click: _vm.resetSearch }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "10",
                      height: "9",
                      viewBox: "0 0 10 9",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M2 1.46094L8.29422 7.5392",
                        stroke: "black",
                        "stroke-width": "1.5",
                        "stroke-linecap": "square",
                        "stroke-linejoin": "round"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M2.10742 7.64648L8.18568 1.35226",
                        stroke: "black",
                        "stroke-width": "1.5",
                        "stroke-linecap": "square",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "data-table_table-container" },
      [
        _c(
          "div",
          { staticClass: "data-table__mobile-table collapse-component" },
          [
            _vm._l(_vm.projectCount, function(row, index) {
              return _vm.loading
                ? _c("div", { staticClass: "collapse-item" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-collapse collapsed",
                        attrs: {
                          type: "button",
                          "data-bs-toggle": "collapse",
                          "aria-expanded": "true"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "data-table__mobile-table__container container"
                          },
                          [
                            _c("div", { staticClass: "top-row row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__left-col col"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDictionaryItem(
                                        _vm.translations,
                                        "Common.Loading"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__right-col col"
                                },
                                [_vm._v("-")]
                              )
                            ]),
                            _vm._v(" "),
                            _vm._m(0, true)
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _c(
              "transition-group",
              {
                attrs: { name: "faderow", tag: "div", css: true },
                on: {
                  "before-enter": _vm.onBeforeEnter,
                  enter: _vm.onEnter,
                  leave: _vm.onLeave
                }
              },
              [
                _vm._v(
                  "\n\n                " +
                    _vm._s(_vm.project.quotations) +
                    "\n                "
                ),
                _vm._l(_vm.gsOutput(), function(quotation, index) {
                  return _c(
                    "div",
                    {
                      key: quotation.quotationCode,
                      staticClass: "collapse-item"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-collapse collapsed",
                          attrs: {
                            type: "button",
                            "data-bs-toggle": "collapse",
                            "data-bs-target": "#collapse-" + index,
                            "aria-expanded": "true",
                            "aria-controls": "collapse-" + index
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "data-table__mobile-table__container container"
                            },
                            [
                              _c("div", { staticClass: "top-row row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-table__mobile-table__left-col col"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "MyPages.QuotationCode"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-table__mobile-table__right-col col"
                                  },
                                  [_vm._v(_vm._s(quotation.quotationCode))]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-table__mobile-table__left-col col"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "NameMyPages.QuotationName"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-table__mobile-table__right-col col"
                                  },
                                  [_vm._v(_vm._s(quotation.quotationName))]
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "collapse",
                          attrs: { id: "collapse-" + index }
                        },
                        [
                          _c("div", { staticClass: "card card-body" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "data-table__mobile-table__container "
                              },
                              [
                                _c("div", { staticClass: "top-row row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-table__mobile-table__left-col cell col"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDictionaryItem(
                                            _vm.translations,
                                            "MyPages.QuotationState"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-table__mobile-table__right-col cell align-left col"
                                    },
                                    [_vm._v(_vm._s(quotation.stateText))]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "top-row bottom-row row" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__left-col cell col"
                                      },
                                      [_vm._v("Type")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__right-col cell align-left col"
                                      },
                                      [_vm._v(_vm._s(quotation.typeText))]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.loading
              ? _c("div", { staticClass: "preloader-logo" })
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "transition-group",
          {
            staticClass: "data-table__table",
            attrs: { name: "faderow", tag: "table", css: true },
            on: {
              "before-enter": _vm.onBeforeEnter,
              enter: _vm.onEnter,
              leave: _vm.onLeave
            }
          },
          [
            _c("tr", { key: 0, staticClass: "data-table__table__top-row" }, [
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "MyPages.QuotationCode"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "MyPages.QuotationState"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "MyPages.QuotationName"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "MyPages.OfferValidTo"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "MyPages.QuotationDelivery"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.getDictionaryItem(_vm.translations, "MyPages.Due"))
                )
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(_vm.translations, "MyPages.Mark")
                  )
                )
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "MyPages.SalesPerson"
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.gsOutput(), function(quotation, quotationIndex) {
              return _c(
                "tr",
                {
                  key: quotation.quotationCode,
                  staticClass: "data-table__table__non-top-row"
                },
                [
                  _c("td", [_vm._v(_vm._s(quotation.quotationCode))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(quotation.stateID))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(quotation.quotationName))]),
                  _vm._v(" "),
                  _c("td", [
                    quotation.offerValidDays
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              new Date(
                                quotation.offerValidDays
                              ).toLocaleDateString("fi-FI")
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    quotation.deliveryDate
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              new Date(
                                quotation.deliveryDate
                              ).toLocaleDateString("fi-FI")
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    quotation.dueDate
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              new Date(quotation.dueDate).toLocaleDateString(
                                "fi-FI"
                              )
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(quotation.projectReference))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(quotation.salesPerson.firstName) +
                        " " +
                        _vm._s(quotation.salesPerson.lastName)
                    )
                  ])
                ]
              )
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "data-table__mobile-table__left-col col" }),
      _vm._v(" "),
      _c("div", { staticClass: "data-table__mobile-table__right-col col" }, [
        _vm._v("-")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }