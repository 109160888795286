var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "coordinates-map" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c(
          "div",
          {
            staticClass: "accordion collapse-component",
            attrs: { id: "accordionExample" }
          },
          _vm._l(_vm.mapCoordinates, function(coordinate, index) {
            return _c("div", { staticClass: "accordion-item collapse-item" }, [
              _c(
                "h2",
                {
                  staticClass: "accordion-header",
                  attrs: { id: "heading" + index }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "collapsed btn btn-collapse",
                      attrs: {
                        type: "button",
                        "data-bs-toggle": "collapse",
                        "data-bs-target": "#collapse" + index,
                        "aria-expanded": "false",
                        "aria-controls": "'collapse' + index"
                      },
                      on: {
                        click: function($event) {
                          return _vm.activateCoordinate(coordinate)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(coordinate.pickedContentName) +
                          "\n                        "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "accordion-collapse collapse",
                  attrs: {
                    id: "collapse" + index,
                    "aria-labelledby": "'heading' + index",
                    "data-bs-parent": "#accordionExample"
                  }
                },
                [
                  _c("div", { staticClass: "accordion-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("strong", [_vm._v(_vm._s(_vm.addresslabel))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(coordinate.VisitingAddress))]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(coordinate.TextA) }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-sm-6 d-flex flex-column justify-content-center"
                        },
                        [
                          _c("p", {
                            domProps: { innerHTML: _vm._s(coordinate.TextB) }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary tt-orange",
                            attrs: { href: coordinate.OfficeUrl }
                          },
                          [_vm._v(_vm._s(_vm.btnlabel))]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "map-col" }, [
          _c(
            "div",
            { staticClass: "map" },
            [
              _c("img", { attrs: { src: _vm.img, alt: "" } }),
              _vm._v(" "),
              _vm._l(_vm.mapCoordinates, function(coordinate, index) {
                return _c("div", {
                  staticClass: "coordinate accordion-button",
                  class: { active: coordinate.Active },
                  style: { left: coordinate.x + "%", top: coordinate.y + "%" },
                  attrs: {
                    "data-bs-toggle": "collapse",
                    "data-bs-target": "#collapse" + index,
                    "aria-expanded": "false",
                    "aria-controls": "'collapse' + index"
                  },
                  on: {
                    click: function($event) {
                      return _vm.activateCoordinate(coordinate)
                    }
                  }
                })
              })
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }