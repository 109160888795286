<script lang="ts">
    import Vue from "vue";

    export default Vue.directive('click-outside', {
        bind(el, binding, vnode) {
            el.clickOutsideEvent = (event) => {
                if (!(el === event.target || el.contains(event.target))) {
                    vnode.context[binding.expression](event);
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unbind(el) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        },
    });
</script>
