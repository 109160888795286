<template>
    <div class="mp-favorites">
        <div class="product-list mypages-favorites" v-if="!orderCreation">
            <div class="row">
                <transition name="editstate">
                    <edit-order v-if="isEditing"
                                @saveOrder="upDateOrders"
                                @saveCloseOrder="endEditingUpdate"
                                @closeOrder="endEditing"
                                :translations="translations"
                                :customer="customer"
                                :currentculture="currentCulture"
                                :favoriteorder="currentOrder" />
                </transition>
                <transition name="editstate">
                    <div v-if="!isEditing && favoriteOrders" class="mp-order-list table-list">
                        <div class="table-list_row row table-list_row_heading d-none d-md-flex g-md-0">
                            <div class="col-2 table-list_col">
                                <p>{{getDictionaryItem(translations, 'Common.Created')}}</p>
                            </div>
                            <div class="col-2 table-list_col">
                                <p>{{getDictionaryItem(translations, 'Common.Name')}}</p>
                            </div>
                            <div class="col-8 table-list_col d-flex justify-content-end"></div>
                        </div>
                        <div class="table-list_row row g-md-0">
                            <div class="col-8 col-md-2 table-list_col">
                                <p class="table-list_date">{{newList.createDate}}</p>
                            </div>
                            <div class="col-md-2 d-flex table-list_col">
                                <input v-model="newList.title"
                                       class="input"
                                       @click="setFocusRow(newList)"
                                       v-bind:class="{'active': focusedRow === order}" />
                                <div class="table-list_action-container d-flex d-md-none">
                                    <div class="d-md-none btn btn-secondary tt-verylight-gray btn-add-dark table-list_handheld-action btn-square" @click="createList"></div>
                                </div>
                            </div>
                            <div class="col-md-8 table-list_col d-flex justify-content-md-end">
                                <a class="btn btn-secondary tt-verylight-gray btn-add-dark btn-square table-list_handheld-action d-none d-md-block"
                                   @click="createList">
                                </a>
                            </div>
                        </div>
                        <transition-group name="faderow"
                                          tag="div"
                                          :css="false"
                                          @before-enter="onBeforeEnter"
                                          @enter="onEnter"
                                          @leave="onLeave">
                            <div v-for="(order, orderIndex) in favoriteOrders"
                                 v-if="page*orderCount > orderIndex"
                                 @click.self="editOrder(order)"
                                 class="table-list_row row g-md-0"
                                 :key="order.key"
                                 :css="false">

                                <div class="col-8 col-md-2 table-list_col">
                                    <p class="table-list_date">{{order.createDate}}</p>
                                </div>

                                <div class="d-flex col-12 col-md-10 table-list_col">
                                    <input v-model="order.title"
                                           @blur="upDateOrders"
                                           class="input"
                                           @click="setFocusRow(order)"
                                           v-on:keyup.enter="onEnterUpdate"
                                           v-bind:class="{'active': focusedRow === order}" />
                                    <div class="table-list_action-container d-flex">
                                        <a class="btn btn-secondary tt-orange btn-trash btn-square table-list_handheld-action" @click="confirmDelete(order)"></a>
                                        <a class="btn btn-secondary tt-verylight-gray btn-order-create btn-square table-list_handheld-action"
                                           @click="openOrderForm(order)"
                                           v-bind:class="{'disabled': !order.orderRows.length}"></a>
                                        <a class="btn btn-secondary tt-verylight-gray btn-edit-dark btn-square table-list_handheld-action"
                                           @click="editOrder(order)"
                                           v-bind:class="{'disabled': !order.orderRows.length}"></a>
                                    </div>
                                </div>
                            </div>
                        </transition-group>

                        <div class="row table-list_row_more" v-if="page*orderCount < favoriteOrders.length">
                            <div class="col-12 d-flex justify-content-center">
                                <div class="btn btn-primary tt-orange" @click="nextPage">
                                    {{getDictionaryItem(translations, 'Search.LoadMore')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <modal v-if="confirm"
               @closeModal="confirmedDeleteList"
               :message="getDictionaryItem(translations, 'MyPages.Modal.Heading')"
               :decline="getDictionaryItem(translations, 'MyPages.Modal.No')"
               :accept="getDictionaryItem(translations, 'MyPages.Modal.Yes')"></modal>
        <div class="mp-favorites_disable" v-if="disable">
            <div class="preloader-logo"></div>
        </div>
        <transition name="editstate">
            <div class="row" v-if="orderCreation">
                <div class="col">
                    <order-form :customerttcenter="customer.ThermotechCenterId"
                                :customer="customer"
                                :currentculture="currentCulture" 
                                :translations="translations" 
                                @close="closeForm()" 
                                :favorite-order="favoriteOrder">
                    </order-form>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import EditOrder from './_editorder.vue';
    import { gsap } from "gsap";
    import Modal from '../utilities/modal.vue';
    import OrderForm from './_orderform.vue'

    let FavoriteList = function () {
        this.title = "Ny lista";
        this.key = "56sdf45sdf6kjl";
        this.createDate = "2022-05-17";
        this.products = [];
        this.orderRows = [];
    }

    export default Vue.component('favorite-orders', {
        created: () => { },
        components: {
            EditOrder,
            Modal
        },
        data: function () {
            return {
                favoriteOrders: Array,
                customerItem: Object,
                page: 1,
                orderCount: 8,
                currentOrder: Object,
                focusedRow: Object,
                isEditing: false,
                confirm: false,
                looseOrder: Object,
                newList: Object,
                disable: false,
                currentIndex: 0,
                favoriteOrder: Object,
                orderCreation: false,
                currentCulture: ""
            }
        },
        props: {
            favoriteorders: {
                required: true,
                type: Array,
            },
            orderpage: {
                type: Number,
                default: 1
            },
            customer: {
                type: Object
            },
            translations: {
                type: Object
            }
        },
        mounted: function () {
            //this.favoriteOrders = this.favoriteorders;
            this.page = this.orderpage;
            //this.customerItem = this.customer;
            this.getCustomerObject();
            this.createEmptyList();
        },

        methods: {
            openOrderForm(order) {
                this.favoriteOrder = order;
                this.orderCreation = true;
            },
            closeForm() {
                this.orderCreation = false;
            },
            nextPage() {
                this.currentIndex = 0;
                this.page++;
                this.$emit('pageChanged', this.page);
            },
            editOrder(order) {
                if (order.orderRows.length) {
                    this.currentOrder = order;
                    this.isEditing = true;
                }
            },
            upDateOrders(order) {
                this.saveFavoriteOrders();
            },
            onEnterUpdate(ev) {
                ev.target.blur();
            },
            endEditing() {
                this.isEditing = false;
            },
            endEditingUpdate(order) {
                this.saveFavoriteOrders();
                this.isEditing = false;
            },
            getCustomerObject() {
                this.$http.post('/api/mypages/getcustomer').then((response) => {
                    this.customerItem = response.data;
                    this.favoriteOrders = this.customerItem.favoriteOrders;
                    this.currentCulture = response.data.culture;
                }, (response) => {
                    console.error(response);
                });
            },
            saveFavoriteOrders() {
                this.disable = true;
                this.customerItem.favoriteOrders = this.favoriteOrders;
                this.$http.post('/api/mypages/updatefavoriteorders', this.customerItem).then((response) => {
                    this.disable = false;
                }, (response) => {
                    console.error(response);
                });
            },
            setFocusRow(order) {
                this.focusedRow = order;
            },
            confirmDelete(order) {
                this.confirm = true;
                this.looseOrder = order;
            },
            confirmedDeleteList(acceptance) {
                if (acceptance) {
                    this.confirm = false;
                    this.deleteList(this.looseOrder);
                }
                else {
                    this.confirm = false;
                }
            },
            deleteList(order) {
                this.favoriteOrders = this.favoriteOrders.filter((c) => { return c.key !== order.key })
                this.saveFavoriteOrders();
            },
            createEmptyList() {
                this.newList = new FavoriteList();
                this.$http.post('/api/mypages/newfavoriteorderlist', this.customerItem).then((response) => {
                    this.newList.title = "Ny lista - " + this.favoriteOrders.length;
                    this.newList.key = response.data.key;
                    this.newList.createDate = response.data.createDate;
                }, (response) => {
                    console.error(response);
                });
            },
            createList() {
                this.favoriteOrders.unshift(this.newList);
                this.saveFavoriteOrders();
                this.createEmptyList();
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    duration: .2,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    alpha: 0,
                })
            },
            onEnter(el, done) {
                this.currentIndex++;
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    onComplete: done,
                    delay: this.currentIndex * 0.05
                })
            },
        },
        watch: {
            favoriteOrders: {
                handler(val) {
                    //this.upDateOrders();
                },
                deep: true
            }
        },
        computed: {}
    });
</script>
