<template>
    <div class="search-page">
        <div class="search-row row">
            <div class="col-sm-12">
                <div class="search-wrapper">
                    <input id="mainsearchinput"
                           ref="ip"
                           autocomplete="off"
                           @keyup.enter="newSearch"
                           v-model="searchParams.Query"
                           :placeholder="getDictionaryItem(translations, 'Search')">
                    <div v-if="searchString != ''" class="clear-search" @click="clearSearch"></div>
                    <button class="btn btn-primary tt-orange" @click="newSearch">
                        {{getDictionaryItem(translations, 'Search')}}
                    </button>
                </div>
            </div>
        </div>
        <div class="filter-row row">
            <div class="col-sm-12">
                <simple-filter ref="simpleFilter" v-bind:filteritems="allFilters" @filtersChanged="onFilter" 
                               :toplabel="getDictionaryItem(translations, 'Search.FilterContent')" 
                               :selectedlabel="getDictionaryItem(translations, 'Search.PickedFilters')" 
                               :clearlabel="getDictionaryItem(translations, 'Search.ClearFilter')" />
                <h3 v-if="items.length">{{getDictionaryItem(translations, 'Search.YouSearchedFor')}}: <span>"{{searchString}}"</span> {{getDictionaryItem(translations, 'Search.NumberOfHits')}}: <span>{{totalUnfiltered}}</span></h3>
                <h3 v-if="totalItems == 0">{{getDictionaryItem(translations, 'Search.NumberOfHits')}}: <span>{{totalItems}}</span></h3>
            </div>
        </div>
        <div class="searchresult-list row">
            <transition-group name="tt-list-fadeslidein" tag="div" class="col-sm-12">
                <search-item v-for="(searchItem, searchItemIndex) in items"
                             :key="searchItem.key"
                             :itemtype="searchItem.type"
                             :index="searchItemIndex"
                             v-bind:searchitem="searchItem" />
            </transition-group>
        </div>
        <div class="row justify-content-center loadmore-row" v-if="this.totalItems > this.currentCount">
            <div class="col align-self-center text-center">
                <button class="btn btn-primary tt-orange" @click="nextPage">
                    {{getDictionaryItem(translations, 'Search.LoadMore')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import SimpleFilter from '../filters/simple-filter.vue';
    import VueResource from 'vue-resource';
    import SearchItem from '../search/searchresultitem.vue';

    let SearchParams = function () {
        this.Query = "";
        this.Take = 8;
        this.Filters = [];
        this.Culture = "";
    }


    export default Vue.component('searchpage', {
        created: () => {

        },
        components: {
            SimpleFilter,
            SearchItem
        },
        data: function () {
            return {
                searchString: '',
                allFilters: [],
                items: [],
                take: 8,
                currentCount: 8,
                totalItems: 0,
                totalUnfiltered: 0,
                searchParams: new SearchParams(),
            }
        },
        props: {
            querystring: {
                type: String,
                required: false,
                default: ''
            },
            translations: {
                type: Object
            },
            culture: {
                type: String,
                required: true,
            }
        },
        mounted: function () {
            this.searchParams.Culture = this.culture;

            if (this.querystring && this.querystring != '') {
                this.searchString = this.querystring;
                this.searchParams.Query = this.querystring;
                this.objectSearch();
            }
        },
        methods: {
            objectSearch: function (filtering) {
                this.$http.post('/api/search/searchpage', this.searchParams).then((response) => {
                    this.items = [];
                    this.searchString = this.searchParams.Query;
                    this.allFilters = filtering != true ? response.body.filters : this.allFilters;
                    this.items = response.body.result;
                    this.totalItems = response.body.totalItemsCount;
                    this.totalUnfiltered = response.body.unfilteredCount;
                }, (response) => {
                    console.error(response);
                });
            },
            nextPage: function () {
                if (this.totalItems > this.currentCount) {
                    this.currentCount = this.currentCount + this.take;
                    this.searchParams.Take = this.currentCount;
                    this.objectSearch(true);
                }
            },
            newSearch: function () {
                this.objectSearch();
            },
            onFilter: function (selectedFilters) {
                this.searchParams.Take = this.currentCount;
                this.searchParams.Filters = [];
                //this.currentCount = this.take;

                if (selectedFilters.length) {
                    this.searchParams.Filters = selectedFilters;
                }
                else {
                    this.searchParams.Filters = [];
                }

                if (this.searchParams.Query != '') {
                    this.objectSearch(true);
                }
            },
            clearSearch: function () {
                this.searchParams = new SearchParams();
                this.searchParams.Culture = this.culture;
                this.searchString = '';
                this.allFilters = [];
                this.items = [];
                this.currentCount = this.take;
                this.totalItems = 0;
                this.totalUnfiltered = 0;
                this.$refs.simpleFilter.clearFilters();
            }
        },
        computed: {
            //filteredOnKey() {
            //    if (this.items && this.selectedFilters && this.selectedFilters.length > 0) {
            //        return this.items.filter((el) => {
            //            let matched = false;
            //            this.selectedFilters.forEach((sel) => {
            //                if (el.categoryKey === sel.key) {
            //                    matched = true;
            //                }
            //            })
            //            return matched;
            //        });
            //    }
            //    else {
            //        return this.unfilteredItems;

            //    }
            //},
        }
    });
</script>
