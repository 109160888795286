var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timed-tabs", on: { click: _vm.stop } }, [
    _c(
      "div",
      { staticClass: "timed-tabs_container" },
      _vm._l(_vm.tabpanes, function(tabpane, index) {
        return _c(
          "div",
          {
            key: tabpane.Key,
            staticClass: "tab-pane",
            class: [
              { active: tabpane.Active },
              { prev: tabpane.Prev },
              { next: tabpane.Next }
            ]
          },
          [
            _c("div", { staticClass: "row", class: _vm.imgpos }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("img", {
                  staticClass: "img-item",
                  attrs: {
                    loading: "lazy",
                    src: tabpane.Img,
                    alt: tabpane.AltTag
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 content-column" }, [
                _c("span", { staticClass: "tag" }, [
                  _vm._v(_vm._s(tabpane.Label))
                ]),
                _vm._v(" "),
                _c("h2", [_vm._v(_vm._s(tabpane.Title))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(tabpane.Text))]),
                _vm._v(" "),
                tabpane.Button
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-sm btn-primary tt-orange",
                        attrs: {
                          href: tabpane.Button.Url,
                          target: tabpane.Button.Target
                        }
                      },
                      [_vm._v(_vm._s(tabpane.Button.Name))]
                    )
                  : _vm._e()
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "nav nav-pills" },
      _vm._l(_vm.tabpanes, function(tabpane, index) {
        return _c("li", { staticClass: "nav-pills_item" }, [
          _c(
            "button",
            {
              staticClass: "nav-link nav-pills_button",
              class: [{ active: tabpane.Active }, { stopped: _vm.stopped }],
              on: {
                click: function($event) {
                  return _vm.setTab(tabpane)
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(tabpane.BtnLabel))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(tabpane.BtnTxt))])
            ]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }