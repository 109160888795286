var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mp-orderedit" },
      [
        !_vm.orderCreation
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-sm-12 d-md-flex" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.favoriteOrder.title,
                      expression: "favoriteOrder.title"
                    }
                  ],
                  staticClass: "mp-favorites_heading-input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.favoriteOrder.title },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onEnterUpdate.apply(null, arguments)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.favoriteOrder, "title", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "mp-favorites_heading-sum" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "FavoriteOrders.ListSum"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.currencyFormat(_vm.totalPrice, "sv-SE", "SEK"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "btn d-sm-none btn-small btn-primary tt-orange create-btn",
                    on: {
                      click: function($event) {
                        _vm.orderCreation = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.getDictionaryItem(
                            _vm.translations,
                            "MyPages.PlaceAnOrder"
                          )
                        ) +
                        "\n                "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "btn d-none d-sm-block btn-small btn-primary tt-orange create-btn",
                    on: {
                      click: function($event) {
                        _vm.orderCreation = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.getDictionaryItem(
                            _vm.translations,
                            "MyPages.PlaceAnOrder"
                          )
                        ) +
                        "\n                "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-6 d-flex justify-content-end align-items-center"
                },
                [
                  _c("a", {
                    staticClass:
                      "btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action d-none d-sm-block",
                    on: { click: _vm.closeOrder }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn btn-small btn-primary tt-blue d-sm-none btn-mob-close",
                      on: { click: _vm.closeOrder }
                    },
                    [_vm._v("\n                    Stäng\n                ")]
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("transition", { attrs: { name: "editstate" } }, [
          _vm.orderCreation
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("order-form", {
                      attrs: {
                        customer: _vm.customer,
                        customerttcenter: _vm.ttCenterId,
                        translations: _vm.translations,
                        "favorite-order": _vm.favoriteOrder,
                        currentculture: _vm.currentculture
                      },
                      on: {
                        close: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.orderCreation
          ? _c(
              "div",
              { staticClass: "mp-orderedit-list table-list" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "table-list_row table-list_row_heading row d-none d-md-flex g-md-0"
                  },
                  [
                    _c("div", { staticClass: "col-2 table-list_col" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "FavoriteOrders.ArticleNumber"
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-2 table-list_col" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Products.RSKNumber"
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-2 table-list_col" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Products.ProductName"
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-3 table-list_col d-flex justify-content-center"
                      },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.getDictionaryItem(
                                _vm.translations,
                                "FavoriteOrders.Count"
                              )
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-2 table-list_col" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Products.GrossPrice"
                            )
                          )
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition-group",
                  {
                    attrs: { name: "faderow", tag: "div", css: false },
                    on: {
                      "before-enter": _vm.onBeforeEnter,
                      enter: _vm.onEnter,
                      leave: _vm.onLeave
                    }
                  },
                  _vm._l(_vm.favoriteOrder.orderRows, function(
                    product,
                    productIndex
                  ) {
                    return _vm.page * _vm.orderCount > productIndex
                      ? _c(
                          "div",
                          {
                            key: product.key,
                            staticClass: "table-list_row row g-md-0"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-2 order-2 order-md-1 table-list_col"
                              },
                              [
                                _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "d-inline-block d-md-none" },
                                    [_vm._v("Art.nr: ")]
                                  ),
                                  _vm._v(_vm._s(product.articleNr))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-2 order-3 order-md-2 table-list_col"
                              },
                              [
                                _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "d-inline-block d-md-none" },
                                    [_vm._v("RSK: ")]
                                  ),
                                  _vm._v(_vm._s(product.rskNr))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-2 order-1 order-md-3 table-list_col"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "table-list_title",
                                    attrs: { href: product.Url }
                                  },
                                  [_vm._v(_vm._s(product.title))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-3 order-1 order-md-3 table-list_col d-flex justify-content-center"
                              },
                              [
                                _c("div", { staticClass: "btn-qty" }, [
                                  _c("div", {
                                    staticClass: "btn btn-sub",
                                    on: {
                                      click: function($event) {
                                        return _vm.reduceCount(product)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: product.count,
                                        expression: "product.count"
                                      }
                                    ],
                                    staticClass:
                                      "mp-orderedit-list_product-count",
                                    domProps: { value: product.count },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            product,
                                            "count",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.validateNumber(product)
                                        }
                                      ],
                                      change: _vm.saveOrder
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "btn btn-add",
                                    on: {
                                      click: function($event) {
                                        return _vm.increaseCount(product)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("a", {
                                    staticClass:
                                      "btn btn-secondary tt-orange btn-trash btn-square table-list_handheld-action d-md-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmDelete(product)
                                      }
                                    }
                                  })
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-2 order-4 table-list_col"
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.currencyFormat(
                                        product.price * product.count,
                                        "sv-SE",
                                        "SEK"
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-1 order-5 table-list_col justify-content-end d-none d-md-flex"
                              },
                              [
                                _c("a", {
                                  staticClass:
                                    "btn btn-secondary tt-orange btn-trash btn-square table-list_handheld-action",
                                  on: {
                                    click: function($event) {
                                      return _vm.confirmDelete(product)
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.page * _vm.orderCount < _vm.totalOrders
                  ? _c("div", { staticClass: "row table-list_row_more row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 d-flex justify-content-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "btn btn-primary tt-orange",
                              on: { click: _vm.nextPage }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.getDictionaryItem(
                                      _vm.translations,
                                      "MyPages.LoadMore"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row table-list_row_more row" }, [
                  _c("div", { staticClass: "col-12 d-flex" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.getDictionaryItem(
                            _vm.translations,
                            "FavoriteOrders.PriceInformation"
                          )
                        ) +
                        "\n                "
                    )
                  ])
                ])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.confirm
          ? _c("modal", {
              attrs: {
                message: _vm.getDictionaryItem(
                  _vm.translations,
                  "MyPages.Modal.Heading"
                ),
                decline: _vm.getDictionaryItem(
                  _vm.translations,
                  "MyPages.Modal.No"
                ),
                accept: _vm.getDictionaryItem(
                  _vm.translations,
                  "MyPages.Modal.Yes"
                )
              },
              on: { closeModal: _vm.confirmedDeleteProduct }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.notificationMessage != ""
          ? _c("notification", {
              attrs: {
                message: _vm.notificationMessage,
                createclickevent: false,
                useokbutton: true,
                time: "4000"
              },
              on: { closeModal: _vm.closeMessage }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }