import { render, staticRenderFns } from "./download-link.vue?vue&type=template&id=acde58a2&"
import script from "./download-link.vue?vue&type=script&lang=ts&"
export * from "./download-link.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Thermotech\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('acde58a2')) {
      api.createRecord('acde58a2', component.options)
    } else {
      api.reload('acde58a2', component.options)
    }
    module.hot.accept("./download-link.vue?vue&type=template&id=acde58a2&", function () {
      api.rerender('acde58a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Dev/js/components/utilities/download-link.vue"
export default component.exports