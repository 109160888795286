<template>
    <div class="data-table_table-container">
        <div class="row">
            <div class="col-10 d-flex">
                <h3>Status för order: {{orderid}}</h3>
            </div>
            <div class="col-2 d-flex justify-content-end align-items-center">
                <a class="btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action" @click="closeShipping()"></a>
            </div>
        </div>


        <div class="data-table__mobile-table collapse-component">
            <div class="collapse-item" v-for="(row,index) in orderCount" v-if="loading">
                <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true">
                    <div class="data-table__mobile-table__container container">
                        <div class="top-row row">
                            <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Common.Loading')}}</div>
                            <div class="data-table__mobile-table__right-col col">-</div>
                        </div>
                        <div class="row">
                            <div class="data-table__mobile-table__left-col col"> </div>
                            <div class="data-table__mobile-table__right-col col">-</div>
                        </div>
                    </div>
                </button>
            </div>
            <transition-group name="faderow"
                              tag="div"
                              :css="true"
                              @before-enter="onBeforeEnter"
                              @enter="onEnter"
                              @leave="onLeave">
                <div v-for="(parcel, index) in shippingData"
                     class="collapse-item"
                     :key="parcel.deliverNo">
                    <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#collapse-' + index" aria-expanded="true"
                            :aria-controls="'collapse-' + index">
                        <div class="data-table__mobile-table__container container">
                            <div class="row">
                                <div class="data-table__mobile-table__left-col col-12">{{parcel.deliverNo}} - {{parcel.customerName}}</div>
                            </div>
                        </div>
                    </button>

                    <div class="collapse" :id="'collapse-' + index" style="">
                        <div class="card card-body">
                            <div class="data-table__mobile-table__container ">
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col cell col">
                                        {{getDictionaryItem(translations, 'Shipping.StreetAddress')}}:
                                    </div>
                                    <div class="data-table__mobile-table__right-col cell align-left col">{{parcel.deliverAddress1}}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col cell col">
                                        {{getDictionaryItem(translations, 'Shipping.ZipCity')}}:
                                    </div>
                                    <div class="data-table__mobile-table__right-col cell align-left col">{{parcel.deliverZipCity}}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col cell col">
                                        {{getDictionaryItem(translations, 'Shipping.ReceiverReference')}}:
                                    </div>
                                    <div class="data-table__mobile-table__right-col cell align-left col">{{parcel.deliverAddress2}}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col cell col">
                                        {{getDictionaryItem(translations, 'Shipping.ModeOfDelivery')}}:
                                    </div>
                                    <div class="data-table__mobile-table__right-col cell align-left col">{{parcel.deliverWayDescription}}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col cell col">Spåra lev:</div>
                                    <div class="data-table__mobile-table__right-col cell align-left col">
                                        <a :href="parcel.tracking.url" target="_blank" v-if="parcel.packageId != ''">
                                            <img class="data-table__table__deliver-icon" v-if="parcel.tracking.logo != ''" :src="parcel.tracking.logo" alt="Logo" />
                                            {{parcel.tracking.company}} - {{parcel.packageId}}
                                            <svg class="icon data-table__table__new-window-icon data-table__table__new-window-icon--short-width" />
                                        </a>
                                        <p class="data-table__table__small-text" v-else-if="parcel.tracking.company != ''">
                                            <img class="data-table__table__deliver-icon" v-if="parcel.tracking.logo != ''" :src="parcel.tracking.logo" alt="Logo" />
                                            {{parcel.tracking.company}}, {{getDictionaryItem(translations, 'Shipping.MissingTracking')}}
                                        </p>
                                        <p v-else>{{getDictionaryItem(translations, 'Shipping.MissingTracking')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
            <div class="preloader-logo" v-if="loading"></div>
        </div>

        <transition-group name="faderow"
                          tag="table"
                          class="data-table__table"
                          :css="true"
                          @before-enter="onBeforeEnter"
                          @enter="onEnter"
                          @leave="onLeave">

            <tr class="data-table__table__top-row" key="0">
                <th>
                    {{getDictionaryItem(translations, 'Shipping.DeliveryNumber')}}
                </th>
                <th>
                    {{getDictionaryItem(translations, 'Shipping.Customer')}}
                </th>
                <th>
                    {{getDictionaryItem(translations, 'Shipping.DeliveryAddress')}}
                </th>
                <th>
                    {{getDictionaryItem(translations, 'Shipping.References')}}
                </th>
                <th>
                    {{getDictionaryItem(translations, 'Shipping.ModeOfDelivery')}}
                </th>
                <th>
                    {{getDictionaryItem(translations, 'Shipping.TrackDelivery')}}
                </th>
            </tr>

            <tr class="data-table__table__non-top-row"
                v-for="(parcel, parcelIndex) in shippingData"
                :key="parcel.deliverNo">
                <td>
                    <p class="data-table__table__small-text">{{parcel.deliverNo}}</p>
                </td>
                <td>
                    <p class="data-table__table__small-text">{{parcel.customerNo}} - {{parcel.customerName}}</p>
                </td>
                <td>
                    <p class="data-table__table__small-text">{{parcel.deliverAddress1}}<br />{{parcel.deliverZipCity}}</p>
                </td>
                <td>
                    <p class="data-table__table__small-text">{{parcel.deliverAddress2}}</p>
                </td>
                <td>
                    <p class="data-table__table__small-text">{{parcel.deliverWayDescription}}</p>
                </td>
                <td class="data-table__table__small-text">
                    <a :href="parcel.tracking.url" target="_blank" v-if="parcel.packageId !== ''">
                        <img class="data-table__table__deliver-icon" v-if="parcel.tracking.logo != ''" :src="parcel.tracking.logo" alt="Logo" />
                        {{parcel.tracking.company}} - {{parcel.packageId}}
                        <svg class="icon data-table__table__new-window-icon data-table__table__new-window-icon--short-width" />
                    </a>
                    <p class="data-table__table__small-text" v-else-if="parcel.tracking.company != ''">
                        <img class="data-table__table__deliver-icon" v-if="parcel.tracking.logo != ''" :src="parcel.tracking.logo" alt="Logo" />
                        {{parcel.tracking.company}}({{getDictionaryItem(translations, 'Shipping.MissingTracking')}})
                    </p>
                    <p v-else>{{getDictionaryItem(translations, 'Shipping.MissingTracking')}}</p>
                </td>
            </tr>
            <tr class="data-table__table__non-top-row data-table__table__loading-row" v-if="loading" :key="0">
                <td colspan="6">{{getDictionaryItem(translations, 'Common.Loading')}}</td>
                <td><div class="loader"></div></td>
            </tr>
            <tr v-for="(shipping, shippingIndex)  in shippingCount"
                v-if="loading"
                class="data-table__table__non-top-row loading"
                :key="shippingIndex">
                <td colspan="6">&nbsp;</td>
            </tr>
        </transition-group>
        <div class="preloader-logo" v-if="loading"></div>

        <div v-if="!shippingData || shippingData.length == 0">
            <b>{{getDictionaryItem(translations, 'Shipping.NoShipping')}}</b>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { gsap } from "gsap";


    export default Vue.component('shipping', {
        data: function () {
            return {
                shippingData: {},
                loading: true,
                error: false,
                shippingCount: 8,
                currentIndex: 0,
            }
        },
        mounted: function () {
            this.fetchShippingList();
        },
        props: {
            orderid: {
                default: '',
                type: String,
            },
            translations: {
                required: false,
                type: Object
            }
        },
        methods: {
            fetchShippingList() {
                this.$http.get('/api/mypages/shipping/shippinglist/' + this.orderid).then((response) => {
                    this.shippingData = response.data;
                    this.loading = false;
                    if (this.shippingData.length === 0) {
                        setTimeout(() => {
                            this.closeShipping();
                        }, 1500);
                    }
                    else {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                }, (response) => {
                    console.error(response);
                    this.closeShipping();
                });
            },
            closeShipping() {
                this.$emit('closeShipping');
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    duration: 0,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    alpha: 0,
                    delay: 0
                })
            },
            onEnter(el, done) {
                this.currentIndex++;
                this.currentIndex = this.currentIndex >= (this.shippingCount + 1) ? 0 : this.currentIndex;
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    x: 0,
                    onComplete: done,
                    delay: this.currentIndex * 0.05
                })
            },
        },
        watch: {
            orderid: {
                handler(val) {
                    this.fetchShippingList();
                },
                deep: true
            }
        },
    });
</script>