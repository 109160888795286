var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category-card", class: { "list-view": _vm.listview } },
    [
      _vm.category.ImageUrl
        ? _c("div", { staticClass: "img-container" }, [
            _c("img", {
              attrs: { src: _vm.category.ImageUrl, alt: _vm.category.Alt }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "btn btn-primary tt-orange", attrs: { href: _vm.url } },
        [_vm._v(_vm._s(_vm.category.Name))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }