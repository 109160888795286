var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notification-container",
      on: {
        click: function($event) {
          return _vm.closeModal(false)
        }
      }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col offset-md-2 offset-lg-1 col-md-8 col-lg-10 col-sm-12"
            },
            [
              _c("div", { staticClass: "notification-container_content" }, [
                _c("div", { staticClass: "notification-container_message" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.message) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _vm.useokbutton
                  ? _c(
                      "div",
                      { staticClass: "notification-container_btncontainer" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "btn btn-small btn-primary tt-orange",
                            on: { click: _vm.btnCloseModal }
                          },
                          [_vm._v("Ok")]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }