export default class PreventDoubleClick {

    constructor() {}

    Run(preventDoubleClickFor) {
        
        document
            .querySelectorAll(preventDoubleClickFor).forEach(elm => {

                elm.addEventListener('click',

                    function (e) {
                        setTimeout(() => { e.target.disabled = true }, 0);


                    });
        });
    }
}

