<template>
    <div class="d-inline">
        <a href="url" @click="download" :alt="altText" :class="classes">
            <slot></slot>
        </a>
        <notification
            v-if="notifyError"
            @closeModal="notifyError = false"
           :message="errorMessage"
           :time="1500">
        </notification>
    </div>
    
    
</template>

<script lang="ts">
    import Vue from "vue";
    import Notification from './notification.vue';


    export default Vue.component('download-link', {
        components: [Notification],
        data: function () {
            return {
                notifyError: false
            }
        },
        mounted: function () {
        },
        props: {
            url: {
                default: '',
                type: String,
            },
            altText: {
                default: '',
                type: String,
            },
            errorMessage: {
                default: '',
                type: String,
            },
            classes: {
                default: [],
                type: Array,
            }
        },
        methods: {
            download(e) {
                e.preventDefault();
                fetch(this.url)
                    .then((res) => {
                        if (res.ok) {
                            return res.blob();
                        }
                        else {
                            this.notifyError = true;
                            return null;
                        }
                    })
                    .then((blob) => {
                        if (blob == null) return;

                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.target = "_blank";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    });
            },
        }
    });
</script>