var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.filters
      ? _c("div", { staticClass: "filters" }, [
          _c("div", { staticClass: "select-filters" }, [
            _c(
              "div",
              { staticClass: "container" },
              _vm._l(_vm.filters, function(filter, index) {
                return _c("select-filter", {
                  key: filter.Property,
                  attrs: {
                    "parent-element": _vm.parentElement,
                    property: filter.Property,
                    caption: filter.Name,
                    options: _vm.getOptions(_vm.documenations),
                    "checked-filters": _vm.checkedFilters[index]
                  },
                  on: {
                    "update:checkedFilters": function($event) {
                      return _vm.$set(_vm.checkedFilters, index, $event)
                    },
                    "update:checked-filters": function($event) {
                      return _vm.$set(_vm.checkedFilters, index, $event)
                    }
                  }
                })
              }),
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "selected-filters" }, [
            _c("div", { staticClass: "container" }, [
              _c("p", { staticClass: "tag" }, [_vm._v(_vm._s(_vm.tagText))]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "transition-group",
                    { attrs: { name: "tt-list-fade", tag: "p" } },
                    [
                      _vm._l(_vm.checkedFilters, function(checkedFilter) {
                        return _vm._l(checkedFilter.Options, function(option) {
                          return _c(
                            "button",
                            {
                              key: option,
                              staticClass:
                                "btn btn-primary tt-white btn-filter",
                              on: {
                                click: function($event) {
                                  return _vm.unselect(
                                    checkedFilter.Options,
                                    option
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(option) +
                                  "\n                            "
                              )
                            ]
                          )
                        })
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm.filtering
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary tt-verylight-gray btn-filter f-flex d-lg-none ",
                            on: { click: _vm.clearFilters }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.clearAllText) +
                                "\n                        "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "files container" },
      [
        _c(
          "transition-group",
          {
            staticClass: "documenation-listing-module",
            attrs: { name: "tt-list-fade", tag: "div" }
          },
          _vm._l(_vm.filteredDocumenation, function(documentation) {
            return _c(
              "p",
              { key: documentation.Key, staticClass: "documentation file" },
              [
                _c(
                  "a",
                  { attrs: { href: documentation.Url, target: "_blank" } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(documentation.Name) +
                        "\n                    \n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "tags" },
                  _vm._l(documentation.Tags, function(tagItem) {
                    return documentation.Tags && documentation.Tags.length
                      ? _c("span", { staticClass: "tag" }, [
                          _vm._v(_vm._s(tagItem))
                        ])
                      : _vm._e()
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "download-link",
                    attrs: {
                      href: documentation.Url,
                      download: documentation.FileName
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "16",
                          height: "16",
                          viewBox: "0 0 16 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M1 8H15",
                            stroke: "black",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M8 1L15 8L8 15",
                            stroke: "black",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            )
          }),
          0
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.showButton
      ? _c(
          "div",
          { staticClass: "show-all d-flex flex-row justify-content-center" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary tt-orange",
                on: {
                  click: function($event) {
                    _vm.showAll = true
                  }
                }
              },
              [_vm._v(_vm._s(_vm.showAllDocumentstionsText))]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }