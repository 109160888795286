<template>
    <div class="v-demo">
        <p>VUE</p>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('v-demo', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                appNr: 0,
            }
        },
        mounted: function () {
            this.appCount();
        },
        methods: {
            appCount: function () {      
                console.log('VUE: ' + this.appNr);
            },
        },
        computed: {

        }
    });
</script>
