<template>
    <div class="timed-tabs" @click="stop">
        <div class="timed-tabs_container">
            <div class="tab-pane" v-for="(tabpane, index) in tabpanes" :class="[{active: tabpane.Active }, {prev: tabpane.Prev }, {next: tabpane.Next }]" v-bind:key="tabpane.Key">
                <div class="row" :class="imgpos">
                    <div class="col-sm-6">
                        <img loading="lazy" class="img-item" :src="tabpane.Img" :alt="tabpane.AltTag">
                    </div>
                    <div class="col-sm-6 content-column">
                        <span class="tag">{{tabpane.Label}}</span>
                        <h2>{{tabpane.Title}}</h2>
                        <p>{{tabpane.Text}}</p>
                        <a :href="tabpane.Button.Url" :target="tabpane.Button.Target" class="btn btn-sm btn-primary tt-orange" v-if="tabpane.Button">{{tabpane.Button.Name}}</a>
                    </div>
                </div>
            </div>
        </div>
        <ul class="nav nav-pills">
            <li class="nav-pills_item" v-for="(tabpane, index) in tabpanes">
                <button class="nav-link nav-pills_button" :class="[{active: tabpane.Active },{stopped: stopped}]" @click="setTab(tabpane)">
                    <span>{{tabpane.BtnLabel}}</span>
                    <p>{{tabpane.BtnTxt}}</p>
                </button>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('timed-tabs', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                tabIndex: 0,
                tabInterval: Object,
                stopped: false,
                tabpanes: []
            }
        },
        props: {
            tabs: {
                type: Array,
                default: []
            },
            imgpos: {
                type: String,
                default: 'img-right'
            }
        },
        mounted: function () {
            this.tabpanes = this.tabs;
            this.tabpanes[0].Active = true;
            this.setTabAuto();
        },
        methods: {
            setTabAuto() {
                this.tabInterval = setInterval(() => {
                    let tabIndex = this.tabIndex == this.tabpanes.length - 1 ? 0 : this.tabIndex + 1;
                    this.setTab(this.tabpanes[tabIndex]);
                }, 3500);
            },
            setTab(tab) {
                this.tabpanes.forEach((tab) => {
                    tab.Active = false;
                    tab.Prev = false;
                    tab.Next = false;
                });

                this.tabpanes[this.tabIndex].Prev = true;
                tab.Next = true;
                this.tabIndex = this.tabpanes.indexOf(tab);
                tab.Active = true;
                tab.Next = false;
            },
            stop() {
                clearInterval(this.tabInterval);
                this.stopped = true;
            }
        },
        computed: {

        }
    });
</script>
