<template>
    <div class="mp-favorites">
        <div class="product-list mypages-favorites">
            <div class="row">
                <div class="col-sm-12 d-flex position-relative">
                    <div class="toggle-button" @click="listView = !listView">
                        <span v-if="listView">
                            {{getDictionaryItem(translations, 'Products.ShowAsCards')}}
                        </span>
                        <span v-if="!listView">{{getDictionaryItem(translations, 'Products.ShowAsList')}}</span>
                        <div class="toggler-icon" v-bind:class="{'active': listView}">
                            <span class="l1"></span>
                            <span class="l2"></span>
                            <span class="l3"></span>
                        </div>
                    </div>
                </div>
            </div>
            <transition-group name="fade" tag="div" class="product-list-items row" v-bind:class="{'list-view': listView}">
                <div v-bind:class="[!listView ? cardcols : 'col-12']"
                     v-for="(product, productIndex) in favoriteItems"
                     :key="product.Key">
                    <product-card v-if="product.IsFavorite && page*productCount > productIndex"
                                  @favoriteChanged="upDateFavorites"
                                  :product="product"
                                  :key="product.Key"
                                  :customer="customer"
                                  :listview="listView"
                                  :translations="translations"
                                  :articlnrlabel="getDictionaryItem(translations, 'Products.ArticleNumber')"
                                  :rsklabel="getDictionaryItem(translations, 'Products.RSKNumber')"
                                  @favoriteOrdersClicked="opentFavoritesList"
                                  :calltoaction="getDictionaryItem(translations, 'Products.ToProduct')">
                    </product-card>
                </div>
            </transition-group>
            <favorites-popover :customer="customerItem" v-if="editFavorites"
                               :typedtranslations="translations"
                               :favoriteproduct="currentFavoriteProduct"
                               @closeFavorites="closeAndSaveFavoritesList">

            </favorites-popover>
            <div class="row" v-if="page*productCount < favoriteItems.length">
                <div class="col-12 d-flex justify-content-center">
                    <div class="btn btn-primary tt-orange" @click="nextPage">
                        {{getDictionaryItem(translations, 'Search.LoadMore')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import ProductCard from '../products/productcard.vue';
    import FavoritesPopover from '../products/favoritespopover.vue';

    export default Vue.component('mypages-favorites', {
        created: () => {

        },
        components: {
            ProductCard,
            FavoritesPopover
        },
        data: function () {
            return {
                customerItem: Object,
                listView: false,
                favoriteItems: Array,
                page: 1,
                productCount: 8,
                editFavorites: false,
                currentFavoriteProduct: {}
            }
        },
        props: {
            customer: {
                required: false,
                type: Object,
            },
            cardcols: {
                type: String,
                default: 'col col-sm-6 col-md-4 col-lg-3'
            },
            favorites: {
                required: true,
                type: Array
            },
            favorderstranslations: {
                required: false,
                type: Object
            },
            translations: {
                type: Object
            }
        },
        mounted: function () {
            this.customerItem = this.customer;
            this.favoriteItems = this.favorites;
        },

        methods: {
            upDateFavorites(favorite) {
                this.favoriteItems = this.favoriteItems.filter((c) => { return c.Id !== favorite.Id })
            },
            upDateFavoriteOrders(orders) {
                this.$http.post('/api/mypages/updatefavoriteorders', this.customerItem).then((response) => {

                }, (response) => {
                    console.error(response);
                });
            },
            nextPage() {
                this.page++;
            },
            setCurrentTab(tab) {
                this.currentTab = tab;
            },
            closeAndSaveFavoritesList(customer) {
                this.editFavorites = false;
                this.customerObject = customer;
            },
            opentFavoritesList(productItem) {
                this.currentFavoriteProduct = productItem;
                this.editFavorites = !this.editFavorites;
            }

        },
        computed: {

        }
    });
</script>
