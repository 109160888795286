var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "employee-listing" },
    [
      _c(
        "div",
        { staticClass: "select-filters" },
        _vm._l(_vm.filters, function(filter, index) {
          return _c("select-filter", {
            key: filter.Property,
            attrs: {
              caption: filter.Name,
              options: _vm.getOptions(_vm.employees, filter.Property),
              "checked-filters": _vm.checkedFilters[index]
            },
            on: {
              "update:checkedFilters": function($event) {
                return _vm.$set(_vm.checkedFilters, index, $event)
              },
              "update:checked-filters": function($event) {
                return _vm.$set(_vm.checkedFilters, index, $event)
              },
              update: _vm.update
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "employee-listing-module",
          attrs: { name: "tt-list-fade", tag: "div" }
        },
        _vm._l(_vm.filteredEmployees, function(employee) {
          return _c(
            "a",
            {
              key: employee.Key,
              staticClass: "employee",
              attrs: { href: employee.Url }
            },
            [
              employee.Image
                ? _c("img", { attrs: { src: employee.Image, alt: "" } })
                : _vm._e(),
              _vm._v(" "),
              !employee.Image
                ? _c("div", { staticClass: "no-img" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 512 512"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M184.32 243.392c23.136 42.592 51.664 51.536 71.52 51.536 19.824 0 48.352-8.928 71.68-51.424l1.552-2.816c12.512-22.72 20.784-37.728 20.784-73.072 0-55.616-11.168-112.448-94.016-112.448-93.472 0-93.472 75.952-93.472 112.448 0 35.376 8.176 50.432 20.544 73.2l1.408 2.576zm268.016 126.8L348.144 323.52a12.122 12.122 0 00-9.568-.112l-53.936 22.688h-57.152l-53.952-22.528c-3.04-1.28-6.48-1.232-9.52.112L59.68 370.24a11.985 11.985 0 00-7.104 10.96v63.632c0 6.624 5.376 12 12 12h382.848c6.624 0 12-5.376 12-12v-63.696c0-4.72-2.784-9.008-7.088-10.944z"
                          }
                        })
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "full-name" }, [
                _vm._v(_vm._s(employee.FullName))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "work-title" }, [
                _vm._v(_vm._s(employee.WorkTitle) + " " + _vm._s(employee.City))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "email" }, [
                _c("a", { attrs: { href: _vm.mailto(employee.Email) } }, [
                  _vm._v(_vm._s(employee.Email))
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "phone" }, [
                _c("a", { attrs: { href: _vm.tel(employee.Phone) } }, [
                  _vm._v(_vm._s(employee.Phone))
                ])
              ]),
              _vm._v(" "),
              employee.Mobile != ""
                ? _c("p", { staticClass: "phone" }, [
                    _c("a", { attrs: { href: _vm.tel(employee.Mobile) } }, [
                      _vm._v(_vm._s(employee.Mobile))
                    ])
                  ])
                : _vm._e()
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      !_vm.showAll
        ? _c(
            "div",
            { staticClass: "show-all d-flex flex-row justify-content-center" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary tt-orange",
                  on: {
                    click: function($event) {
                      _vm.showAll = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.showAllEmployeesText))]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }