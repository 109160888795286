<script lang="ts">
    import Vue from "vue";

    export default Vue.mixin({
        methods: {
            currencyFormat: function (price, lang, currency) {
                if (!price)
                    return "Pris saknas";

                var formatter = new Intl.NumberFormat('sv-SE', {
                    style: 'currency',
                    currency: 'SEK',
                });
                return formatter.format(price);
            }
        }
    })
</script>
