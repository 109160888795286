<template>
    <div class="product-searchinput">
        <form method="get" v-bind:action="productsearchpage" class="search-wrapper">
            <input id="mainsearchinput" v-model="searchString" autocomplete="off" v-bind:placeholder="searchplaceholder" tabindex="1" name="query">
            <button class="btn btn-primary tt-orange">{{searchbuttonlabel}}</button>
        </form>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('product-searchinput', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                searchString: ''
            }
        },
        props: {
            productsearchpage: {
                type: String,
                default: ''
            },
            searchbuttonlabel: {
                type: String,
                default: 'Sök'
            },
            searchplaceholder: {
                type: String,
                default: 'Search'
            }

        },
        mounted: function () {

        },
        methods: {

        },
        computed: {

        }
    });
</script>
