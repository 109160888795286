<template>

    <div class="product-card" v-bind:class="{'list-view': listview}">
        <div class="img-container" v-if="product.ImageUrl">
            <img v-bind:src="product.ImageUrl" v-bind:alt="product.Alt" />
            <favorite-button v-if="customer" :customer="customer" :product="product"></favorite-button>
        </div>
        <div class="product-content">
            <h3>{{product.Title}}</h3>
            <div class="texts">
                <p class="intro" v-html="product.Description"></p>
                <p v-if="product.ArticleNr != ''" class="pr-numbers">{{articlnrlabel}} {{product.ArticleNr}}</p>
                <p v-if="product.RSKNr != ''" class="pr-numbers">{{rsklabel}} {{product.RSKNr}}</p>
                <p v-if="product.Price != ''" class="pr-price">{{getDictionaryItem(translations, 'Products.Price')}}: {{currencyFormat(product.Price,'sv-SE','SEK')}}</p>
            </div>
        </div>
        <div class="btn-container">
            <a class="btn btn-primary tt-orange" v-bind:href="product.Url">{{getDictionaryItem(translations, 'Products.ReadMore')}}</a>
            <div v-if="customer"
                 @click="favoriteOrders"
                 class="btn btn-primary tt-verylight-gray btn-add-list"
                 v-bind:class="{'added': productItem.AddedToFavoriteList}">
                {{getDictionaryItem(translations, 'Products.SaveToList')}} <span class="icon"></span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import FavoriteButton from './favoritebutton.vue';

    export default Vue.component('product-card', {
        created: () => {

        },
        components: {
            FavoriteButton
        },
        data: function () {
            return {
                productItem: Object,
                customerItem: Object,
                editFavorites: false
            }
        },
        props: {
            product: {
                type: Object
            },
            customer: {
                required: false,
                type: Object,
            },
            calltoaction: {
                type: String
            },
            articlnrlabel: {
                type: String
            },
            rsklabel: {
                type: String
            },
            listview: {
                type: Boolean
            },
            translations: {
                type: Object
            }
        },
        mounted: function () {
            this.productItem = this.product;
            this.customerItem = this.customer;
        },
        methods: {
            favoriteOrders() {
                this.editFavorites = true;
                this.$emit('favoriteOrdersClicked', this.productItem);
            }
        },
        computed: {

        }
    });
</script>
