<script lang="ts">
    import Vue from "vue";

    export function FilterType() {
        return {
            name: '',
            key: '',
            value: '',
            operator: '',
            active: false
        }
    }

    export const CONTAINS = "c";
    export const GREATER = "gt";
    export const LESS = "lt";
    export const GREATEREQUALS = "gte";
    export const LESSEQUALS = "lte";
    export const EQUALS = "e";
    export const NOTEQUAL = "ne"

    export function Sorting() {
        return {
            _ASCENDING: "ASCENDING",
            _DESCENDING: "DESCENDING",
            order: "ASCENDING",
            key: '',
        }
    }

    export default Vue.mixin({
        data: function () {
            return {
                gsCollection: [],
                gsFilters: [],
                gsSorting: '',
                searchTerm: '',
                prSearchTerm: '',
                quotationSearchTerm: ''
            }
        },
        methods: {

            gsOutput: function () {

                let out = this.gsCollection;


                if (this.searchTerm != '') {
                    out = out.filter(item =>

                        item['orderNumber']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.searchTerm.toLowerCase()) !== -1 ||
                        item['yourReference']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.searchTerm.toLowerCase()) !== -1 ||
                        item['orderDate']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.searchTerm.toLowerCase()) !== -1 ||
                        item['extraInfo']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.searchTerm.toLowerCase()) !== -1 ||
                        item['mark']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.searchTerm.toLowerCase()) !== -1

                    );
                }

                if (this.prSearchTerm != '') {
                    out = out.filter(item =>
                        item['state']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.prSearchTerm.toLowerCase()) !== -1 ||
                        item['code']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.prSearchTerm.toLowerCase()) !== -1 ||
                        item['customer']['city']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.prSearchTerm.toLowerCase()) !== -1
                    );
                }

                if (this.quotationSearchTerm != '') {
                    out = out.filter(item =>
                        item['quotationCode']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.quotationSearchTerm.toLowerCase()) !== -1 ||
                        item['projectReference']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.quotationSearchTerm.toLowerCase()) !== -1 ||
                        item['quotationName']
                            .toString()
                            .toLowerCase()
                            .indexOf(this.quotationSearchTerm.toLowerCase()) !== -1 ||
                        (item['salesPerson'].firstName + " " + item['salesPerson'].lastName)
                            .toString()
                            .toLowerCase()
                            .indexOf(this.quotationSearchTerm.toLowerCase()) !== -1
                    );
                }
                this.gsFilters.forEach(f => {
                    switch (f.Operator) {
                        case EQUALS:
                            out = out.filter(o => o[f.key] == f.value);
                            break;
                        case NOTEQUAL:
                            out = out.filter(o => o[f.key] != f.value);
                            break
                        case GREATER:
                            out = out.filter(o => o[f.key] > f.value);
                            break;
                        case LESS:
                            out = out.filter(o => o[f.key] < f.value);
                            break;
                        case LESSEQUALS:
                            out = out.filter(o => o[f.key] >= f.value);
                            break;
                        case GREATEREQUALS:
                            out = out.filter(o => o[f.key] >= f.value);
                            break;
                        case CONTAINS:
                            out = out.filter(o => o[f.key].indexOf(f.value) > -1);
                            break;
                        default:
                            out = out.filter(o => o[f.key] == f.value);
                    }
                });

                return out;
            },
            gsToggleFilter(filter) {
                if (this.gsHaveFilter(filter))
                    this.gsRemoveFilter((filter))
                else
                    this.gsAddFilter(filter)
            },
            gsRemoveFilter(filter) {
                let index = this.gsFilters.indexOf(filter);
                if (index > -1) {
                    filter.active = false;
                    this.gsFilters.splice(index, 1)
                }
            },
            gsHaveFilter(filter) {
                return this.gsFilters.indexOf(filter) > -1;
            },
            gsAddFilter: function (filter) {
                filter.active = true;
                this.gsFilters.push(filter);
            },
            gsResetFilters() {
                this.gsFilters = [];
            },
            gsSetSort: function (sorting) {
                this.gsSorting = sorting;
            },
            gsSearch: function (term) {
                this.searchTerm = term;
            },
            gsPrSearch: function (term) {
                this.prSearchTerm = term;
            },
            gsQuotationSearch: function (term) {
                this.quotationSearchTerm = term;
            }
        }
    });

</script>
