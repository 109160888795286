<template>
    <div class="coordinates-map">
        <div class="row">
            <div class="col-sm-6">
                <div class="accordion collapse-component" id="accordionExample">
                    <div class="accordion-item collapse-item" v-for="(coordinate, index) in mapCoordinates">
                        <h2 class="accordion-header" :id="'heading' + index">
                            <button class="collapsed btn btn-collapse" @click="activateCoordinate(coordinate)" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" aria-controls="'collapse' + index">
                                {{coordinate.pickedContentName}}
                            </button>
                        </h2>
                        <div :id="'collapse' + index" class="accordion-collapse collapse" aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <strong>{{addresslabel}}</strong>
                                        <p>{{coordinate.VisitingAddress}}</p>
                                        <br />
                                        <p v-html="coordinate.TextA"></p>
                                        
                                    </div>
                                    <div class="col-sm-6 d-flex flex-column justify-content-center">
                                        <p v-html="coordinate.TextB"></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <a v-bind:href="coordinate.OfficeUrl" class="btn btn-primary tt-orange">{{btnlabel}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="map-col">
                    <div class="map">
                        <img v-bind:src="img" alt="" />
                        <div @click="activateCoordinate(coordinate)"
                             v-for="(coordinate, index) in mapCoordinates"
                             v-bind:class="{ active: coordinate.Active }"
                             v-bind:style="{left: coordinate.x + '%', top: coordinate.y + '%'}"
                             class="coordinate accordion-button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" aria-controls="'collapse' + index" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('coordinates-map', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                mapCoordinates: [],
                savedMapCoordinates: [],
                selectedCoordinate: {}
            }
        },
        props: {
            mapobject: {
                required: true
            },
            coordinates: {
                required: false
            },
            img: {
                type: String
            },
            addresslabel: {
                type: String,
                default: 'Besöksadress'
            },
            btnlabel: {
                type: String,
                default: 'Till kontorssida'
            }
        },
        mounted: function () {
            this.mapCoordinates = this.coordinates;
            this.savedMapCoordinates = this.coordinates;
        },
        methods: {
            activateCoordinate: function (coordinate) {
                this.selectedCoordinate.Active = false;
                this.selectedCoordinate = coordinate;
                coordinate.Active = true;
            }
        },
        computed: {

        }
    });
</script>
