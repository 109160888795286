var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faq-cat" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.category))]),
      _vm._v(" "),
      _vm._l(_vm.faqs, function(faqItem, index) {
        return index < _vm.itemCount
          ? _c("div", { staticClass: "collapse-component" }, [
              _c("div", { staticClass: "collapse-item" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-collapse collapsed",
                    attrs: {
                      type: "button",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": "#collapse-" + faqItem.Key,
                      "aria-expanded": "false",
                      "aria-controls": "#collapse-" + faqItem.Key
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(faqItem.Question) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse",
                    attrs: { id: "collapse-" + faqItem.Key }
                  },
                  [
                    _c("div", { staticClass: "card card-body" }, [
                      _c("p", {
                        domProps: { innerHTML: _vm._s(faqItem.Answer) }
                      })
                    ])
                  ]
                )
              ])
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.itemCount < _vm.faqs.length
        ? _c("div", { staticClass: "more-row" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.nextPage } },
              [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(_vm.translations, "FAQ.AllQuestions")
                  )
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }