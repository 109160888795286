var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-table" },
    [
      _c(
        "div",
        { staticClass: "data-table_table-container" },
        [
          _c(
            "div",
            { staticClass: "data-table__mobile-table collapse-component" },
            [
              _vm._l(_vm.invoiceCount, function(row, index) {
                return _vm.loading
                  ? _c("div", { staticClass: "collapse-item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-collapse collapsed",
                          attrs: {
                            type: "button",
                            "data-bs-toggle": "collapse",
                            "aria-expanded": "true"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "data-table__mobile-table__container container"
                            },
                            [
                              _c("div", { staticClass: "top-row row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-table__mobile-table__left-col col"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "Common.Loading"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-table__mobile-table__right-col col"
                                  },
                                  [_vm._v("-")]
                                )
                              ]),
                              _vm._v(" "),
                              _vm._m(0, true)
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _c(
                "transition-group",
                {
                  attrs: { name: "faderow", tag: "div", css: true },
                  on: {
                    "before-enter": _vm.onBeforeEnter,
                    enter: _vm.onEnter,
                    leave: _vm.onLeave
                  }
                },
                _vm._l(_vm.invoiceList, function(invoice, invoiceIndex) {
                  return _vm.page * _vm.invoiceCount > invoiceIndex
                    ? _c(
                        "div",
                        {
                          key: invoice.invoiceNumber,
                          staticClass: "collapse-item"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-collapse collapsed",
                              attrs: {
                                type: "button",
                                "data-bs-toggle": "collapse",
                                "data-bs-target": "#collapse-" + invoiceIndex,
                                "aria-expanded": "true",
                                "aria-controls": "collapse-" + invoiceIndex
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__container container"
                                },
                                [
                                  _c("div", { staticClass: "top-row row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__left-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDictionaryItem(
                                              _vm.translations,
                                              "Invoices.InvoiceNumber"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__right-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            invoice.invoiceNumber
                                              ? invoice.invoiceNumber
                                              : "-"
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "top-row row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__left-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDictionaryItem(
                                              _vm.translations,
                                              "Invoice.OrderDate"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__right-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            invoice.invoiceDate
                                              ? invoice.invoiceDate.substring(
                                                  0,
                                                  10
                                                )
                                              : "-"
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "bottom-row row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__left-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDictionaryItem(
                                              _vm.translations,
                                              "Invoice.PaymentExpiryDate"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-table__mobile-table__right-col col"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            invoice.paymentExpiryDate
                                              ? invoice.paymentExpiryDate.substring(
                                                  0,
                                                  10
                                                )
                                              : "-"
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "collapse",
                              attrs: { id: "collapse-" + invoiceIndex }
                            },
                            [
                              _c("div", { staticClass: "card card-body" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-table__mobile-table__container "
                                  },
                                  [
                                    _c("div", { staticClass: "top-row row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "data-table__mobile-table__left-col cell col"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDictionaryItem(
                                                _vm.translations,
                                                "Offers.Status"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "data-table__mobile-table__right-col cell align-left col"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              invoice.paymentStateTranslationKey
                                                ? _vm.getDictionaryItem(
                                                    _vm.translations,
                                                    invoice.paymentStateTranslationKey
                                                  )
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "top-row row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "data-table__mobile-table__left-col cell col"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDictionaryItem(
                                                _vm.translations,
                                                "Invoices.Invoice"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "data-table__mobile-table__right-col cell align-left col"
                                        },
                                        [
                                          _c(
                                            "DownloadLink",
                                            {
                                              attrs: {
                                                url: invoice.pdfUrl,
                                                errorMessage: _vm.getDictionaryItem(
                                                  _vm.translations,
                                                  "Invoices.MissingFile"
                                                ),
                                                classes: [
                                                  "data-table_document",
                                                  " data-table__table__button--no-style"
                                                ]
                                              }
                                            },
                                            [
                                              _c("svg", {
                                                staticClass:
                                                  "icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "top-row row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "data-table__mobile-table__left-col cell col"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDictionaryItem(
                                                _vm.translations,
                                                "Invoices.Sum"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "data-table__mobile-table__right-col cell align-left col"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              invoice.total
                                                ? invoice.total + ":-"
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _vm.loading
                ? _c("div", { staticClass: "preloader-logo" })
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "transition-group",
            {
              staticClass: "data-table__table",
              attrs: { name: "faderow", tag: "table", css: true },
              on: {
                "before-enter": _vm.onBeforeEnter,
                enter: _vm.onEnter,
                leave: _vm.onLeave
              }
            },
            [
              _c("tr", { key: 0, staticClass: "data-table__table__top-row" }, [
                _c("th", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "Invoices.InvoiceNumber"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("th", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "Invoice.OrderDate"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("th", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "Invoice.PaymentExpiryDate"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("th", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(_vm.translations, "Offers.Status")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("th", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "Invoices.Invoice"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "data-table__table__rightmost-cell" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(_vm.translations, "Invoices.Sum")
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.invoiceList, function(invoice, invoiceIndex) {
                return _vm.page * _vm.invoiceCount > invoiceIndex
                  ? _c(
                      "tr",
                      {
                        key: invoice.invoiceNumber,
                        staticClass: "data-table__table__non-top-row"
                      },
                      [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              invoice.invoiceNumber
                                ? invoice.invoiceNumber
                                : "-"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              invoice.invoiceDate
                                ? invoice.invoiceDate.substring(0, 10)
                                : "-"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              invoice.paymentExpiryDate
                                ? invoice.paymentExpiryDate.substring(0, 10)
                                : "-"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              invoice.paymentStateTranslationKey
                                ? _vm.getDictionaryItem(
                                    _vm.translations,
                                    invoice.paymentStateTranslationKey
                                  )
                                : "-"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "DownloadLink",
                              {
                                attrs: {
                                  url: invoice.pdfUrl,
                                  classes: [
                                    "data-table_document",
                                    " data-table__table__button--no-style"
                                  ],
                                  errorMessage: _vm.getDictionaryItem(
                                    _vm.translations,
                                    "Invoices.MissingFile"
                                  )
                                }
                              },
                              [
                                _c("svg", {
                                  staticClass:
                                    "icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width"
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "data-table__table__rightmost-cell" },
                          [
                            _vm._v(
                              _vm._s(invoice.total ? invoice.total + ":-" : "-")
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.invoiceList && _vm.invoiceList.length == 0 && !_vm.loading
                ? _c(
                    "tr",
                    { key: 0, staticClass: "data-table__table__non-top-row" },
                    [
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.getDictionaryItem(
                                _vm.translations,
                                "Common.NoInvoices"
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loading
                ? _c(
                    "tr",
                    {
                      key: 0,
                      staticClass:
                        "data-table__table__non-top-row data-table__table__loading-row"
                    },
                    [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Common.Loading"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [_c("div", { staticClass: "loader" })])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.invoiceCount, function(invoice, invoiceIndex) {
                return _vm.loading
                  ? _c(
                      "tr",
                      {
                        key: invoiceIndex,
                        staticClass: "data-table__table__non-top-row loading"
                      },
                      [_c("td", [_vm._v(" ")])]
                    )
                  : _vm._e()
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.loading ? _c("div", { staticClass: "preloader-logo" }) : _vm._e(),
          _vm._v(" "),
          _vm.invoiceList
            ? _c("div", { staticClass: "data-table__btn-wrapper" }, [
                _vm.invoiceList && _vm.invoiceList.length
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary tt-orange",
                        on: { click: _vm.nextPage }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "MyPages.LoadMore"
                            )
                          )
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.notifyError
        ? _c("notification", {
            attrs: { message: _vm.errorMessage },
            on: {
              closeModal: function($event) {
                _vm.notifyError = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "data-table__mobile-table__left-col col" }),
      _vm._v(" "),
      _c("div", { staticClass: "data-table__mobile-table__right-col col" }, [
        _vm._v("-")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }