<template>
    <div class="simple-filter">
        <h4 v-if="filteritems.length">{{toplabel}}</h4>
        <div class="filters">
            <button v-for="(filter, index) in filteritems"
                    :key="filter.category"
                    @click="pushFilters(filter)"
                    v-bind:class="{ active: filterStatus(filter) }"
                    class="btn btn-primary btn-filter">
                {{filter.category}}
            </button>
        </div>
        <transition name="fade">
            <div class="active-filters" v-if="selectedFilters.length">
                <h4>{{selectedlabel}}</h4>
                <button v-for="(selectedFilter, index) in selectedFilters"
                        :key="selectedFilter.category"
                        @click="pushFilters(selectedFilter)"
                        class="btn btn-secondary btn-selected active">
                    {{selectedFilter.category}}
                </button>
                <button class="btn btn-secondary btn-selected" @click="clearFilters">
                    {{clearlabel}}
                </button>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('simple-filter', {
        created: () => {

        },
        components: {

        },
        data: function () {
            return {
                selectedFilters: []
            }
        },
        //props: ['filteritems', 'toplabel', 'selectedlabel', 'clearlabel'],
        props: {
            toplabel: String,
            selectedlabel: String,
            clearlabel: String,
            filteritems: {
                type: Array,
            }
        },
        mounted: function () {

        },
        methods: {
            filtersChanged() {
                this.$emit('filtersChanged', this.selectedFilters);
            },
            pushFilters: function (item) {
                if (this.filterStatus(item)) {
                    this.selectedFilters = this.selectedFilters.filter((c) => { return c !== item })
                }
                else {
                    this.selectedFilters.push(item);
                }
                this.filtersChanged();
            },
            clearFilters: function () {
                this.selectedFilters = [];
                this.filtersChanged();
            },
            filterStatus: function (item) {
                return this.selectedFilters.includes(item);
            },
            resetFilters: function () {
                this.selectedFilters = [];
                this.filtersChanged();
            },
            sendMessToParent: function () {
                this.$emit('messageFromChild', this.arg1)
            },
            setQueryFilter: function () {
                let urlParams = new URLSearchParams(window.location.search);

                if (urlParams.has('filter')) {
                    let filterName = urlParams.get('filter');
                    let tempArr = this.filteritems.filter((obj, index) => {
                        if (obj.category === filterName) {
                            this.pushFilters(this.filteritems[index]);
                        }
                    });

                    return urlParams.get('filter');
                }
                else {
                    return '';
                }
            }

        },
        watch: {
            filteritems: {
                handler(val, oldVal) {
                    this.setQueryFilter();
                }
            }
        }
    });
</script>
