var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customer
    ? _c("div", {
        staticClass: "favorite-heart",
        class: { active: _vm.productItem.IsFavorite },
        on: { click: _vm.toggleFavorite }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }