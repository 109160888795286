<template>
    <div class="data-table">
        <div class="data-table_table-container">
            <div class="data-table__mobile-table collapse-component">
                <div class="collapse-item" v-for="(row,index) in invoiceCount" v-if="loading">
                    <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true">
                        <div class="data-table__mobile-table__container container">
                            <div class="top-row row">
                                <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Common.Loading')}}</div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                            <div class="row">
                                <div class="data-table__mobile-table__left-col col"> </div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                        </div>
                    </button>
                </div>
                <transition-group name="faderow"
                                  tag="div"
                                  :css="true"
                                  @before-enter="onBeforeEnter"
                                  @enter="onEnter"
                                  @leave="onLeave">
                    <div v-for="(invoice, invoiceIndex) in invoiceList" class="collapse-item" v-if="page*invoiceCount > invoiceIndex"
                         :key="invoice.invoiceNumber">
                        <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#collapse-' + invoiceIndex" aria-expanded="true"
                                :aria-controls="'collapse-' + invoiceIndex">
                            <div class="data-table__mobile-table__container container">
                                <div class="top-row row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Invoices.InvoiceNumber')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{ invoice.invoiceNumber? invoice.invoiceNumber : "-"  }}</div>
                                </div>
                                <div class="top-row row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Invoice.OrderDate')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{ invoice.invoiceDate? invoice.invoiceDate.substring(0,10) : "-" }}</div>
                                </div>
                                <div class="bottom-row row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Invoice.PaymentExpiryDate')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{invoice.paymentExpiryDate? invoice.paymentExpiryDate.substring(0,10) : "-" }}</div>
                                </div>

                            </div>
                        </button>

                        <div class="collapse" :id="'collapse-' + invoiceIndex" style="">
                            <div class="card card-body">
                                <div class="data-table__mobile-table__container ">
                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Offers.Status')}}</div>
                                        <div class="data-table__mobile-table__right-col cell align-left col">{{ invoice.paymentStateTranslationKey? getDictionaryItem(translations, invoice.paymentStateTranslationKey) : "-"  }}</div>
                                    </div>

                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Invoices.Invoice')}}</div>
                                        <div class="data-table__mobile-table__right-col cell align-left col">
                                            <DownloadLink :url="invoice.pdfUrl" :errorMessage="getDictionaryItem(translations, 'Invoices.MissingFile')" :classes="['data-table_document', ' data-table__table__button--no-style']">
                                                <svg class="icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width" />
                                            </DownloadLink>
                                        </div>
                                    </div>

                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'Invoices.Sum')}}</div>
                                        <div class="data-table__mobile-table__right-col cell align-left col">{{ invoice.total? invoice.total + ':-' : "-"  }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <div class="preloader-logo" v-if="loading"></div>
            </div>
            <transition-group name="faderow"
                              tag="table"
                              class="data-table__table"
                              :css="true"
                              @before-enter="onBeforeEnter"
                              @enter="onEnter"
                              @leave="onLeave">
                <tr class="data-table__table__top-row" :key="0">
                    <th>{{getDictionaryItem(translations, 'Invoices.InvoiceNumber')}}</th>
                    <th>{{getDictionaryItem(translations, 'Invoice.OrderDate')}}</th>
                    <th>{{getDictionaryItem(translations, 'Invoice.PaymentExpiryDate')}}</th>
                    <th>{{getDictionaryItem(translations, 'Offers.Status')}}</th>
                    <th>{{getDictionaryItem(translations, 'Invoices.Invoice')}}</th>
                    <th class="data-table__table__rightmost-cell">{{getDictionaryItem(translations, 'Invoices.Sum')}}</th>
                </tr>
                <tr v-for="(invoice, invoiceIndex)  in invoiceList"
                    class="data-table__table__non-top-row"
                    :key="invoice.invoiceNumber"
                    v-if="page*invoiceCount > invoiceIndex">
                    <td>{{invoice.invoiceNumber? invoice.invoiceNumber : "-"  }}</td>
                    <td>{{invoice.invoiceDate? invoice.invoiceDate.substring(0,10) : "-" }}</td>
                    <td>{{invoice.paymentExpiryDate? invoice.paymentExpiryDate.substring(0,10) : "-" }}</td>
                    <td>{{ invoice.paymentStateTranslationKey? getDictionaryItem(translations, invoice.paymentStateTranslationKey) : "-"  }}</td>
                    <td>
                        <DownloadLink :url="invoice.pdfUrl" :classes="['data-table_document', ' data-table__table__button--no-style']" :errorMessage="getDictionaryItem(translations, 'Invoices.MissingFile')">
                            <svg class="icon data-table__table__delivery-note-icon data-table__table__delivery-note-icon--short-width" />
                        </DownloadLink>
                    </td>

                    <td class="data-table__table__rightmost-cell">{{ invoice.total? invoice.total + ':-' : "-"  }}</td>
                </tr>
                <tr class="data-table__table__non-top-row" v-if="invoiceList && invoiceList.length == 0 && !loading" :key="0">
                    <td>
                        {{getDictionaryItem(translations, 'Common.NoInvoices')}}
                    </td>
                </tr>
                <tr class="data-table__table__non-top-row data-table__table__loading-row" v-if="loading" :key="0">
                    <td>{{getDictionaryItem(translations, 'Common.Loading')}}</td>
                    <td><div class="loader"></div></td>
                </tr>
                <tr v-for="(invoice, invoiceIndex)  in invoiceCount"
                    v-if="loading"
                    class="data-table__table__non-top-row loading"
                    :key="invoiceIndex">
                    <td>&nbsp;</td>
                </tr>
            </transition-group>
            <div class="preloader-logo" v-if="loading"></div>
            <div class="data-table__btn-wrapper" v-if="invoiceList">
                <button v-if="invoiceList && invoiceList.length" class="btn btn-primary tt-orange" @click="nextPage">{{getDictionaryItem(translations, 'MyPages.LoadMore')}}</button>
            </div>
        </div>

        <notification v-if="notifyError"
                      @closeModal="notifyError = false"
                      :message="errorMessage">
        </notification>

    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { gsap } from "gsap";
    import DownloadLink from '../utilities/download-link.vue';
    import Notification from '../utilities/notification.vue';

    export default Vue.component('mypages-invoices', {
        data: function () {
            return {
                loadMoreCount: 0,
                invoiceList: [],
                page: 1,
                invoiceCount: 8,
                currentIndex: 0,
                loading: true,
                notifyError: false,
                errorMessage: ''
            }
        },
        components: {
            DownloadLink, Notification
        },
        mounted: function () {
            this.invoiceList = this.invoices;
            this.fetchInvoices();
        },
        props: {
            translations: {
                type: Object
            }
        },
        computed: {},
        methods: {
            fetchInvoices() {

                this.$http.get('/api/mypages/invoice/list').then((response) => {
                    this.invoiceList = response.data;
                    this.loading = false;
                }, (response) => {
                    this.errorMessage = this.getDictionaryItem(this.translations, 'MyPages.HttpFailure');
                    this.notifyError = true;
                    this.loading = false;
                    this.invoiceList = [];
                    console.error(response);
                });
            },
            nextPage() {
                this.currentIndex = 0;
                this.page++;
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    duration: 0,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    alpha: 0,
                    delay: 0
                })
            },
            onEnter(el, done) {
                this.currentIndex++;
                this.currentIndex = this.currentIndex >= (this.invoiceCount + 1) ? 0 : this.currentIndex;
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    x: 0,
                    onComplete: done,
                    delay: this.currentIndex * 0.05
                })
            },
        }
    });
</script>
