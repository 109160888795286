<template>
    <div class="project">
        <div class="d-flex">
            <div class="flex-grow-1">
                <h2>
                    {{project.code}} - {{project.state}}
                </h2>
            </div>
            <div class="d-flex ">
                <a class="btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action" @click="close()"></a>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 data-table_searchdata">
                <input type="text" v-model="searchInput" v-bind:class="{ active: inputActive }" :placeholder="getDictionaryItem(translations, 'Search')" aria-label="Search criteria" aria-describedby="basic-addon2" @click="inputActive = true">
                <button tabindex="1" class="data-table_btn-clear" v-if="searchInput != ''" @click="resetSearch">
                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 1.46094L8.29422 7.5392" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path> <path d="M2.10742 7.64648L8.18568 1.35226" stroke="black" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"></path></svg>
                </button>
            </div>
        </div>
        <div class="data-table_table-container">
            <div class="data-table__mobile-table collapse-component">
                <div class="collapse-item" v-for="(row,index) in projectCount" v-if="loading">
                    <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true">
                        <div class="data-table__mobile-table__container container">
                            <div class="top-row row">
                                <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'Common.Loading')}}</div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                            <div class="row">
                                <div class="data-table__mobile-table__left-col col"> </div>
                                <div class="data-table__mobile-table__right-col col">-</div>
                            </div>
                        </div>
                    </button>
                </div>
                <transition-group name="faderow"
                                  tag="div"
                                  :css="true"
                                  @before-enter="onBeforeEnter"
                                  @enter="onEnter"
                                  @leave="onLeave">

                    {{project.quotations}}
                    <div v-for="(quotation, index) in gsOutput()"
                         class="collapse-item"
                         :key="quotation.quotationCode">
                        <button class="btn btn-collapse collapsed" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#collapse-' + index" aria-expanded="true"
                                :aria-controls="'collapse-' + index">
                            <div class="data-table__mobile-table__container container">
                                <div class="top-row row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'MyPages.QuotationCode')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{quotation.quotationCode}}</div>
                                </div>
                                <div class="row">
                                    <div class="data-table__mobile-table__left-col col">{{getDictionaryItem(translations, 'NameMyPages.QuotationName')}}</div>
                                    <div class="data-table__mobile-table__right-col col">{{ quotation.quotationName  }}</div>
                                </div>
                            </div>
                        </button>
                        <div class="collapse" :id="'collapse-' + index" style="">
                            <div class="card card-body">
                                <div class="data-table__mobile-table__container ">
                                    <div class="top-row row">
                                        <div class="data-table__mobile-table__left-col cell col">{{getDictionaryItem(translations, 'MyPages.QuotationState')}}</div>
                                        <div class="data-table__mobile-table__right-col cell align-left col">{{quotation.stateText}}</div>
                                    </div>

                                    <div class="top-row bottom-row row">
                                        <div class="data-table__mobile-table__left-col cell col">Type</div>
                                        <div class="data-table__mobile-table__right-col cell align-left col">{{quotation.typeText}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </transition-group>
                <div class="preloader-logo" v-if="loading"></div>
            </div>


            <transition-group name="faderow"
                              tag="table"
                              class="data-table__table"
                              :css="true"
                              @before-enter="onBeforeEnter"
                              @enter="onEnter"
                              @leave="onLeave">
                <tr class="data-table__table__top-row" :key="0">
                    <th>{{getDictionaryItem(translations, 'MyPages.QuotationCode')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.QuotationState')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.QuotationName')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.OfferValidTo')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.QuotationDelivery')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.Due')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.Mark')}}</th>
                    <th>{{getDictionaryItem(translations, 'MyPages.SalesPerson')}}</th>
                </tr>
                <tr v-for="(quotation, quotationIndex) in gsOutput()"
                    class="data-table__table__non-top-row"
                    :key="quotation.quotationCode">
                    <td>{{quotation.quotationCode}}</td>
                    <td>{{quotation.stateID}}</td>
                    <td>{{quotation.quotationName}}</td>
                    <td><span v-if="quotation.offerValidDays">{{new Date(quotation.offerValidDays).toLocaleDateString("fi-FI")}}</span></td>
                    <td><span v-if="quotation.deliveryDate">{{new Date(quotation.deliveryDate).toLocaleDateString("fi-FI") }}</span></td>
                    <td><span v-if="quotation.dueDate">{{new Date(quotation.dueDate).toLocaleDateString("fi-FI")}}</span></td>
                    <td>{{quotation.projectReference}}</td>
                    <td>{{quotation.salesPerson.firstName}} {{quotation.salesPerson.lastName}}</td>
                </tr>

            </transition-group>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import gsap from "gsap";
    import genericSortMixin, { EQUALS, FilterType, Sorting } from '../../mixins/genericsort.vue';

    export default Vue.component('nemesys-project', {
        components: {

        },
        data: function () {
            return {
                currentIndex: 0,
                rowCount: 8,
                page: 1,
                searchInput: '',
                inputActive: false,
            }
        },
        mounted: function () {
            this.gsCollection = this.project.quotations;
        },
        props: {
            project: {
                type: Object,
                required: true
            },
            translations: {
                type: Object
            },
        },
        watch: {
            searchInput: function (val) {
                this.currentIndex = 0;
                this.gsQuotationSearch(val);
            },
        },
        computed: {
            
        },
        methods: {
            nextPage() {
                this.currentIndex = 0;
                this.page++;
            },
            resetSearch() {
                this.searchInput = "";
                this.inputActive = false;
            },
            close: function() {
                this.$emit('close');
            },
            onLeave(el, done) {
                gsap.to(el, {
                    alpha: 0,
                    duration: 0,
                    onComplete: done
                })
            },
            onBeforeEnter(el) {
                el.style.opacity = 0;
                gsap.to(el, {
                    duration: 0,
                    alpha: 0,
                    delay: 0
                })
            },
            onEnter(el, done) {
                this.currentIndex++;
                this.currentIndex = this.currentIndex >= (this.rowCount + 1) ? 0 : this.currentIndex;
                gsap.to(el, {
                    alpha: 1,
                    duration: .3,
                    x: 0,
                    onComplete: done,
                    delay: this.currentIndex * 0.05
                })
            },
        }
    });
</script>
