var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.posting && !_vm.orderPosted
      ? _c("div", { staticClass: "row" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _vm.orderPosted
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", [
              _vm._v(
                _vm._s(
                  _vm.getDictionaryItem(
                    _vm.translations,
                    "FavoriteOrders.OrderConfirmationHeader"
                  )
                )
              )
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.notificationMessage))]),
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(_vm.translations, "MyPages.OrderSum")
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _vm.offerRequest.deliveryDate
              ? _c("p", [
                  _vm._v(
                    "Önskat leveransdatum: " +
                      _vm._s(_vm.offerRequest.deliveryDate)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.totalPrice
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.TotalPrice"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.totalPrice)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.totalArticles
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "FavoriteOrders.Count"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.totalArticles)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.offerRequest.reference
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.Reference"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.offerRequest.reference)
                  )
                ])
              : _vm._e(),
            _c("br"),
            _vm._v(" "),
            _vm.offerRequest.recipient
              ? _c("div", [
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.getDictionaryItem(
                            _vm.translations,
                            "MyPages.DeliveryAddress"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.offerRequest.recipient))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.offerRequest.address1))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.offerRequest.zip))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.offerRequest.city))]),
                  _c("br")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-secondary mp-orderform--btn mp-orderform--btn__last tt-blue",
                on: {
                  click: function($event) {
                    return _vm.decline($event)
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(_vm.translations, "MyPages.Return")
                  )
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.posting
      ? _c("form", { staticClass: "mp-orderform" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "FavoriteOrders.CreateOrderHeader"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "form-text mp-orderform--instruction-text" },
                  [_vm._v(" ")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "warehouse-select" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.TTCenter"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.offerRequest.officeId,
                        expression: "offerRequest.officeId"
                      }
                    ],
                    staticClass:
                      "form-select form-select-lg mp-orderform--select",
                    attrs: { id: "warehouse-select" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.offerRequest,
                          "officeId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.wareHouses, function(wareHouse, whIndex) {
                    return _c("option", { domProps: { value: wareHouse.id } }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(wareHouse.officeName) +
                          "\n                        "
                      )
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "reference" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.Reference"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.offerRequest.reference,
                      expression: "offerRequest.reference"
                    }
                  ],
                  staticClass: "form-control modal-container_input",
                  attrs: {
                    type: "text",
                    value: "",
                    placeholder: "",
                    id: "reference"
                  },
                  domProps: { value: _vm.offerRequest.reference },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.offerRequest,
                        "reference",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "delivery-date" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.DeliveryDate"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.offerRequest.deliveryDate,
                      expression: "offerRequest.deliveryDate"
                    }
                  ],
                  staticClass: "form-control modal-container_input",
                  attrs: {
                    id: "delivery-date",
                    type: "date",
                    placeholder: "",
                    min: _vm.minDate
                  },
                  domProps: { value: _vm.offerRequest.deliveryDate },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.offerRequest,
                        "deliveryDate",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "notes" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(_vm.translations, "MyPages.Notes")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.offerRequest.notes,
                      expression: "offerRequest.notes"
                    }
                  ],
                  staticClass: "form-control modal-container_input",
                  attrs: {
                    id: "notes",
                    type: "text",
                    placeholder: "Noteringar"
                  },
                  domProps: { value: _vm.offerRequest.notes },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.offerRequest, "notes", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.DeliveryAddress"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "form-text mp-orderform--instruction-text" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.getDictionaryItem(
                          _vm.translations,
                          "MyPages.InfoAboutDeliveryAddress"
                        )
                      )
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "recipient" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.Recipient"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.offerRequest.recipient,
                      expression: "offerRequest.recipient"
                    }
                  ],
                  staticClass: "form-control modal-container_input",
                  attrs: { id: "recipient", type: "text", placeholder: "" },
                  domProps: { value: _vm.offerRequest.recipient },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.offerRequest,
                        "recipient",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "address1" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.Address1"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.offerRequest.address1,
                      expression: "offerRequest.address1"
                    }
                  ],
                  staticClass: "form-control modal-container_input",
                  attrs: { id: "address1", type: "text", placeholder: "" },
                  domProps: { value: _vm.offerRequest.address1 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.offerRequest,
                        "address1",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "address2" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "MyPages.Address2"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.offerRequest.address2,
                      expression: "offerRequest.address2"
                    }
                  ],
                  staticClass: "form-control modal-container_input",
                  attrs: { id: "address2", type: "text", placeholder: "" },
                  domProps: { value: _vm.offerRequest.address2 },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.offerRequest,
                        "address2",
                        $event.target.value
                      )
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col-lg-4" }, [
                  _c("label", { attrs: { for: "zip" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.getDictionaryItem(_vm.translations, "MyPages.Zip")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.offerRequest.zip,
                        expression: "offerRequest.zip"
                      }
                    ],
                    staticClass: "form-control modal-container_input",
                    attrs: { id: "zip", type: "text", placeholder: "" },
                    domProps: { value: _vm.offerRequest.zip },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.offerRequest, "zip", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-lg-8" }, [
                  _c("label", { attrs: { for: "city" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.getDictionaryItem(_vm.translations, "MyPages.City")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.offerRequest.city,
                        expression: "offerRequest.city"
                      }
                    ],
                    staticClass: "form-control modal-container_input",
                    attrs: { id: "city", type: "text", placeholder: "" },
                    domProps: { value: _vm.offerRequest.city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.offerRequest, "city", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row align-center" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "p",
                { staticClass: "form-text mp-orderform--instruction-text" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "FavoriteOrders.PriceInformation"
                      )
                    )
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 d-flex justify-content-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mp-orderform--btn tt-orange",
                  attrs: { disabled: !_vm.canPost },
                  on: {
                    click: function($event) {
                      return _vm.createOrder($event)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(_vm.translations, "MyPages.Send")
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-secondary mp-orderform--btn mp-orderform--btn__last tt-blue",
                  on: {
                    click: function($event) {
                      return _vm.decline($event)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.getDictionaryItem(_vm.translations, "MyPages.Cancel")
                    )
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "preloader-logo" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }