var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", {
      staticClass: "modal-container_backdrop",
      on: {
        click: function($event) {
          return _vm.closeModal(false)
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "col offset-md-2 offset-lg-1 col-md-8 col-lg-10 col-sm-12"
          },
          [
            _c("div", { staticClass: "modal-container_content" }, [
              _c("div", { staticClass: "modal-container_header" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.message) +
                    "\n                        "
                ),
                _c("div", {
                  staticClass: "modal-container_close-btn btn btn-close-dark",
                  on: {
                    click: function($event) {
                      return _vm.closeModal(false)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-container_footer" }, [
                _c(
                  "div",
                  { staticClass: "modal-container_main-content" },
                  [
                    _vm._t("default"),
                    _vm._v(" "),
                    _vm.showMobalButtons
                      ? _c(
                          "div",
                          {
                            staticClass: "btn-group",
                            attrs: { role: "group", "aria-label": "toggle" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function($event) {
                                    return _vm.closeModal(true)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.accept) +
                                    "\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "btn btn-secondary active",
                                on: {
                                  click: function($event) {
                                    return _vm.closeModal(false)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.decline) +
                                    "\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }