<template>
    <div class="faq-listing">
        <simple-filter v-bind:filteritems="unfilteredItems" @filtersChanged="onFilter" :toplabel="getDictionaryItem(translations, 'FAQ.FilterContents')" :selectedlabel="getDictionaryItem(translations, 'FAQ.SelectedFilters')" :clearlabel="getDictionaryItem(translations, 'FAQ.ClearAll')"/>
        <transition-group name="fade" tag="div" class="list row">
            <div v-for="(faqCat, catIndex) in items" :key="faqCat.category" class="faq-list">
                <faq-cat :faqs="faqCat.FAQs" :category="faqCat.category" :translations="translations" v-if="faqCat.FAQs.length" />
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import SimpleFilter from '../filters/simple-filter.vue';
    import FaqCat from './faqcat.vue';

    export default Vue.component('faq-listing', {
        created: () => {

        },
        components: {
            SimpleFilter
        },
        data: function () {
            return {
                unfilteredItems: [],
                items: [],
                nrItems: 3,
                itemCount: 3,
                itemCountArr: [],
            }
        },
        props: ['listitems','translations'],
        mounted: function () {
            this.unfilteredItems = this.listitems;
            this.items = this.listitems;
        },
        methods: {
            onFilter(filteredItems) {
                if (filteredItems.length) {
                    this.items = filteredItems;
                }
                else {
                    this.items = this.unfilteredItems;
                }
                
            },
        },
        computed: { }
    });
</script>
