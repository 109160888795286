<template>
    <div class="text-collapse">
        <div ref="textel" class="collapse-text rte-content" v-bind:class="{ expanded: isExpanded }" v-html="text"></div>
        <a v-if="!isExpanded" href="#" class="expand-btn" @click.prevent="isExpanded = !isExpanded">{{buttonlabel}}</a>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('text-collapse', {
        created: () => { },
        components: {},
        data: function () {
            return {
                isExpanded: false
            }
        },
        props: {
            text: {
                type: String,
                required: true,
                default: ''
            },
            buttonlabel: {
                type: String,
                required: true,
                default: 'text not set'
            }
        },
        mounted: function () {
            let collapsedHeight = this.$refs.textel.clientHeight;
            this.isExpanded = true;
            this.$nextTick(() => {
                if (this.$refs.textel.offsetHeight >= collapsedHeight) {
                    this.isExpanded = false;
                }
                else {
                    this.isExpanded = true;
                }
            })
        },
        methods: {},
        computed: {}
    });
</script>
