var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-table_table-container" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-10 d-flex" }, [
          _c("h3", [_vm._v("Status för order: " + _vm._s(_vm.orderid))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-2 d-flex justify-content-end align-items-center"
          },
          [
            _c("a", {
              staticClass:
                "btn btn-secondary tt-orange btn-close btn-square table-list_handheld-action",
              on: {
                click: function($event) {
                  return _vm.closeShipping()
                }
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "data-table__mobile-table collapse-component" },
        [
          _vm._l(_vm.orderCount, function(row, index) {
            return _vm.loading
              ? _c("div", { staticClass: "collapse-item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-collapse collapsed",
                      attrs: {
                        type: "button",
                        "data-bs-toggle": "collapse",
                        "aria-expanded": "true"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "data-table__mobile-table__container container"
                        },
                        [
                          _c("div", { staticClass: "top-row row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "data-table__mobile-table__left-col col"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDictionaryItem(
                                      _vm.translations,
                                      "Common.Loading"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "data-table__mobile-table__right-col col"
                              },
                              [_vm._v("-")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(0, true)
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _c(
            "transition-group",
            {
              attrs: { name: "faderow", tag: "div", css: true },
              on: {
                "before-enter": _vm.onBeforeEnter,
                enter: _vm.onEnter,
                leave: _vm.onLeave
              }
            },
            _vm._l(_vm.shippingData, function(parcel, index) {
              return _c(
                "div",
                { key: parcel.deliverNo, staticClass: "collapse-item" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-collapse collapsed",
                      attrs: {
                        type: "button",
                        "data-bs-toggle": "collapse",
                        "data-bs-target": "#collapse-" + index,
                        "aria-expanded": "true",
                        "aria-controls": "collapse-" + index
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "data-table__mobile-table__container container"
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "data-table__mobile-table__left-col col-12"
                              },
                              [
                                _vm._v(
                                  _vm._s(parcel.deliverNo) +
                                    " - " +
                                    _vm._s(parcel.customerName)
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "collapse",
                      attrs: { id: "collapse-" + index }
                    },
                    [
                      _c("div", { staticClass: "card card-body" }, [
                        _c(
                          "div",
                          {
                            staticClass: "data-table__mobile-table__container "
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__left-col cell col"
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "Shipping.StreetAddress"
                                        )
                                      ) +
                                      ":\n                                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__right-col cell align-left col"
                                },
                                [_vm._v(_vm._s(parcel.deliverAddress1))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__left-col cell col"
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "Shipping.ZipCity"
                                        )
                                      ) +
                                      ":\n                                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__right-col cell align-left col"
                                },
                                [_vm._v(_vm._s(parcel.deliverZipCity))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__left-col cell col"
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "Shipping.ReceiverReference"
                                        )
                                      ) +
                                      ":\n                                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__right-col cell align-left col"
                                },
                                [_vm._v(_vm._s(parcel.deliverAddress2))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__left-col cell col"
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.getDictionaryItem(
                                          _vm.translations,
                                          "Shipping.ModeOfDelivery"
                                        )
                                      ) +
                                      ":\n                                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__right-col cell align-left col"
                                },
                                [_vm._v(_vm._s(parcel.deliverWayDescription))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__left-col cell col"
                                },
                                [_vm._v("Spåra lev:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data-table__mobile-table__right-col cell align-left col"
                                },
                                [
                                  parcel.packageId != ""
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: parcel.tracking.url,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          parcel.tracking.logo != ""
                                            ? _c("img", {
                                                staticClass:
                                                  "data-table__table__deliver-icon",
                                                attrs: {
                                                  src: parcel.tracking.logo,
                                                  alt: "Logo"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(parcel.tracking.company) +
                                              " - " +
                                              _vm._s(parcel.packageId) +
                                              "\n                                        "
                                          ),
                                          _c("svg", {
                                            staticClass:
                                              "icon data-table__table__new-window-icon data-table__table__new-window-icon--short-width"
                                          })
                                        ]
                                      )
                                    : parcel.tracking.company != ""
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "data-table__table__small-text"
                                        },
                                        [
                                          parcel.tracking.logo != ""
                                            ? _c("img", {
                                                staticClass:
                                                  "data-table__table__deliver-icon",
                                                attrs: {
                                                  src: parcel.tracking.logo,
                                                  alt: "Logo"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(parcel.tracking.company) +
                                              ", " +
                                              _vm._s(
                                                _vm.getDictionaryItem(
                                                  _vm.translations,
                                                  "Shipping.MissingTracking"
                                                )
                                              ) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    : _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getDictionaryItem(
                                              _vm.translations,
                                              "Shipping.MissingTracking"
                                            )
                                          )
                                        )
                                      ])
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.loading ? _c("div", { staticClass: "preloader-logo" }) : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "data-table__table",
          attrs: { name: "faderow", tag: "table", css: true },
          on: {
            "before-enter": _vm.onBeforeEnter,
            enter: _vm.onEnter,
            leave: _vm.onLeave
          }
        },
        [
          _c("tr", { key: "0", staticClass: "data-table__table__top-row" }, [
            _c("th", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "Shipping.DeliveryNumber"
                    )
                  ) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.getDictionaryItem(_vm.translations, "Shipping.Customer")
                  ) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "Shipping.DeliveryAddress"
                    )
                  ) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "Shipping.References"
                    )
                  ) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "Shipping.ModeOfDelivery"
                    )
                  ) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "Shipping.TrackDelivery"
                    )
                  ) +
                  "\n            "
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.shippingData, function(parcel, parcelIndex) {
            return _c(
              "tr",
              {
                key: parcel.deliverNo,
                staticClass: "data-table__table__non-top-row"
              },
              [
                _c("td", [
                  _c("p", { staticClass: "data-table__table__small-text" }, [
                    _vm._v(_vm._s(parcel.deliverNo))
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "data-table__table__small-text" }, [
                    _vm._v(
                      _vm._s(parcel.customerNo) +
                        " - " +
                        _vm._s(parcel.customerName)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "data-table__table__small-text" }, [
                    _vm._v(_vm._s(parcel.deliverAddress1)),
                    _c("br"),
                    _vm._v(_vm._s(parcel.deliverZipCity))
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "data-table__table__small-text" }, [
                    _vm._v(_vm._s(parcel.deliverAddress2))
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "data-table__table__small-text" }, [
                    _vm._v(_vm._s(parcel.deliverWayDescription))
                  ])
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "data-table__table__small-text" }, [
                  parcel.packageId !== ""
                    ? _c(
                        "a",
                        {
                          attrs: { href: parcel.tracking.url, target: "_blank" }
                        },
                        [
                          parcel.tracking.logo != ""
                            ? _c("img", {
                                staticClass: "data-table__table__deliver-icon",
                                attrs: {
                                  src: parcel.tracking.logo,
                                  alt: "Logo"
                                }
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                    " +
                              _vm._s(parcel.tracking.company) +
                              " - " +
                              _vm._s(parcel.packageId) +
                              "\n                    "
                          ),
                          _c("svg", {
                            staticClass:
                              "icon data-table__table__new-window-icon data-table__table__new-window-icon--short-width"
                          })
                        ]
                      )
                    : parcel.tracking.company != ""
                    ? _c(
                        "p",
                        { staticClass: "data-table__table__small-text" },
                        [
                          parcel.tracking.logo != ""
                            ? _c("img", {
                                staticClass: "data-table__table__deliver-icon",
                                attrs: {
                                  src: parcel.tracking.logo,
                                  alt: "Logo"
                                }
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                    " +
                              _vm._s(parcel.tracking.company) +
                              "(" +
                              _vm._s(
                                _vm.getDictionaryItem(
                                  _vm.translations,
                                  "Shipping.MissingTracking"
                                )
                              ) +
                              ")\n                "
                          )
                        ]
                      )
                    : _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.getDictionaryItem(
                              _vm.translations,
                              "Shipping.MissingTracking"
                            )
                          )
                        )
                      ])
                ])
              ]
            )
          }),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "tr",
                {
                  key: 0,
                  staticClass:
                    "data-table__table__non-top-row data-table__table__loading-row"
                },
                [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.getDictionaryItem(
                          _vm.translations,
                          "Common.Loading"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_c("div", { staticClass: "loader" })])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.shippingCount, function(shipping, shippingIndex) {
            return _vm.loading
              ? _c(
                  "tr",
                  {
                    key: shippingIndex,
                    staticClass: "data-table__table__non-top-row loading"
                  },
                  [_c("td", { attrs: { colspan: "6" } }, [_vm._v(" ")])]
                )
              : _vm._e()
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.loading ? _c("div", { staticClass: "preloader-logo" }) : _vm._e(),
      _vm._v(" "),
      !_vm.shippingData || _vm.shippingData.length == 0
        ? _c("div", [
            _c("b", [
              _vm._v(
                _vm._s(
                  _vm.getDictionaryItem(_vm.translations, "Shipping.NoShipping")
                )
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "data-table__mobile-table__left-col col" }),
      _vm._v(" "),
      _c("div", { staticClass: "data-table__mobile-table__right-col col" }, [
        _vm._v("-")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }