var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category-list" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12 d-flex position-relative" }, [
          _c(
            "div",
            {
              staticClass: "toggle-button",
              on: {
                click: function($event) {
                  _vm.listView = !_vm.listView
                }
              }
            },
            [
              _vm.listView
                ? _c("span", [_vm._v(_vm._s(_vm.togglecardlabel))])
                : _vm._e(),
              _vm._v(" "),
              !_vm.listView
                ? _c("span", [_vm._v(_vm._s(_vm.togglelistlabel))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "toggler-icon",
                  class: { active: _vm.listView }
                },
                [
                  _c("span", { staticClass: "l1" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "l2" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "l3" })
                ]
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "category-list-items row",
          class: { "list-view": _vm.listView },
          attrs: { name: "fade", tag: "div" }
        },
        _vm._l(_vm.categories, function(category, catIndex) {
          return _c(
            "div",
            {
              key: category.Key,
              class: [!_vm.listView ? _vm.cardcols : "col-12"]
            },
            [
              _c("category-card", {
                key: category.Key,
                attrs: {
                  category: category,
                  listview: _vm.listView,
                  activefilters: _vm.activefilters
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }