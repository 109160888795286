<template>
    <div class="case-listing">
        <div class="filter-row">
            <simple-filter v-bind:filteritems="allFilters" @filtersChanged="onFilter" 
                           :toplabel="getDictionaryItem(translations, 'Case.FilterContents')" 
                           :selectedlabel="getDictionaryItem(translations, 'Case.SelectedFilters')" 
                           :clearlabel="getDictionaryItem(translations, 'Case.ClearAll')" />
            <div class="sorting">
                <button class="btn btn-sort" @click="sort(false)">{{getDictionaryItem(translations, 'Case.Latest')}}</button>
                <button class="btn btn-sort" @click="sort(true)">{{getDictionaryItem(translations, 'Case.Alphabetical')}}</button>
            </div>
        </div>
        <div>
            <h4 class="list-header" v-if="selectedFilters.length">{{getDictionaryItem(translations, 'Case.FilteredSelection')}} ({{items.length}} {{getDictionaryItem(translations, 'Case.Hits')}})</h4>
            <div class="case-list">
                <transition-group name="tt-list-fade" tag="div" class="row">
                    <a v-for="(caseItem, caseIndex) in items" :key="caseItem.Key" :href="caseItem.Url" target="_self" class="case-item col-md-6 col-xl-4">
                        <img :src="caseItem.Image" alt="" />
                        <h2>{{caseItem.Name}}<span v-if="caseItem.City != ''">, {{caseItem.City}}</span></h2>
                        <p>{{caseItem.HeaderText | truncate(35,'...')}}</p>
                    </a>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import SimpleFilter from '../filters/simple-filter.vue';

    export default Vue.component('case-listing', {
        created: () => {

        },
        components: {
            SimpleFilter
        },
        data: function () {
            return {
                unfilteredItems: [],
                items: [],
                allFilters: [],
                selectedFilters: [],
                alphaAsc: false,
                dateDesc: false

            }
        },
        props: ['listobject','translations'],
        mounted: function () {
            this.unfilteredItems = this.listobject.Cases;
            this.items = this.listobject.Cases;
            this.allFilters = this.listobject.Filters;
        },
        filters: {
            truncate: function (text, length, suffix) {
                
                var matchIndex = [...text.matchAll(new RegExp(/\s/, 'gi'))]
                    .map(function (a) { return a.index })
                    .filter(function (i) { return i <= length; });

                if (matchIndex.length > 0) {
                    return text.substring(0, matchIndex[matchIndex.length - 1]) + suffix;
                }
                else if (text.length > length)
                {
                    return text.substring(0, length) + suffix;
                }
                return text;

            }
        },
        methods: {
            onFilter(selectedFilters) {
                this.items = this.unfilteredItems;
                if (selectedFilters.length) {
                    this.selectedFilters = selectedFilters;
                    this.items = this.filteredOnKey;
                }
                else {
                    this.selectedFilters = [];
                }
            },
            sort(alpha) {
                if (alpha) {
                    if (this.alphaAsc) {
                        this.alphaAsc = false;
                        this.sortedArrayAlpha;
                    }
                    else {
                        this.alphaAsc = true;
                        this.sortedArrayAlpha;
                    }
                }
                else {
                    if (this.dateDesc) {
                        this.dateDesc = false;
                        this.sortedArrayDate;
                    }
                    else {
                        this.dateDesc = true;
                        this.sortedArrayDate;
                    }
                }

            }
        },
        computed: {
            filteredOnKey() {
                if (this.items && this.selectedFilters && this.selectedFilters.length > 0) {
                    return this.items.filter((el) => {
                        let matched = false;
                        this.selectedFilters.forEach((sel) => {
                            if (el.CategoryKey === sel.Key) {
                                matched = true;
                            }
                        })
                        return matched;
                    });
                }
                else {
                    return this.unfilteredItems;

                }
            },
            sortedArrayAlpha: function () {
                if (this.alphaAsc) {
                    this.items.sort(function (a, b) {
                        return b.Name.localeCompare(a.Name);
                    });
                }
                else {
                    this.items.sort(function (a, b) {
                        return a.Name.localeCompare(b.Name);
                    });
                }
            },
            sortedArrayDate: function () {
                if (this.dateDesc) {
                    this.items.sort(function (a, b) {
                        return b.CreateDate.localeCompare(a.CreateDate);
                    });
                }
                else {
                    this.items.sort(function (a, b) {
                        return a.CreateDate.localeCompare(b.CreateDate);
                    });
                }
            }
        }
    });
</script>
