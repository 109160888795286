var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-page" }, [
    _c("div", { staticClass: "search-row row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "search-wrapper" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchParams.Query,
                expression: "searchParams.Query"
              }
            ],
            ref: "ip",
            attrs: {
              id: "mainsearchinput",
              autocomplete: "off",
              placeholder: _vm.getDictionaryItem(_vm.translations, "Search")
            },
            domProps: { value: _vm.searchParams.Query },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.newSearch.apply(null, arguments)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.searchParams, "Query", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.searchString != ""
            ? _c("div", {
                staticClass: "clear-search",
                on: { click: _vm.clearSearch }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary tt-orange",
              on: { click: _vm.newSearch }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getDictionaryItem(_vm.translations, "Search")) +
                  "\n                "
              )
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "filter-row row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c("simple-filter", {
            ref: "simpleFilter",
            attrs: {
              filteritems: _vm.allFilters,
              toplabel: _vm.getDictionaryItem(
                _vm.translations,
                "Search.FilterContent"
              ),
              selectedlabel: _vm.getDictionaryItem(
                _vm.translations,
                "Search.PickedFilters"
              ),
              clearlabel: _vm.getDictionaryItem(
                _vm.translations,
                "Search.ClearFilter"
              )
            },
            on: { filtersChanged: _vm.onFilter }
          }),
          _vm._v(" "),
          _vm.items.length
            ? _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "Search.YouSearchedFor"
                    )
                  ) + ": "
                ),
                _c("span", [_vm._v('"' + _vm._s(_vm.searchString) + '"')]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDictionaryItem(
                        _vm.translations,
                        "Search.NumberOfHits"
                      )
                    ) +
                    ": "
                ),
                _c("span", [_vm._v(_vm._s(_vm.totalUnfiltered))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.totalItems == 0
            ? _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.getDictionaryItem(
                      _vm.translations,
                      "Search.NumberOfHits"
                    )
                  ) + ": "
                ),
                _c("span", [_vm._v(_vm._s(_vm.totalItems))])
              ])
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "searchresult-list row" },
      [
        _c(
          "transition-group",
          {
            staticClass: "col-sm-12",
            attrs: { name: "tt-list-fadeslidein", tag: "div" }
          },
          _vm._l(_vm.items, function(searchItem, searchItemIndex) {
            return _c("search-item", {
              key: searchItem.key,
              attrs: {
                itemtype: searchItem.type,
                index: searchItemIndex,
                searchitem: searchItem
              }
            })
          }),
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    this.totalItems > this.currentCount
      ? _c("div", { staticClass: "row justify-content-center loadmore-row" }, [
          _c("div", { staticClass: "col align-self-center text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary tt-orange",
                on: { click: _vm.nextPage }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.getDictionaryItem(_vm.translations, "Search.LoadMore")
                    ) +
                    "\n            "
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }