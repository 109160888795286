<template>
    <div @click="toggleFavorite" v-if="customer" class="favorite-heart" v-bind:class="{'active': productItem.IsFavorite}"></div>
</template>

<script lang="ts">
    import Vue from "vue";

    export default Vue.component('favorite-button', {
        data: function () {
            return {
                productItem: Object,
                customerItem: Object
            }
        },
        mounted: function () {
            this.productItem = this.product;
            this.customerItem = this.customer;
        },
        props: {
            product: {
                type: Object
            },
            customer: {
                required: false,
                type: Object,
            },
        },
        methods: {
            toggleFavorite() {
                this.productItem.IsFavorite = !this.productItem.IsFavorite;
                this.$emit('favoriteChanged', this.productItem);
                let id = this.productItem.Id;
                if (this.customerItem.FavoriteProducts && this.customerItem.FavoriteProducts.includes(id)) {
                    this.customerItem.FavoriteProducts = this.customerItem.FavoriteProducts.filter(item => item !== id);
                }
                else {

                    this.customerItem.FavoriteProducts.push(id);
                }
                this.upDateFavoriteProducts();
            },
            upDateFavoriteProducts() {
                this.$http.post('/api/mypages/updatefavoriteproducts', this.customerItem).then((response) => {

                }, (response) => {
                    console.error(response);
                });
            },
        }
    });
</script>